export interface PaginatedModel<T> {
  data: T[];
  total: number;
  page: number;
  pageSize: number;
}

export interface PaginatedTableData<T extends object> {
  data?: Array<T>;
  pagination?: PaginationData;
}

export interface PaginationData {
  /*
   * Amount of items
   */
  total?: number;
  /**
   * Items per page
   */
  per_page?: number;
  /**
   * Current page number
   */
  current_page?: number;
  /**
   * Last page number
   */
  last_page?: number;
  /**
   * URL to the next page
   */
  next_page_url?: string;
  /**
   * URL to the previous page
   */
  prev_page_url?: string;
}
