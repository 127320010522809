/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodsInner } from './paymentMethodsInner';
import { PaymentwallType } from './paymentwallType';


/**
 * Update Paymentwall Request body data
 */
export interface CreateIndividualPaymentwallRequest { 
    /**
     * Name of the payment wall
     */
    name: string;
    type?: PaymentwallType;
    /**
     * Array of payment methods
     */
    payment_methods: Array<PaymentMethodsInner>;
}
export namespace CreateIndividualPaymentwallRequest {
}


