<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onReject()"
    ></button>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onReject()">
      {{ rejectText }}
    </button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">
      {{ confirmText }}
    </button>
  </div>
</div>
