<div class="d-flex justify-content-between m-4">
  <h3 class="header">Wybierz sklep</h3>
  <input
    class="eself-input ms-2"
    type="text"
    placeholder="Szukaj:   nip, regon, adres, nazwa, mail, telefon..."
    (keyup)="search($event)"
  />
</div>

<ng-container
  *ngIf="visibleShops | stateWrapper | async as shopsResult"
  [ngSwitch]="shopsResult.status"
>
  <ng-container *ngSwitchCase="'loaded'">
    <div class="m-4 d-flex flex-wrap gap-4">
      <div
        *ngFor="let shop of shopsResult.value"
        class="d-flex card p-4 gap-2 outfit-font shadow-sm"
      >
        <div
          class="d-flex justify-content-between pointer"
          (click)="onNavigateToShop(shop)"
        >
          <img
            *ngIf="shop.logo_base64"
            [src]="shop.logo_base64"
            alt="shop-logo"
          />
          <h4 class="ms-2 text-center w-100 main-font">
            {{ shop.name }}#{{ shop.id }}
          </h4>
        </div>
        <p *ngIf="shop.url" class="outfit-font">
          Adres:
          <a [href]="shop.url" target="_blank" rel="child">{{
            shop.url | shortDomain
          }}</a>
        </p>
        <p>
          Mail:
          <a [href]="'mailto: ' + shop.business_mail">{{
            shop.business_mail
          }}</a>
        </p>
        <p>
          Telefon:
          <a [href]="'tel: ' + shop.business_phone">{{
            shop.business_phone
          }}</a>
        </p>
        <div class="d-flex justify-content-between">
          <p class="pointer shadow-on-hover" [copy]="shop.nip ?? 'b.d'">
            NIP: {{ shop.nip
            }}<span class="material-icons"> content_copy </span>
          </p>
          <span class="ms-1 me-1">|</span>
          <p class="pointer shadow-on-hover" [copy]="shop.regon ?? 'b.d'">
            REGON: {{ shop.regon
            }}<span class="material-icons"> content_copy </span>
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <button
            class="eself-accent-button m-1 p-2 w-100"
            (click)="onNavigateToShop(shop)"
          >
            Otwórz
          </button>
          <a
            class="eself-secondary-button p-2 m-1 w-100 link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
            [href]="shop.url"
            target="_blank"
            rel="child"
          >
            Odwiedź stronę
          </a>
        </div>
      </div>
    </div></ng-container
  >

  <eself-common-loading *ngSwitchCase="'loading'"></eself-common-loading>
  <eself-common-api-error *ngSwitchCase="'error'"> </eself-common-api-error>
  <eself-common-empty-collection *ngSwitchCase="'empty'">
  </eself-common-empty-collection>
</ng-container>
