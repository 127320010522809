/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Pagination resource
 */
export interface PaginationResource { 
    /**
     * Amount of items
     */
    total?: number;
    /**
     * Items per page
     */
    per_page?: number;
    /**
     * Current page number
     */
    current_page?: number;
    /**
     * Last page number
     */
    last_page?: number;
    /**
     * URL to the next page
     */
    next_page_url?: string;
    /**
     * URL to the previous page
     */
    prev_page_url?: string;
}

