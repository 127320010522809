import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { OrderChangedEvent, SortOrderType } from '@eself/shared-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionDetailsModalComponent } from '../../../../shared/components/modals/transaction-details-modal/transaction-details-modal.component';
import { TransactionResource, TransactionService } from 'api-client';
import { Subject, combineLatest, map, startWith, switchMap, tap } from 'rxjs';

@Component({
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent {
  filtersHidden = true;
  public order: OrderChangedEvent = {
    property: '',
    order: SortOrderType.NONE,
  };

  public data: TransactionResource[] = [];
  public filteredData: TransactionResource[] = [];

  dateOptions: Date[] | null = null;
  dateSelected: Date | null = null;
  isLoading = true;

  pageIndex = 1;
  searchTerm: string | null = null;

  pageChangeSubject = new Subject<{ pageIndex: number }>();
  searchTermSubject = new Subject<string>();

  pageData$ = combineLatest([
    this.pageChangeSubject.pipe(startWith({ pageIndex: this.pageIndex })),
    this.searchTermSubject.pipe(startWith('')),
  ]).pipe(
    switchMap(([page, searchTerm]) =>
      this.transactionService.getTransactions(page.pageIndex).pipe(
        tap((transactionsPage) => {
          this.dateOptions = [];
          for (const iterator of transactionsPage.data ?? []) {
            if (iterator.transaction_timestamp == null) {
              continue;
            }
            const date = new Date(iterator.transaction_timestamp);
            if (
              this.dateOptions.find(
                (x) =>
                  x.getFullYear() == date.getFullYear() &&
                  x.getMonth() == date.getMonth()
              ) == undefined
            ) {
              this.dateOptions.push(
                new Date(date.getFullYear(), date.getMonth(), 1)
              );
            }
          }
          this.dateOptions = this.dateOptions.sort(
            (a, b) => b.getTime() - a.getTime()
          );
        }),
        tap(() => {
          this.pageIndex = page.pageIndex;
        }),
        map((transactions) => {
          transactions.data = transactions.data?.filter((transaction) =>
            this.searchFunc(transaction, searchTerm)
          );
          this.isLoading = false;
          return transactions;
        })
      )
    )
  );
  constructor(
    private transactionService: TransactionService,
    private datePipe: DatePipe,
    private modalService: NgbModal
  ) {
    this.onPageChange(0);
  }

  public onPageChange(pageIndex: number): void {
    this.isLoading = true;
    this.pageChangeSubject.next({ pageIndex });
  }

  public onSerachChange(searchTerm: string): void {
    this.searchTermSubject.next(searchTerm);
  }

  public onOrderUpdated(order: OrderChangedEvent): void {
    this.order = order;
    alert(
      'Ta opcja jest tymczasowo niedostępna przy stronicowaniu serwerowym. Pracujemy nad jej udostępnieniem.'
    );
  }

  public openTransactionDetails(transaction: TransactionResource): void {
    const modalRef = this.modalService.open(TransactionDetailsModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.transaction = transaction;
  }

  public search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchTermSubject.next(searchTerm);
  }

  public searchFunc(item: TransactionResource, searchTerm: string): boolean {
    return (
      (('#' + item.id).includes(searchTerm.toLowerCase()) ||
        item.shop?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.price?.toString().includes(searchTerm.toLowerCase()) ||
        item.balance?.toString().includes(searchTerm.toLowerCase()) ||
        item.eself_fee?.toString().includes(searchTerm) ||
        item.micro_fee?.toString().includes(searchTerm) ||
        this.datePipe
          .transform(item.transaction_timestamp, 'dd.MM.YYYY')
          ?.includes(searchTerm.toLowerCase())) ??
      (false ||
        this.datePipe
          .transform(item.payout_timestamp, 'dd.MM.YYYY')
          ?.includes(searchTerm.toLowerCase())) ??
      false
    );
  }
}
