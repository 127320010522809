/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SettlementType } from './settlementType';
import { InvoiceStatus } from './invoiceStatus';


/**
 * Settlement resource
 */
export interface SettlementResource { 
    /**
     * The unique identifier for the resource
     */
    id?: number;
    /**
     * Date of update in ISO 8601 format
     */
    date_of_update?: string;
    /**
     * Currency
     */
    currency?: any | null;
    /**
     * Amount
     */
    amount?: any | null;
    /**
     * Gross micro fee
     */
    micro_fee_gross?: any | null;
    /**
     * Net micro fee
     */
    micro_fee_net?: any | null;
    /**
     * VAT micro fee
     */
    micro_fee_vat?: any | null;
    /**
     * Gross Eself fee
     */
    eself_fee_gross?: any | null;
    /**
     * Net Eself fee
     */
    eself_fee_net?: any | null;
    /**
     * VAT Eself fee
     */
    eself_fee_vat?: any | null;
    /**
     * Gross gate fee
     */
    gate_fee_gross?: any | null;
    /**
     * Net gate fee
     */
    gate_free_net?: any | null;
    /**
     * VAT gate fee
     */
    gate_fee_vat?: any | null;
    /**
     * Transaction total fee gross
     */
    total_fee_gross?: number;
    /**
     * Transaction total fee net
     */
    total_fee_net?: number;
    /**
     * Transaction total fee vat
     */
    total_fee_vat?: number;
    /**
     * Withdrawal amount
     */
    withdrawal_amount?: any | null;
    /**
     * Status of withdrawal
     */
    withdrawal_status?: number;
    /**
     * Date of withdrawal in ISO 8601 format
     */
    withdrawal_date?: string;
    settlement_type?: SettlementType;
    /**
     * Date of settlement in ISO 8601 format
     */
    settlement_date?: string;
    /**
     * Available balance
     */
    available_balance?: any | null;
    invoice_status?: InvoiceStatus;
}
export namespace SettlementResource {
}


