<form [formGroup]="form" (ngSubmit)="onSubmit()" class="row g-3">
  <h3>Osoby kontaktowe</h3>
  <h5>Kontakt biznesowy</h5>
  <div class="col-12">
    <label for="buisnessName" class="form-label">Imię i nazwisko</label>
    <input
      type="text"
      id="buisnessName"
      name="buisnessName"
      class="form-control"
      formControlName="buisnessName"
    />
  </div>
  <div class="col-md-6">
    <label for="buisnessMail" class="form-label">Mail</label>
    <input
      type="email"
      id="buisnessMail"
      name="buisnessMail"
      class="form-control"
      formControlName="buisnessMail"
    />
  </div>
  <div class="col-md-6">
    <label for="buisnessPhone" class="form-label">Numer telefonu</label>
    <input
      type="tel"
      id="buisnessPhone"
      name="buisnessPhone"
      class="form-control"
      formControlName="buisnessPhone"
    />
  </div>
  <br />
  <h5 class="mt-4 pt-2">Kontakt z klientem</h5>
  <div class="col-12">
    <label for="customerSupportName" class="form-label">Imię i nazwisko</label>
    <input
      type="text"
      id="customerSupportName"
      name="customerSupportName"
      class="form-control"
      formControlName="customerSupportName"
    />
  </div>
  <div class="col-md-6">
    <label for="customerSupportMail" class="form-label">Mail</label>
    <input
      type="email"
      id="customerSupportMail"
      name="customerSupportMail"
      class="form-control"
      formControlName="customerSupportMail"
    />
  </div>
  <div class="col-md-6">
    <label for="customerSupportPhone" class="form-label">Numer telefonu</label>
    <input
      type="tel"
      id="customerSupportPhone"
      name="customerSupportPhone"
      class="form-control"
      formControlName="customerSupportPhone"
    />
  </div>
  <hr />
  <h3>Regulaminy</h3>
  <div class="col-md-6">
    <label for="linkPrivacyPolicy" class="form-label"
      >Polityka prywatności</label
    >
    <input
      type="url"
      id="linkPrivacyPolicy"
      name="linkPrivacyPolicy"
      class="form-control"
      formControlName="linkPrivacyPolicy"

    />
  </div>
  <div class="col-md-6">
    <label for="linkRegulations" class="form-label">Regulamin sklepu</label>
    <input
      type="url"
      id="linkRegulations"
      name="linkRegulations"
      class="form-control"
      formControlName="linkRegulations"
    />
  </div>
  <hr />
  <h3>Wizytówka</h3>

  <div class="col-md-6">
    <label for="shop-icon" class="form-label">Grafika do katalogu:</label>
    <input
      type="file"
      class="form-control"
      id="shop-icon"
      name="shop-icon"
      accept="image/*"
      (change)="onShopIconChange($event)"
    /><img
      *ngIf="shopIconPreviewUrl"
      class="img-preview"
      [src]="shopIconPreviewUrl"
      alt="Preview"
    />
    <button
      *ngIf="shopIconPreviewUrl"
      class="btn btn-danger"
      (click)="onRemoveShopIcon()"
    >
      Usuń
    </button>
  </div>
  <!-- Add the file input field for shop-logo -->
  <div class="col-md-6">
    <label for="shop-logo" class="form-label">Logo sklepu:</label>
    <input
      type="file"
      class="form-control"
      id="shop-logo"
      name="shop-logo"
      accept="image/*"
      (change)="onShopLogoChange($event)"
    /><img
      *ngIf="shopLogoPreviewUrl"
      class="img-preview"
      [src]="shopLogoPreviewUrl"
      alt="Preview"
    />
    <button
      *ngIf="shopLogoPreviewUrl"
      class="btn btn-danger"
      (click)="onRemoveShopLogo()"
    >
      Usuń
    </button>
  </div>

  <div class="col-12">
    <p *ngIf="showStep">Krok 2 z 3</p>
    <button *ngIf="showBack" class="btn btn-secondary" (click)="onBack()">
      Wstecz
    </button>
    <button
      type="submit"
      class="btn ms-2"
      [class.btn-primary]="form.valid"
      [class.btn-danger]="!form.valid || !showNext"
    >
      {{ continue }}
    </button>
  </div>
</form>
