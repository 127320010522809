/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentWallResource } from './paymentWallResource';
import { Fee } from './fee';
import { PaymentwallShopTypeResource } from './paymentwallShopTypeResource';
import { AddressResource } from './addressResource';
import { CustomerAssistant } from './customerAssistant';


/**
 * Shop resource
 */
export interface ShopResource { 
    /**
     * Id
     */
    id?: number;
    /**
     * Type of the shop
     */
    type?: any | null;
    merchant_id?: string;
    transaction_token?: string;
    token?: string;
    /**
     * Name
     */
    name?: string;
    /**
     * Shop Url
     */
    url?: string;
    /**
     * Shop Logo
     */
    logo_base64?: string;
    /**
     * Shop catalog
     */
    catalog_base64?: string;
    /**
     * Email address to contact regarding RODO
     */
    rodo_email?: string;
    /**
     * Nip
     */
    nip?: string | null;
    /**
     * Regon
     */
    regon?: string | null;
    /**
     * Krs
     */
    krs?: string | null;
    /**
     * Pesel
     */
    pesel?: string | null;
    /**
     * Phone number of the shop
     */
    phone_number?: string;
    /**
     * Email of the shop
     */
    email?: string;
    /**
     * Bank account of the shop
     */
    bank_account?: string;
    /**
     * Invoice email of the shop
     */
    invoice_email?: string;
    customer_assistant?: CustomerAssistant | null;
    address?: AddressResource;
    mailing_address?: AddressResource;
    /**
     * Business mail
     */
    business_mail?: string;
    /**
     * Business phone number
     */
    business_phone?: string;
    /**
     * Business person name
     */
    business_person?: string;
    /**
     * Mail for contacting with clients
     */
    client_mail?: string;
    /**
     * Phone for contacting with clients
     */
    client_phone?: string;
    /**
     * Person who clients should contact with
     */
    client_person?: string;
    /**
     * Representative person of the shop
     */
    representative_person?: string;
    /**
     * Representative\'s PESEL of the shop
     */
    representative_pesel?: string;
    /**
     * PaymentWall\'s contact person of the shop
     */
    paymentwall_contact_person?: string;
    /**
     * PaymentWall\'s contact phone of the shop
     */
    paymentwall_contact_phone?: string;
    /**
     * PaymentWall\'s contact email of the shop
     */
    paymentwall_contact_email?: string;
    /**
     * PaymentWall\'s technical contact person of the shop
     */
    paymentwall_technical_contact_person?: string;
    /**
     * PaymentWall\'s technical contact phone of the shop
     */
    paymentwall_technical_contact_phone?: string;
    /**
     * PaymentWall\'s technical contact mail of the shop
     */
    paymentwall_technical_contact_mail?: string;
    privacy_policy_url?: string;
    regulations_url?: string;
    note?: string;
    fee?: Fee | null;
    paymentwall?: PaymentWallResource;
    paymentwall_shop_type?: PaymentwallShopTypeResource;
}

