<span *ngIf="displayPaginationDescription">
  Widzisz {{ startIndex }}-{{ endIndex }} z {{ totalCount }} wyników. Zbiór
  danych zawiera {{ allData?.length }} wpisów.
</span>
<div
  class="d-flex flex-row me-2 pb-4 align-items-center justify-content-between"
>
  <eself-table-pagination
    *ngIf="isPaginationEnabled()"
    class="mb-2 mt-2"
    [currentPage]="pageIndex + 1"
    [canGoForward]="canGoForward"
    [canGoBackward]="canGoBackward"
    [pages]="closestPages"
    (moveToFirstPage)="onMoveToFirstPage()"
    (moveToPreviousPage)="onMoveToPreviousPage()"
    (moveToPage)="onMoveToPage($event - 1)"
    (moveToNextPage)="onMoveToNextPage()"
    (moveToLastPage)="onMoveToLastPage()"
  >
  </eself-table-pagination>
  <input
    *ngIf="searchFunc || displaySearch"
    class="eself-input ms-2"
    type="text"
    [placeholder]="searchPlaceholder"
    title="Szukaj"
    (keyup)="search($event)"
  />
</div>
<div class="table-responsive">
  <table class="table table-hover">
    <thead class="">
      <tr>
        <ng-container
          *ngTemplateOutlet="
            headers || defaultHeaderTemplate;
            context: { $implicit: pageData }
          "
        ></ng-container>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr *ngFor="let row of pageData">
        <ng-container
          *ngTemplateOutlet="
            rows || defaultRowTemplate;
            context: { $implicit: row }
          "
        ></ng-container>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="isLoading" class="placeholder-glow">
  <p class="placeholder-glow">
    <span class="placeholder col-12"></span>
  </p>
  <p class="placeholder-glow">
    <span class="placeholder col-12"></span>
  </p>
</div>

<eself-table-pagination
  *ngIf="isPaginationEnabled()"
  class="mt-2"
  [currentPage]="pageIndex + 1"
  [canGoForward]="canGoForward"
  [canGoBackward]="canGoBackward"
  [pages]="closestPages"
  (moveToFirstPage)="onMoveToFirstPage()"
  (moveToPreviousPage)="onMoveToPreviousPage()"
  (moveToPage)="onMoveToPage($event - 1)"
  (moveToNextPage)="onMoveToNextPage()"
  (moveToLastPage)="onMoveToLastPage()"
>
</eself-table-pagination>
<span *ngIf="displayPaginationDescription">
  Widzisz {{ startIndex }}-{{ endIndex }} z {{ totalCount }} wyników. Zbiór
  danych zawiera {{ allData?.length }} wpisów.
</span>

<ng-template #defaultHeaderTemplate let-data>
  <th *ngFor="let header of pageData[0] | keyvalue" scope="col">
    {{ header.key }}
  </th>
</ng-template>

<ng-template #defaultRowTemplate let-row>
  <td *ngFor="let row of row | keyvalue" scope="row">
    {{ row.value }}
  </td>
</ng-template>
