/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Address resource
 */
export interface AddressResource { 
    /**
     * ID
     */
    id?: number;
    /**
     * Country of the address
     */
    country?: string;
    /**
     * Country code of the address
     */
    country_code?: string;
    /**
     * City of the address
     */
    city?: string;
    /**
     * Post code of the address
     */
    post_code?: string;
    /**
     * Street of the address
     */
    street?: string;
    /**
     * Building number of the address
     */
    building_number?: string;
    /**
     * Apartment number of the address
     */
    apartment_number?: string;
}

