<div class="d-flex flex-column p-4">
  <div class="d-flex justify-content-between">
    <h3 *ngIf="!toEdit; else editHeader">Kreator konfiguracji bramki</h3>
    <ng-template #editHeader>
      <h3>Edytor konfiguracji bramki #{{ toEdit?.id }}</h3>
    </ng-template>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onClose()"
    ></button>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-label" for="name">Nazwa: </label>
        <input
          id="name"
          type="text"
          class="form-control"
          [(ngModel)]="name"
          required
        />
      </div>
      <h4 class="mt-4">Formy płatności:</h4>
      <ul class="list-group">
        <li
          class="list-group-item d-flex flex-row"
          [class.active]="inEditId === element.id"
          [class.disabled]="inEditId !== null && inEditId !== element.id"
          *ngFor="let element of elements; let i = index"
        >
          <div class="ms-2 me-auto">
            <div>
              <span class="fw-bold me-3">{{ i + 1 }}. {{ element.name }}</span>
              [zewnętrzne id: {{ element.external_id }}]
            </div>
            <span
              >prowizja: {{ element.fee_percent | currencyNumber : '%' }},
              opłata: {{ element.charge | currencyNumber : 'PLN' }}</span
            >
          </div>
          <div class="">
            <button
              *ngIf="element.id"
              type="button"
              class="btn-edit"
              aria-label="Edit"
              (click)="onEditElement(element.id)"
            >
              <i class="material-icons"> edit </i>
            </button>
            <!-- <button
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="onRemoveElement(element.id)"
            ></button> -->
          </div>
        </li>
      </ul>
    </div>
    <div class="col">
      <div class="form-group mt-2 ps-4 left-border">
        <label class="form-label" for="bulletPoint">
          <h3>Dodaj nową formę płatności</h3>
        </label>
        <form [formGroup]="paymentForm">
          <div class="form-group mt-0">
            <label class="form-label" for="name">Nazwa: </label>
            <input
              type="text"
              class="form-control"
              [class.disabled]="!inEditId"
              formControlName="name"
              required
              [readonly]="!inEditId"
            />
          </div>
          <div class="form-group mt-0">
            <label class="form-label" for="external_id">Zewnętrzne id: </label>
            <input
              type="text"
              class="form-control"
              [class.disabled]="!inEditId"
              formControlName="external_id"
              required
              [readonly]="!inEditId"
            />
          </div>
          <div class="form-group mt-0">
            <label class="form-label" for="fee">Prowizja %: </label>
            <input
              type="number"
              class="form-control"
              [class.disabled]="!inEditId"
              formControlName="fee"
              [readonly]="!inEditId"
              required
            />
          </div>
          <div class="form-group mt-0">
            <label class="form-label" for="charge">Opłata: </label>
            <input
              type="number"
              class="form-control"
              [class.disabled]="!inEditId"
              formControlName="charge"
              required
              [readonly]="!inEditId"
            />
          </div>
          <div class="d-flex mt-2">
            <button
              class="btn btn-primary flex-fill"
              type="button"
              (click)="onAddElement()"
              [disabled]="!paymentForm.valid || !inEditId"
            >
              {{ 'Zapisz' }}
            </button>
            <button
              *ngIf="inEditId"
              class="btn btn-primary flex-fill"
              type="button"
              (click)="onClearEdit()"
              [disabled]="!paymentForm.valid"
            >
              Anuluj
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <button
    type="submit"
    class="btn btn-primary mt-2"
    (click)="onCreate()"
    [disabled]="!name.length"
  >
    <span>{{ !toEdit ? 'Utwórz nową konfigurację' : 'Zapisz zmiany' }}</span>
  </button>
</div>
