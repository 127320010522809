<h3 class="m-4">Status rozliczeń</h3>
<div class="d-flex card m-4 p-4" *ngIf="billingStatuses$ | async as billingStatuses">
  <eself-table [data]="billingStatuses" [pageSize]="50" [isLoading]="isLoading">
    <ng-template eselfTableHeader>
      <th scope="col" class="text-end">kwota całkowita</th>
      <th scope="col" class="text-end">łączne opłaty</th>
      <th scope="col" class="text-end">kwota wypłaty*</th>
      <th scope="col" class="text-center">data wypłaty*</th>
      <th scope="col" class="text-center">status wypłaty</th>
      <th scope="col" class="text-center">data rozliczenia*</th>
    </ng-template>
    <ng-template [eselfTableRow]="billingStatuses" let-row>
      <td scope="row" class="text-end" data-title="kwota do zapłaty">
        {{ row.totalAmount | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-end" data-title="łączne opłaty underline" placement="bottom" container="body">
        <u> {{ row.totalCharges | currencyNumber : 'PLN' }}</u>
      </td>
      <td scope="row" class="text-end" data-title="kwota do zapłaty">
        {{ row.withdrawalAmount | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" data-title="data wypłaty*">
        {{ row.withdrawalDate | safeDate }}
      </td>
      <td scope="row" data-title="status wypłaty" placement="bottom"
        [ngbTooltip]="row.withdrawalStatus | withdrawalStatusText" [openDelay]="50" [closeDelay]="300" container="body">
        <i class="material-icons" [style.color]="row.withdrawalStatus === 1 ? '#006be0' : '#912da4'">
          {{
          row.withdrawalStatus === 1
          ? 'account_balance_wallet'
          : 'attach_money'
          }}
        </i>
      </td>
      <td scope="row" data-title="data rozliczenia*">
        {{ row.settlementDate | safeDate }}
      </td>
    </ng-template>
  </eself-table>
</div>
