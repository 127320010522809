import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

export interface Step2Form {
  shopIcon: File | null;
  shopIconBase64: string;
  shopLogo: File | null;
  shopLogoBase64: string;

  buisnessName: string;
  buisnessMail: string;
  buisnessPhone: string;

  customerSupportName: string;
  customerSupportMail: string;
  customerSupportPhone: string;

  linkRegulations: string;
  linkPrivacyPolicy: string;
}

@Component({
  selector: 'app-shop-onboarding-step2',
  templateUrl: './shop-onboarding-step2.component.html',
  styleUrls: ['../onboarding.scss'],
})
export class ShopOnboardingStep2Component {
  @Output() formResult = new EventEmitter<Step2Form>();
  @Output() back = new EventEmitter<void>();

  @Input() showBack = true;
  @Input() showStep = true;
  @Input() showNext = true;
  @Input() continue = 'Dalej';

  form: FormGroup;
  shopIconPreviewUrl: string | null = null;
  shopLogoPreviewUrl: string | null = null;

  @Input() set formValue(value: Step2Form | null) {
    if (value) {
      this.shopIconPreviewUrl = value.shopIconBase64;
      this.shopLogoPreviewUrl = value.shopLogoBase64;
      this.form.setValue({
        buisnessName: value.buisnessName,
        buisnessMail: value.buisnessMail,
        buisnessPhone: value.buisnessPhone,
        customerSupportName: value.customerSupportName,
        customerSupportMail: value.customerSupportMail,
        customerSupportPhone: value.customerSupportPhone,
        shopIcon: value.shopIcon,
        shopLogo: value.shopLogo,
        linkPrivacyPolicy: value.linkPrivacyPolicy,
        linkRegulations: value.linkRegulations,
      });
    }
  }

  constructor() {
    this.form = new FormGroup({
      buisnessName: new FormControl(''),
      buisnessMail: new FormControl(''),
      buisnessPhone: new FormControl(''),
      customerSupportName: new FormControl(''),
      customerSupportMail: new FormControl(''),
      customerSupportPhone: new FormControl(''),
      shopIcon: new FormControl(null),
      shopLogo: new FormControl(null),
      linkRegulations: new FormControl(''),
      linkPrivacyPolicy: new FormControl(''),
    });
  }

  onSubmit(): void {
    const formValue = this.form.value as Step2Form;
    formValue.shopIconBase64 = this.shopIconPreviewUrl ?? '';
    formValue.shopLogoBase64 = this.shopLogoPreviewUrl ?? '';
    this.formResult.emit(formValue);
  }

  onBack(): void {
    this.back.emit();
  }

  onShopIconChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement || !inputElement.files) {
      return;
    }
    if (inputElement.files.length > 0) {
      const file = inputElement.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.shopIconPreviewUrl = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.form.patchValue({ shopIcon: file });
    } else {
      this.shopIconPreviewUrl = null;
      this.form.patchValue({ shopIcon: null });
    }
  }

  onShopLogoChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement || !inputElement.files) {
      return;
    }
    if (inputElement.files.length > 0) {
      const file = inputElement.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.shopLogoPreviewUrl = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.form.patchValue({ shopLogo: file });
    } else {
      this.shopLogoPreviewUrl = null;
      this.form.patchValue({ shopLogo: null });
    }
  }

  onRemoveShopIcon(): void {
    this.shopIconPreviewUrl = null;
    this.form.patchValue({ shopIcon: null });
  }

  onRemoveShopLogo(): void {
    this.shopLogoPreviewUrl = null;
    this.form.patchValue({ shopLogo: null });
  }
}
