<span class="d-flex w-100">
  Witaj, <b class="ps-1"> {{ userEmail }}!</b>
</span>
<hr class="mt-4" />
<div *ngIf="headerSide">
  <ng-container #headerSide *ngTemplateOutlet="headerSide"></ng-container>
</div>
<button
  *ngFor="let element of menuElements"
  routerLink="{{ element.link }}"
  routerLinkActive="active"
>
  <i class="material-icons">{{ element.icon }}</i> {{ element.name }}
</button>
<hr class="mt-4 mt-auto" />
<button class="d-flex mb-4" (click)="logout()" routerLinkActive="active">
  <i class="material-icons"> logout </i> Wyloguj się
</button>
