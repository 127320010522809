import { Component } from '@angular/core';
import type { EChartsOption } from 'echarts';
import { SelectedShopService, retryPipe } from '@eself/shared-services';
import { ShopResource, ShopService } from 'api-client';
import { Observable, tap } from 'rxjs';
import { ConfirmModalComponent, ToastService } from '@eself/shared-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shop-dashboard-page',
  templateUrl: './shop-dashboard-page.component.html',
  styleUrls: ['./shop-dashboard-page.component.scss'],
})
export class ShopDashboardPageComponent {
  shop$: Observable<ShopResource> = new Observable();

  note: string | null = null;

  options: EChartsOption = {
    title: {
      left: '50%',
      text: 'Saldo sklepu - przelewy 24',
      subtext: 'Bieżący miesiąc',
      textAlign: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      align: 'auto',
      bottom: 10,
      data: [
        'Środki dostępne do wypłaty',
        'Prowizja do pobrania',
        'Środki zablokowane',
        'Prowzija zablokowana',
      ],
    },
    calculable: true,
    series: [
      {
        name: 'area',
        type: 'pie',
        radius: '55%',
        center: ['50%', '50%'],
        emphasis: {
          focus: 'self',
        },
        data: [
          { value: 1200, name: 'Środki dostępne do wypłaty' },
          { value: 22.8, name: 'Prowizja do pobrania' },
          { value: 9800, name: 'Środki zablokowane' },
          { value: 186.2, name: 'Prowzija zablokowana' },
        ],
      },
    ],
  };

  constructor(
    private selectedShopService: SelectedShopService,
    private shopService: ShopService,
    private toasts: ToastService,
    private modalService: NgbModal
  ) {
    this.getShop();
  }

  onSaveNote(shop: ShopResource) {
    if (shop.fee?.id && shop.id && this.note) {
      this.shopService
        .changeShopFeeNote(shop.id ?? 0, shop.fee?.id, {
          note: this.note,
        })
        .subscribe(() => this.toasts.show('Zapisano notatkę'));
    }
  }

  activateShop(shop: ShopResource){
    alert('ta opcja jest jeszcze niedostępna');
  }

  async onGenerateToken(shop: ShopResource): Promise<void> {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent);
    confirmModalRef.componentInstance.message = `Czy na pewno chcesz wygenrować nowy token?`;
    const result = await confirmModalRef.result;
    if (result === true && shop.id) {
      this.shopService.generateNewShopToken(shop.id).subscribe((token) => {
        if (token) {
          this.toasts.show('Wygenerowano token i skopiowano go do schowka');
          navigator.clipboard.writeText(token.shop_token ?? 'error');
          this.getShop();
        }
      });
    }
  }

  getShop() {
    this.shop$ = this.shopService
      .getShop(this.selectedShopService.getShop()?.id ?? 0)
      .pipe(
        retryPipe(3),
        tap((shop) => {
          this.note = shop.note ?? null;
          return shop;
        })
      );
  }
}
