<eself-top-bar
  [mainSection]="mainSection"
  [mainSectionSelected]="mainSectionSelected"
  [colorClass]="colorClass"
></eself-top-bar>
<div>
  <eself-side-bar *ngIf="!sideBar" [menuElements]="sideBarElements">
  </eself-side-bar>
  <div *ngIf="sideBar" class="m-0 p-0">
    <ng-container #sideBar *ngTemplateOutlet="sideBar"></ng-container>
  </div>
  <div class="main-content">
    <ng-content></ng-content>
  </div>
</div>
