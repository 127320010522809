import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceResource } from 'api-client';

@Component({
  selector: 'app-edit-invoice-modal',
  templateUrl: './edit-invoice-modal.component.html',
  styleUrls: ['./edit-invoice-modal.component.scss'],
})
export class EditInvoiceModalComponent implements OnInit {
  @Input() invoice: InvoiceResource | null = null;
  form: FormGroup;
  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal) {
    this.form = this.fb.group({
      invoice_number: [''],
      issue_date: [''],
      note: [''],
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      invoice_number: this.invoice?.invoice_number,
      issue_date: this.invoice?.issue_date,
      note: this.invoice?.note,
    });
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit(): void {
    this.activeModal.close({
      invoice_number: this.form.value.invoice_number,
      issue_date: this.form.value.issue_date,
      note: this.form.value.note,
    });
  }
}
