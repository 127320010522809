import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeDate',
})
export class SafeDatePipe implements PipeTransform {
  /**
   *
   */
  constructor(private datePipe: DatePipe) {}
  transform(
    value: Date | string | number | null | undefined,
    ...args: string[]
  ): unknown {
    if (value) {
      if (args.length > 0) {
        if (args[0] === 'long') {
          return this.datePipe.transform(value, 'dd.MM.YYYY HH:mm:ss');
        }
        return this.datePipe.transform(value, args[0]);
      }
      return this.datePipe.transform(value, 'dd.MM.YYYY');
    }
    return '-';
  }
}
