import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethodResource } from 'api-client';

@Pipe({
  name: 'paymentMethodsReadable',
})
export class PaymentMethodsReadablePipe implements PipeTransform {
  transform(paymentMethods: PaymentMethodResource[] | undefined): string {
    if (!paymentMethods) return '';
    return paymentMethods.map((method) => method.name).join(', ');
  }
}
