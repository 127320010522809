import { Component } from '@angular/core';
import { PaginatedTableData } from '@eself/shared-services';
import { SelectableModel, ToastService } from '@eself/shared-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  InvoiceResource,
  InvoiceService,
  InvoiceStatus,
  SettlementResource,
} from 'api-client';
import { Subject, tap, startWith, switchMap, map } from 'rxjs';
import { InvoiceDetailsModalComponent } from './invoice-details-modal/invoice-details-modal.component';
import { EditInvoiceModalComponent } from './edit-invoice-modal/edit-invoice-modal.component';

@Component({
  templateUrl: './shop-invoices-page.component.html',
  styleUrls: ['./shop-invoices-page.component.scss'],
})
export class ShopInvoicesPageComponent {
  isLoading = true;
  pageIndex = 0;
  totalCount = 0;

  pageChangeSubject = new Subject<{ pageIndex: number }>();

  pageData$ = this.pageChangeSubject.pipe(
    tap(() => (this.isLoading = true)),
    startWith({ pageIndex: this.pageIndex }),
    switchMap((pageDetails) =>
      this.invoiceService
        .getInvoices(this.pageIndex) //use here pageDetails
        .pipe(
          tap((frame) => {
            this.pageIndex = frame.pagination?.current_page ?? 0;
          }),
          map((frame) => {
            this.totalCount = frame.pagination?.total ?? 0;
            if (this.totalCount < this.pageIndex) {
              this.pageIndex = 0;
            }

            return {
              data: frame.data,
              pagination: frame.pagination,
            } as PaginatedTableData<InvoiceResource>;
          })
        )
    ),
    tap(() => (this.isLoading = false))
  );

  constructor(
    private invoiceService: InvoiceService,
    private modal: NgbModal,
    private toast: ToastService
  ) {
    this.onPageChange(0);
  }

  public onPageChange(pageIndex: number): void {
    this.isLoading = true;
    this.pageChangeSubject.next({ pageIndex });
  }

  openInvoice(invoice: InvoiceResource) {
    const modalRef = this.modal.open(InvoiceDetailsModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.invoice = invoice;
    modalRef.result.then((result) => {
      if (result) {
        this.pageChangeSubject.next({
          pageIndex: this.pageIndex,
        });
      }
    });
  }

  canEdit(invoice: InvoiceResource): boolean {
    return (
      invoice.status === InvoiceStatus.NUMBER_0 ||
      invoice.status === InvoiceStatus.NUMBER_1
    );
  }

  editInvoice(invoice: InvoiceResource): void {
    const modalRef = this.modal.open(EditInvoiceModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.invoice = invoice;
    modalRef.result.then((result) => {
      if (result && invoice.id) {
        this.invoiceService.updateInvoice(invoice.id, result).subscribe((_) => {
          this.toast.show('Zaktualizowano fakturę!');
          this.pageChangeSubject.next({
            pageIndex: this.pageIndex,
          });
        });
      }
    });
  }
}
