<div
  *ngIf="paymentwall"
  class="list-group-item list-group-item-action"
  [class.active]="active"
>
  <img [src]="paymentwall.logo_base64" class="me-4" alt="logo" /><span
    class="fw-bold"
    >{{ paymentwall.name ?? 'b.d' }}</span
  >
  <br />
  Metody płatnosci:
  <span class="fw-bold">{{
    paymentwall.payment_methods | paymentMethodsReadable
  }}</span>
</div>
