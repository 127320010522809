import { Component, Input } from '@angular/core';
import { PaymentWallResource } from 'api-client';

@Component({
  selector: 'app-paymentwalll-list-item',
  templateUrl: './paymentwalll-list-item.component.html',
  styleUrls: ['./paymentwalll-list-item.component.scss'],
})
export class PaymentwalllListItemComponent {
  @Input() paymentwall: PaymentWallResource | null = null;

  @Input() active = false;
}
