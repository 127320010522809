<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="invoiceModalLabel">
      Szczegóły Faktury #{{ invoice?.id }}
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
        (click)="onClose()"
    ></button>
  </div>
  <div *ngIf="invoice" class="modal-body">
    <div class="row">
      <div class="col"><strong>ID:</strong> {{ invoice.id }}</div>
      <div class="col">
        <strong>Numer Faktury:</strong> {{ invoice.invoice_number }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Data wystawienia:</strong> {{ invoice.issue_date | safeDate }}
      </div>
      <div class="col" *ngIf="invoice.status">
        <strong>Status:</strong> {{ invoice.status | invoiceStatusText }}
      </div>
    </div>
    <div class="row">
      <div class="col"><strong>Notatka:</strong> {{ invoice.note }}</div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Ilość Transakcji:</strong> {{ invoice.transaction_count }}
      </div>
      <div class="col">
        <strong>Data Pierwszej Transakcji:</strong>
        {{ invoice.first_transaction_date | safeDate }}
      </div>
      <div class="col">
        <strong>Data Ostatniej Transakcji:</strong>
        {{ invoice.last_transaction_date | safeDate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <strong>Opłata eSelf (brutto):</strong>
        {{ invoice.only_eself_fee_gross | currencyNumber : 'PLN' }}
      </div>
      <div class="col-12">
        <strong>Opłata eSelf (netto):</strong>
        {{ invoice.only_eself_fee_net | currencyNumber : 'PLN' }}
      </div>
      <div class="col-12">
        <strong>Opłata eSelf VAT:</strong>
        {{ invoice.only_eself_fee_vat | currencyNumber : 'PLN' }}
      </div>
    </div>

    <!-- Rozliczenia -->
    <div class="row">
      <div class="col">
        <strong>Rozliczenia:</strong>
        <ul>
          <li *ngFor="let settlement of invoice.settlements">
            ID: {{ settlement.id }}, Data Aktualizacji:
            {{ settlement.date_of_update | safeDate }}, Kwota:
            {{ settlement.amount | currencyNumber : 'PLN' }}
            <!-- Dodaj więcej pól z obiektu settlement w razie potrzeby -->
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex">
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          [disabled]="isPaid()"
          (click)="onReady()"
        >
          Oznacz jako zrealizowaną
        </button>
        <button
          type="button"
          class="btn btn-warning ms-2"
          data-bs-dismiss="modal"
          [disabled]="isCanceled()"
          (click)="onCancel()"
        >
          Oznacz jako anulowaną
        </button>
      </div>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        (click)="onClose()"
      >
        Zamknij
      </button>
    </div>
  </div>
</div>
