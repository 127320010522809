import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatus } from 'api-client';

@Pipe({
  name: 'rodoStatusIcon',
})

/**
 * 0 - Pending, 1 - Realized, 2 - Cancelled
 */
export class RodoStatusIconPipe implements PipeTransform {
  transform(value: number | undefined, ...args: unknown[]): string {
    if (value === 1) {
      return 'downloading';
    }
    if (value === 2 || value === 3) {
      return 'next_plan';
    }
    return 'error';
  }
}
