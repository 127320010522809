import { Pipe, PipeTransform } from '@angular/core';
import { TransactionProductType } from 'api-client';

@Pipe({
  name: 'transactionProductTypeText',
})
/**
 * 1 - Physical, 2 - Virtual
 */
export class TransactionProductTypePipe implements PipeTransform {
  transform(
    value: TransactionProductType | undefined,
    ...args: unknown[]
  ): string {
    if (value === TransactionProductType.NUMBER_1) return 'Fizyczny';
    return 'Wirtualny';
  }
}
