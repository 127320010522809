/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 - Pending, 1 - Realized, 2 - Cancelled
 */
export type InvoiceStatus = 0 | 1 | 2;

export const InvoiceStatus = {
    NUMBER_0: 0 as InvoiceStatus,
    NUMBER_1: 1 as InvoiceStatus,
    NUMBER_2: 2 as InvoiceStatus
};

