/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserResource } from './userResource';


/**
 * Customer Assistant associated with the shop
 */
/**
 * @type CustomerAssistant
 * Customer Assistant associated with the shop
 * @export
 */
export type CustomerAssistant = UserResource;

