import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GateConfiguration } from '../models/gate-configuration';

@Injectable({
  providedIn: 'root',
})
export class GateConfigurationService {
  public static Configurations: GateConfiguration[] = [
    {
      id: 1,
      name: 'Konfiguracja 1 - przelewy 24',
      payments: [
        {
          id: 1,
          name: 'Przelew',
          externalId: '1',
          commission: 0.028,
          fee: 0,
          createdAt: new Date(),
          updatedAt: new Date(),
        },
        {
          id: 2,
          name: 'Karta',
          externalId: '2',
          commission: 0.019,
          fee: 0.2,
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      ],
      createdAt: new Date(),
      updatedAt: new Date(),
    },
  ];

  public configurations$: BehaviorSubject<GateConfiguration[] | null>;

  constructor() {
    this.configurations$ = new BehaviorSubject<GateConfiguration[] | null>(
      null
    );
    this.seed();
  }

  private seed() {
    if (localStorage.getItem('gateConfigurations') === null) {
      localStorage.setItem(
        'gateConfigurations',
        JSON.stringify(GateConfigurationService.Configurations)
      );
    }
  }

  public refresh(): void {
    this.configurations$.next(GateConfigurationService.Configurations);
  }

  public update(configurations: GateConfiguration): void {
    const index = GateConfigurationService.Configurations.findIndex(
      (x) => x.id == configurations.id
    );
    GateConfigurationService.Configurations[index] = configurations;
    localStorage.setItem(
      'gateConfigurations',
      JSON.stringify(GateConfigurationService.Configurations)
    );
    this.refresh();
  }

  public add(configuration: GateConfiguration): void {
    const id =
      GateConfigurationService.Configurations[
        GateConfigurationService.Configurations.length - 1
      ].id + 1;
    configuration.id = id;
    GateConfigurationService.Configurations[
      GateConfigurationService.Configurations.length + 1
    ] = configuration;
    localStorage.setItem(
      'gateConfigurations',
      JSON.stringify(GateConfigurationService.Configurations)
    );
    this.refresh();
  }
}
