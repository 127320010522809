<ul class="pagination">
  <li class="page-item" (click)="onMoveToFirstPage()">
    <button class="page-link" aria-label="First page">
      <span aria-hidden="true">Pierwsza strona</span>
    </button>
  </li>
  <li *ngIf="canGoBackward" class="page-item" (click)="onMoveToPreviousPage()">
    <button class="page-link" aria-label="Previous">
      <span aria-hidden="true">&laquo;</span>
    </button>
  </li>
  <li
    *ngFor="let pageNumber of pages"
    class="page-item"
    (click)="onMoveToPage(pageNumber)"
  >
    <button
      [class.active]="currentPage === pageNumber"
      class="page-link"
      aria-label="pageNumber"
    >
      <span aria-hidden="true">
        {{ pageNumber }}
      </span>
    </button>
  </li>
  <li *ngIf="canGoForward" class="page-item" (click)="onMoveToNextPage()">
    <button class="page-link" aria-label="Next">
      <span aria-hidden="true">&raquo;</span>
    </button>
  </li>
  <li class="page-item" (click)="onMoveToLastPage()">
    <button class="page-link" aria-label="Last page">
      <span aria-hidden="true">Ostatnia strona</span>
    </button>
  </li>
</ul>
