import { Component, OnInit } from '@angular/core';
import {
  OrderModel,
  ShopsService,
  SelectedShopService,
  retryPipe,
} from '@eself/shared-services';
import { BillingStatusService, BillingStatusResource } from 'api-client';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './shop-billing-status-page.component.html',
  styleUrls: ['./shop-billing-status-page.component.scss'],
})
export class ShopBillingStatusPageComponent {
  public data: OrderModel[] = [];
  isLoading = true;

  billingStatuses$: Observable<BillingStatusResource[]> = new Observable();

  constructor(
    private shopsService: ShopsService,
    private billingService: BillingStatusService,
    private selectedShopService: SelectedShopService
  ) {
    this.billingStatuses$ = this.billingService
      .getAllBillingStatuses()
      .pipe(retryPipe());
  }
}
