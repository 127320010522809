<div class="modal-content">
  <div class="modal-header">
    <h1
      *ngIf="transaction"
      class="modal-title fs-4"
      id="exampleModalFullscreenLabel"
    >
      Transakcja
      <span
        class="pointer shadow-on-hover"
        [copy]="transaction.id?.toString() ?? 'b.d'"
        >#{{ transaction.id }}</span
      >
    </h1>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onClose()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <div *ngIf="transaction" class="row">
        <div class="col-12 col-lg-6 d-flex">
          <div class="card w-100 m-2">
            <div class="card-header">
              <h3>
                Szczegóły
                <span
                  class="pointer shadow-on-hover"
                  [copy]="transaction.id?.toString() ?? 'b.d'"
                  >#{{ transaction.id }}</span
                >
              </h3>
            </div>
            <div class="card-body">
              <table class="w-100 details">
                <tr>
                  <th>UID</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.uuid ?? 'b.d'"
                  >
                    {{ transaction.uuid }}
                  </td>
                </tr>
                <tr>
                  <th>Status transakcji</th>
                  <td>
                    {{ transaction.transaction_status | transactionStatusText }}
                  </td>
                </tr>

                <tr>
                  <th>Data przyjęcia</th>
                  {{
                    transaction.transaction_timestamp
                      | safeDate : 'long'
                  }}
                </tr>
                <tr *ngIf="transaction.transaction_timestamp">
                  <th>Data wypłaty</th>
                  {{
                    transaction.transaction_timestamp
                      | safeDate : 'long'
                  }}
                </tr>
                <tr>
                  <th>Wypłacona kwota</th>
                  <td>
                    {{ transaction.payout_amount | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Łączna kwota</th>
                  <td>
                    {{ transaction.price | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Wszystkie opłaty (netto)</th>
                  <td>
                    {{ transaction.total_fee_net | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Wszystkie opłaty (VAT)</th>
                  <td>
                    {{ transaction.total_fee_vat | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Wszystkie opłaty (brutto)</th>
                  <td>
                    {{ transaction.total_fee_gross | currencyNumber : 'PLN' }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 d-flex">
          <div class="card w-100 m-2">
            <div class="card-header d-flex justify-content-between">
              <h3>Opłaty</h3>
            </div>
            <div class="card-body">
              <table *ngIf="transaction.shop" class="w-100 details">
                <tr>
                  <th>Prowizja ESELF</th>
                  <td>
                    {{ transaction.eself_fee | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Mikroopłata ESELF</th>
                  <td>
                    {{ transaction.micro_fee | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Opłata łączna ESELF (Netto)</th>
                  <td>
                    {{ transaction.total_fee_net | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Opłata łączna ESELF (Vat)</th>
                  <td>
                    {{ transaction.total_fee_vat | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Opłata łączna ESELF (Brutto)</th>
                  <td>
                    {{ transaction.total_fee_gross | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Prowizja bramki</th>
                  <td>
                    {{ transaction.fee | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Dochód ESELF</th>
                  <td>
                    {{ transaction.income_eself | currencyNumber : 'PLN' }}
                  </td>
                </tr>
                <tr>
                  <th>Prowizja kalkulacyjna</th>
                  <td>
                    {{ transaction.only_eself_fee | currencyNumber : 'PLN' }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="card w-100 m-2">
            <div class="card-header d-flex justify-content-between">
              <h3>
                Sprzedawca
                <span>#{{ transaction.shop?.url ?? '' | shortDomain }}</span>
              </h3>
              <div *ngIf="transaction.shop" class="p-2">
                <img
                  class="logo"
                  *ngIf="transaction.shop.logo_base64"
                  [src]="transaction.shop.logo_base64"
                  [alt]="transaction.shop.name"
                  alt="logo"
                />
              </div>
            </div>
            <div class="card-body">
              <table *ngIf="transaction.shop" class="w-100 details">
                <tr>
                  <th>Firma</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.shop.name ?? 'b.d'"
                  >
                    {{ transaction.shop.name }}
                  </td>
                </tr>
                <tr>
                  <th>NIP</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.shop.nip ?? 'b.d'"
                  >
                    {{ transaction.shop.nip }}
                  </td>
                </tr>
                <tr>
                  <th>Opiekun</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.shop.client_person ?? 'b.d'"
                  >
                    {{ transaction.shop.client_person }}
                  </td>
                </tr>
                <tr>
                  <th>mail (klient)</th>
                  <td class="pointer shadow-on-hover">
                    <a [href]="'mailto:' + transaction.shop.client_mail">
                      {{ transaction.shop.client_mail }}</a
                    >
                  </td>
                </tr>
                <tr>
                  <th>telefon (klient)</th>
                  <td class="pointer shadow-on-hover">
                    <a [href]="'tel:' + transaction.shop.client_phone">
                      {{ transaction.shop.client_phone }}</a
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="card w-100 m-2">
            <div class="card-header">
              <h3>Dodatkowe informacje</h3>
            </div>
            <div class="card-body">
              <table class="w-100 details">
                <tr>
                  <th>Imię</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.first_name ?? 'b.d'"
                  >
                    {{ transaction.first_name }}
                  </td>
                </tr>
                <tr>
                  <th>Mail</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.email ?? 'b.d'"
                  >
                    {{ transaction.email }}
                  </td>
                </tr>
                <tr>
                  <th>ID transkacji</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.paymentwall_order_id ?? 'b.d'"
                  >
                    {{ transaction.paymentwall_order_id }}
                  </td>
                </tr>
                <tr>
                  <th>Merchant Paywall ID transakcji</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.merchant_paymentwall_order_id ?? 'b.d'"
                  >
                    {{ transaction.merchant_paymentwall_order_id }}
                  </td>
                </tr>
                <tr>
                  <th>Eself PayWall ID</th>
                  <td
                    class="pointer shadow-on-hover"
                    [copy]="transaction.eself_paymentwall_order_id ?? 'b.d'"
                  >
                    {{ transaction.eself_paymentwall_order_id }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="transaction.transaction_products" class="col-12 d-flex">
          <div class="card m-2 w-100">
            <div class="card-header">
              <h3>Produkty</h3>
            </div>
            <div class="card-body">
              <eself-table [data]="transaction.transaction_products">
                <ng-template eselfTableHeader>
                  <th scope="col" class="text-start">id produktu</th>
                  <th scope="col" class="text-center">nazwa produktu</th>
                  <th scope="col" class="text-center">typ</th>
                  <th scope="col" class="text-center">cena</th>
                  <th scope="col" class="text-center">ilość</th>
                  <th scope="col" class="text-end">łącznie</th>
                </ng-template>
                <ng-template
                  [eselfTableRow]="transaction.transaction_products"
                  let-row
                >
                  <td scope="row" data-title="id produktu" class="text-start">
                    {{ row.id }}
                  </td>
                  <td scope="row" data-title="nazwa produktu">
                    {{ row.name }}
                  </td>
                  <td scope="row" data-title="typ transakcji">
                    {{
                      row.type
                        | transactionProductTypeText : 'Fizyczny' : 'Wirtualny'
                    }}
                  </td>
                  <td scope="row" data-title="data transakcji">
                    {{ row.price | currencyNumber : 'PLN' }}
                  </td>
                  <td scope="row" data-title="kwota">
                    {{ row.quantity }}
                  </td>
                  <td
                    *ngIf="row.quantity && row.price"
                    scope="row"
                    class="text-end"
                    data-title="łącznie"
                  >
                    {{ row.quantity * row.price | currencyNumber : 'PLN' }}
                  </td>
                </ng-template>
              </eself-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
