import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  log(message: string): void {
    console.log(`[LOG] ${message}`);
  }
  error(message: string): void {
    console.error(`[ERROR] ${message}`);
  }
  warn(message: string): void {
    console.log(`[WARN] ${message}`);
  }
  info(message: string): void {
    console.log(`[INFO] ${message}`);
  }
}
