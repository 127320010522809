import { Component } from '@angular/core';
import {
  CommissionHistoryModel,
  CommissionModel,
  SelectedShopService,
  retryPipe,
} from '@eself/shared-services';
import { ConfirmModalComponent } from '@eself/shared-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeeResource, FeeService, ShopResource, ShopService } from 'api-client';
import { Observable, of, tap } from 'rxjs';

@Component({
  templateUrl: './shop-commission-page.component.html',
  styleUrls: ['./shop-commission-page.component.scss'],
})
export class ShopCommissionPageComponent {
  commission: CommissionModel | null = null;
  failed = false;
  isLoading = true;
  selectedFee: FeeResource | null = null;

  history$: Observable<CommissionHistoryModel[]> = of([]);
  shopId = 0;
  actualFeeId: number | null = null;
  note: string | null = null;
  shop$: Observable<ShopResource> = new Observable();
  fees$: Observable<FeeResource[]> = new Observable();
  constructor(
    private feeService: FeeService,
    private shopService: ShopService,
    private selectedShopService: SelectedShopService,
    private modalService: NgbModal
  ) {
    const shop = this.selectedShopService.getShop();
    this.shopId = shop?.id ?? 0;
    this.newShopRequest();
    this.fees$ = feeService.getFees().pipe(retryPipe());
  }

  private newShopRequest() {
    this.shop$ = this.shopService.getShop(this.shopId).pipe(
      retryPipe(),
      tap((shop) => {
        this.isLoading = false;
        this.note = shop.note ?? null;
        this.actualFeeId = shop.fee?.id ?? null;
      })
    );
  }

  public async onChangeCommission(): Promise<void> {
    if (this.selectedFee?.id) {
      try {
        const confirmModalRef = this.modalService.open(ConfirmModalComponent);
        confirmModalRef.componentInstance.message = `Czy chcesz potwierdzić zmianę abonamentu?`;
        const result = await confirmModalRef.result;
        if (result === true) {
          this.isLoading = true;
          this.shopService
            .changeShopFeeNote(this.shopId, this.selectedFee.id, {
              note: this.note,
            })
            .subscribe(() => {
              this.selectedFee = null;
              this.newShopRequest();
              this.fees$ = this.feeService.getFees().pipe(retryPipe());
            });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    }
  }

  onFeeSelect(fee: FeeResource): void {
    this.selectedFee = fee;
  }
}
