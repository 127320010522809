import { Component } from '@angular/core';
import {
  OrderModel,
  PaginatedTableData,
  SelectedShopService,
  retryPipe,
} from '@eself/shared-services';
import { SelectableModel, ToastService } from '@eself/shared-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  InvoiceService,
  SettlementResource,
  SettlementService,
  WithdrawalStatus,
} from 'api-client';
import { CreateInvoiceModalComponent } from '../../../../shared/components/modals/create-invoice-modal/create-invoice-modal.component';
import { EChartsOption } from 'echarts';
import { Subject, map, startWith, switchMap, tap } from 'rxjs';

@Component({
  templateUrl: './shop-eself-billing-page.component.html',
  styleUrls: ['./shop-eself-billing-page.component.scss'],
})
export class ShopEselfBillingPageComponent {
  active = 1;
  isLoading = true;
  selectedModels: SelectableModel<SettlementResource>[] = [];
  options: EChartsOption | null = null;
  options2: EChartsOption | null = null;

  pageIndex = 0;
  pageSize = 10;
  totalCount = 0;
  searchTerm: string | null = null;

  withdrawalStatusFilter: WithdrawalStatus | null = null;

  pageChangeSubject = new Subject<{ pageIndex: number; pageSize: number }>();

  pageData$ = this.pageChangeSubject.pipe(
    tap(() => (this.isLoading = true)),
    startWith({ pageIndex: this.pageIndex, pageSize: this.pageSize }),
    switchMap((pageDetails) =>
      this.settlementService
        .getEselfSettlements(
          this.selectedShopService.getShop()?.id ?? 0,
          pageDetails.pageIndex
        ) //use here pageDetails
        .pipe(
          tap((frame) => {
            this.pageIndex = frame.pagination?.current_page ?? 0;
            this.pageSize = frame.pagination?.per_page ?? 0;
            this.selectedModels = [];
          }),
          map((frame) => {
            this.totalCount = frame.pagination?.total ?? 0;
            if (this.totalCount < this.pageIndex * this.pageSize) {
              this.pageIndex = 0;
            }

            return {
              data:
                frame.data?.map(
                  (x) =>
                    ({
                      data: x,
                      selected: false,
                      disabled: x.invoice_status! == 1,
                    } as SelectableModel<SettlementResource>)
                ) ?? [],
              pagination: frame.pagination,
            } as PaginatedTableData<SelectableModel<SettlementResource>>;
          })
        )
    ),
    tap(() => (this.isLoading = false))
  );

  constructor(
    private settlementService: SettlementService,
    private selectedShopService: SelectedShopService,
    private invoicesService: InvoiceService,
    private modalService: NgbModal,
    private toast: ToastService
  ) {}

  public checkUnchecked(
    data: PaginatedTableData<SelectableModel<SettlementResource>>
  ) {
    data.data
      ?.filter((x) => !x.selected && !x.disabled)
      .forEach((x) => {
        x.selected = true;
        this.selectedModels.push(x);
      });
  }

  public onPageChange(pageIndex: number): void {
    this.pageChangeSubject.next({ pageIndex, pageSize: this.pageSize });
  }

  public async activeIdChange(id: number): Promise<void> {
    if (id == 2) {
      this.setOptions();
    } else {
      this.options = null;
      this.options2 = null;
    }
  }
  public withdraw() {
    const modalRef = this.modalService.open(CreateInvoiceModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.settlements = this.selectedModels.map(
      (x) => x.data
    );
    modalRef.result.then((invoice) => {
      this.invoicesService.createInvoice(invoice).subscribe(
        (invoice) => {
          this.toast.show(
            'Faktura została utworzona! Znajdziesz ją w zakładce "Faktury"'
          );
        },
        (error) => {
          this.toast.show('Błąd podczas tworzenia faktury');
          console.log(error);
        }
      );
    });
  }

  public canWithdraw(data: SelectableModel<SettlementResource>[]) {
    return data.filter((x) => x.selected).length > 0;
  }

  public showAll() {
    this.withdrawalStatusFilter = null;
  }

  public filterOnWithdrawReady() {
    this.withdrawalStatusFilter = WithdrawalStatus.NUMBER_1;
  }

  public filterOnWithdraw() {
    this.withdrawalStatusFilter === WithdrawalStatus.NUMBER_2;
  }

  public onSelectionChanged(model: SelectableModel<SettlementResource>) {
    if (this.selectedModels.length > 0) {
      this.selectedModels = this.selectedModels.filter(
        (x) => x.data.id !== model.data.id
      );
    }
    if (model.selected) {
      this.selectedModels.push(model);
    }
  }

  public setOptions(): void {
    this.options = {
      title: {
        left: '50%',
        text: 'Aktualne saldo prowizji e-self',
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        align: 'auto',
        bottom: 10,
        data: ['Saldo dostępne', 'Kwota prowizji'],
      },
      calculable: true,
      series: [
        {
          name: '',
          type: 'pie',
          radius: '55%',
          center: ['50%', '50%'],
          emphasis: {
            focus: 'self',
          },
          data: [
            { value: 1200, name: 'Saldo dostępne' },
            { value: 22.8, name: 'Kwota prowizji' },
          ],
        },
      ],
    };
    this.options = {
      title: {
        left: '50%',
        text: 'Aktualne saldo bramki płatności',
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        align: 'auto',
        bottom: 10,
        data: ['Saldo dostępne', 'Kwota prowizji'],
      },
      calculable: true,
      series: [
        {
          name: '',
          type: 'pie',
          radius: '55%',
          center: ['50%', '50%'],
          emphasis: {
            focus: 'self',
          },
          data: [
            { value: 1200, name: 'Saldo dostępne' },
            { value: 22.8, name: 'Kwota prowizji' },
          ],
        },
      ],
    };

    this.options2 = {
      legend: {},
      tooltip: {},
      dataset: {
        // Provide a set of data.
        source: [
          ['product', '01.2023', '02.2023', '03.2023'],
          ['Wypłacone', ...this.getRandomValues()],
          ['Nie wypłacone', ...this.getRandomValues()],
          ['Odrzucone', ...this.getRandomValues()],
        ],
      },
      // Declare an x-axis (category axis).
      // The category map the first column in the dataset by default.
      xAxis: { type: 'category' },
      // Declare a y-axis (value axis).
      yAxis: {},
      // Declare several 'bar' series,
      // every series will auto-map to each column by default.
      series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
    };
  }

  private getRandomValues() {
    const res: number[] = [];
    for (let i = 0; i < 3; i++) {
      res.push(Math.random() * 100);
    }
    return res;
  }
}
