import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentTypeIcon',
})
export class PaymentTypeIconPipe implements PipeTransform {
  transform(value: number | undefined, ...args: unknown[]): string {
    return 'assets/icons/blik.png';
  }
}
