import { Directive, Input } from '@angular/core';

import { TableHeaderTemplateContext } from '../interfaces/table-header-template-context';

@Directive({
  selector: 'ng-template[eselfTableHeader]',
})
export class TableHeaderTemplateDirective<TItem extends object> {
  @Input('eselfTableHeader') data!: TItem[] | '';

  static ngTemplateContextGuard<TContextItem extends object>(
    dir: TableHeaderTemplateDirective<TContextItem>,
    ctx: unknown
  ): ctx is TableHeaderTemplateContext<TContextItem> {
    return true;
  }
}
