<h3 class="m-4">Globalna konfiguracja prowizji</h3>
<div class="card m-4">
  <div class="card-header d-flex justify-content-between">
    <h3>Lista prowizji</h3>
    <button class="btn btn-success" (click)="onCreateCommission()">
      + Utwórz nową
    </button>
  </div>
  <div class="card-body">
    <ng-container
      *ngIf="commissions$ | stateWrapper | async as result"
      [ngSwitch]="result.status"
    >
      <ng-container *ngSwitchCase="'loading'">
        <div class="m-4 mt-0 d-flex">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
          <h4 class="ms-4">Ładowanie ...</h4>
        </div></ng-container
      >
      <ng-container *ngSwitchCase="'loaded'">
        <eself-table [data]="result.value ?? []" [pageSize]="50">
          <ng-template eselfTableHeader>
            <th>Nazwa</th>
            <th>Zniżka</th>
            <th>Typ</th>
            <th>Cena miesięczna</th>
            <th>Prowizja</th>
            <th>Mikro opłata</th>
            <th>Limit</th>
            <th>Ważność</th>
            <th>Parametry</th>
            <th>Akcje</th>
          </ng-template>
          <ng-template [eselfTableRow]="result.value ?? []" let-row>
            <td data-title="Nazwa">{{ row.name }}</td>
            <td data-title="Zniżka">{{ row.discount_text }}</td>
            <td data-title="Typ">{{ row.type | commissionTypeReadable }}</td>
            <td data-title="Cena miesięczna">
              {{ row.monthly_price | currencyNumber : 'PLN' }}
            </td>
            <td data-title="Prowizja">
              {{ row.fee_percent ?? 0 | currencyNumber : '%' }}
            </td>
            <td data-title="Mikro opłata">
              {{ row.micro_fee ?? 0 | currencyNumber : 'PLN' }}
            </td>
            <td data-title="Limit">
              {{ row.transaction_limit ?? 0 }}
            </td>
            <td data-title="Ważność">
              {{ row.valid_from | safeDate }} do {{ row.valid_to | safeDate }}
            </td>
            <td data-title="Parametry">
              <div class="d-flex flex-column">
                <div
                  class="alert alert-light p-1 m-2"
                  *ngFor="let bullet of row.parameters; let i = index"
                  role="alert"
                >
                  {{ i + 1 }}. {{ bullet.text }}
                </div>
              </div>
            </td>
            <td data-title="Akcje">
              <button class="btn btn-primary" (click)="onEditCommission(row)">
                Edytuj
              </button>
              <button class="btn btn-danger" (click)="onDeleteCommission(row)">
                Usuń
              </button>
            </td>
          </ng-template>
        </eself-table></ng-container
      >
      <ng-container *ngSwitchCase="'error'">
        <p class="text-center">Wystąpił problem..</p></ng-container
      >
      <ng-container *ngSwitchCase="'empty'">
        <p class="text-center">Brak wyników..</p></ng-container
      >
    </ng-container>
  </div>
</div>
