import { Component, OnInit } from '@angular/core';
import {
  OrderModel,
  ShopsService,
  SelectedShopService,
  retryPipe,
} from '@eself/shared-services';
import { ToastService } from '@eself/shared-ui';
import {
  SettlementService,
  ShopResource,
  ShopSaldoResource,
  ShopService,
  TransactionResource,
  TransactionService,
} from 'api-client';
import {
  Observable,
  Subject,
  combineLatest,
  flatMap,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  templateUrl: './shop-balance-page.component.html',
  styleUrls: ['./shop-balance-page.component.scss'],
})
export class ShopBalancePageComponent {
  public date: Date = new Date();

  isLoading = true;
  shopId = 0;
  shop$: Observable<ShopResource> = new Observable();
  shopSaldo$: Observable<ShopSaldoResource> = new Observable();

  pageChangeSubject = new Subject<{ pageIndex: number }>();

  pageIndex = 1;
  searchTerm: string | null = null;

  pageData$ = combineLatest([
    this.pageChangeSubject.pipe(startWith({ pageIndex: this.pageIndex })),
  ]).pipe(
    switchMap(([page]) =>
      this.transactionService
        .getShopTransactions(this.shopId, page.pageIndex)
        .pipe(
          tap(() => {
            this.pageIndex = page.pageIndex;
          }),
          map((transactions) => {
            this.isLoading = false;
            return transactions;
          })
        )
    )
  );
  shopTransactions$: Observable<TransactionResource[]> = new Observable();
  constructor(
    private shopService: ShopService,
    private selectedShopService: SelectedShopService,
    private transactionService: TransactionService,
    private settlementService: SettlementService,
    private toasts: ToastService
  ) {
    const shop = this.selectedShopService.getShop();
    this.shopId = shop?.id ?? 0;
    this.shopSaldo$ = this.shopService
      .getShopSaldo(this.shopId)
      .pipe(retryPipe());
    this.shopTransactions$ = this.transactionService
      .getShopTransactions(this.shopId)
      .pipe(
        retryPipe(),
        map((transactions) => transactions.data ?? []),
        tap(() => (this.isLoading = false))
      );

    this.shopId = this.selectedShopService.getShop()?.id ?? 0;
    this.onPageChange(0);
  }

  public onPageChange(pageIndex: number): void {
    this.isLoading = true;
    this.pageChangeSubject.next({ pageIndex });
  }

  public synchronize() {
    this.settlementService.dispatchTransactions().subscribe(() => {
      this.toasts.show('Wymuszono synchronizację!');
      this.onPageChange(0);
    });
  }
}
