import { Directive, Input } from '@angular/core';

import { TableRowTemplateContext } from '../interfaces/table-row-template-context';

@Directive({
  selector: 'ng-template[eselfTableRow]',
})
export class TableRowTemplateDirective<TItem extends object> {
  @Input('eselfTableRow') data!: TItem[];

  static ngTemplateContextGuard<TContextItem extends object>(
    dir: TableRowTemplateDirective<TContextItem>,
    ctx: unknown
  ): ctx is TableRowTemplateContext<TContextItem> {
    return true;
  }
}
