import { Observable, timer } from 'rxjs';
import { retry, RetryConfig } from 'rxjs/operators';

export function retryPipe<T>(
  count?: number | undefined
): (source: Observable<T>) => Observable<T> {
  count = count ?? 3;
  return (source: Observable<T>) =>
    source.pipe(
      retry({
        count,
        resetOnSuccess: true,
        delay: (i) => timer(i * i * 1000),
      } as RetryConfig)
    );
}
