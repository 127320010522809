<h3 class="m-4">Rozliczenia eself</h3>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" (activeIdChange)="activeIdChange($event)" class="nav-tabs ps-4">
  <li [ngbNavItem]="1">
    <button ngbNavLink>Dane</button>
    <ng-template ngbNavContent>
      <div class="d-flex flex-column p-4">
        <div class="d-flex"></div>
        <eself-state-table [data$]="pageData$">
          <ng-template #actions let-row>
            <div class="d-flex w-100">
              <button *ngIf="selectedModels.length > 0" class="btn btn-primary mt-2 mb-2" (click)="withdraw()">
                Utwórz fakturę ({{ selectedModels.length }})
              </button>
              <button class="btn btn-primary mt-2 mb-2 ms-auto" (click)="checkUnchecked(row)">
                Zaznacz nierozliczone (na stronie)
              </button>
            </div>
          </ng-template>
          <ng-template eselfTableHeader>
            <th scope="col" class="text-center">wypłać</th>
            <th scope="col" class="text-start">status rozliczenia</th>
            <th scope="col" class="text-end">kwota całkowita</th>
            <th scope="col" class="text-end">łączne opłaty</th>
            <th scope="col" class="text-end">kwota wypłaty*</th>
            <th scope="col" class="text-center">data wypłaty*</th>
            <th scope="col" class="text-center">data rozliczenia*</th>
            <th scope="col" class="text-center">ID</th>
          </ng-template>
          <ng-template #pagination let-row><eself-table-pagination [pagination]="row"
              (moveToPage)="onPageChange($event)"> </eself-table-pagination>
            <eself-common-loading *ngIf="isLoading "></eself-common-loading>
          </ng-template>
          <ng-template #row let-row>
            <td scope="row" data-title="wypłać">
              <input *ngIf="!row.disabled; else ready" class="form-check-input" type="checkbox" value=""
                id="flexCheckDefault" [(ngModel)]="row.selected" (change)="onSelectionChanged(row)" />
              <ng-template #ready>
                <i class="material-icons text-success big-icon">check</i>
              </ng-template>
            </td>
            <td scope="row" class="text-start" data-title="status wypłaty" placement="bottom"
              [ngbTooltip]="row.data.withdrawal_status | withdrawalStatusText" [openDelay]="50" [closeDelay]="300"
              container="body">
              <i class="material-icons" [style.color]="row.data.withdrawal_status | withdrawalStatusColor">
                {{ row.data.withdrawal_status | withdrawalStatusIcon }}
              </i>
              -
              {{ row.data.withdrawal_status | withdrawalStatusText }}
            </td>
            <td scope="row" class="text-end" data-title="kwota całkowita">
              {{ row.data.amount | currencyNumber : row.data.currency }}
            </td>
            <td scope="row" class="text-end" class="text-end" data-title="łączne opłaty underline" placement="bottom"
              container="body">
              <u>
                {{ row.data.total_fee_gross | currencyNumber : row.data.currency }}
              </u>
            </td>
            <td scope="row" class="text-end" data-title="kwota do zapłaty">
              {{ row.data.withdrawal_amount | currencyNumber }}
              {{ row.data.currency }}
            </td>
            <td scope="row" data-title="data wypłaty*">
              {{ row.data.withdrawal_date | safeDate : 'long' }}
            </td>
            <td scope="row" data-title="data rozliczenia*">
              {{ row.data.settlement_date | safeDate : 'long' }}
            </td>
            <td scope="row" data-title="id">#{{ row.data.id }}</td>
          </ng-template>
        </eself-state-table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <button ngbNavLink>Analiza</button>
    <ng-template ngbNavContent>
      <div class="d-flex m-4">
        <div echarts [options]="options" class="demo-chart mt-4">
          Ładowanie..
        </div>
        <div echarts [options]="options2" class="demo-chart mt-4"></div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="pt-2"></div>
