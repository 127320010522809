import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { retryPipe } from '@eself/shared-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CreateIndividualPaymentwallRequest,
  FeeRequest,
  FeeResource,
  FeeService,
  FeeType,
  PaymentWallResource,
  PaymentWallService,
  ShopType,
  UpdatePaymentwallRequest,
} from 'api-client';
import { CommissionConfigurationModalComponent } from '../modals/commission-configuration-modal/commission-configuration-modal.component';
import { Observable, map, tap } from 'rxjs';
import { CreatePaymentWallModalComponent } from '../modals/create-payment-wall-modal/create-payment-wall-modal.component';

export interface Step3Form {
  feeId: number;
  gateId: number;
  merchantId: string;
  note: string;
}

@Component({
  selector: 'app-shop-onboarding-step3',
  templateUrl: './shop-onboarding-step3.component.html',
  styleUrls: ['./shop-onboarding-step3.component.scss', '../onboarding.scss'],
})
export class ShopOnboardingStep3Component {
  @Output() back = new EventEmitter<void>();
  @Output() formResult = new EventEmitter<Step3Form>();

  @Input() nipOrKrs = '';
  @Input() shopType: ShopType | null = null;
  form: FormGroup;

  @Input() showBack = false;
  @Input() showNext = true;
  @Input() showStep = true;

  selectedFeeId: number | null = null;
  selectedGateId: number | null = null;

  selectedFee: FeeResource | null = null;
  selectedGate: PaymentWallResource | null = null;
  individualGate: PaymentWallResource | null = null;
  availableFees: Observable<FeeResource[]> = new Observable();
  availableGates: Observable<PaymentWallResource[]> = new Observable();

  @Input() set formValue(value: Step3Form | null) {
    if (value) {
      this.form.setValue({
        merchantId: value.merchantId,
        note: value.note,
      });
      this.selectedFeeId = value.feeId;
      this.selectedGateId = value.gateId;
    }
  }

  constructor(
    private feeService: FeeService,
    private modalService: NgbModal,
    private paymentWallService: PaymentWallService
  ) {
    this.form = new FormGroup({
      merchantId: new FormControl(''),
      note: new FormControl(''),
    });
    this.refreshFees();
    this.refreshGates();
  }

  public onCreateCommission(): void {
    const modal = this.modalService.open(
      CommissionConfigurationModalComponent,
      {
        size: 'lg',
      }
    );
    modal.componentInstance.commissionTypeLock = FeeType.NUMBER_4;
    modal.componentInstance.dateEndEnabled = false;
    modal.result.then((result: FeeRequest) => {
      this.feeService.createFee(result).subscribe(() => this.refreshFees());
    });
  }

  public onCloneGatePerClient() {
    if (!this.selectedGate) {
      return;
    }
    const modal = this.modalService.open(CreatePaymentWallModalComponent, {
      size: 'xl',
    });
    modal.componentInstance.original = this.selectedGate;
    modal.result.then((result: CreateIndividualPaymentwallRequest) => {
      this.paymentWallService
        .createIndividualPaymentwall(result)
        .subscribe((result) => {
          this.refreshGates();
          this.individualGate = result;
          this.selectedGate = null;
        });
    });
  }

  public onCloneCommission(fee: FeeResource | null) {
    if (!fee) {
      return;
    }
    const modal = this.modalService.open(
      CommissionConfigurationModalComponent,
      {
        size: 'lg',
      }
    );
    modal.componentInstance.commissionTypeLock = FeeType.NUMBER_4;
    fee.id = 0;
    modal.componentInstance.commissionToEdit = fee;
    modal.componentInstance.dateEndEnabled = false;
    modal.result.then((result: FeeRequest) => {
      this.feeService.createFee(result).subscribe(() => {
        this.refreshFees();
      });
    });
  }

  private refreshFees(): void {
    this.availableFees = this.feeService.getFees().pipe(
      retryPipe(),
      map((x) => x.filter((y) => y.type !== FeeType.NUMBER_4)),
      tap((fees) => {
        if (this.selectedFeeId) {
          const fee = fees.find(
            (x) => x.id != null && x.id == this.selectedGateId
          );
          if (fee) {
            this.onFeeSelect(fee);
          }
        }
      })
    );
  }

  public refreshGates(): void {
    this.availableGates = this.paymentWallService.getPaymentWalls().pipe(
      retryPipe(),
      tap((gates) => {
        if (this.selectedGateId) {
          const gate = gates.find(
            (x) => x.id != null && x.id == this.selectedGateId
          );
          if (gate) {
            this.onGateSelect(gate);
          }
        }
      })
    );
  }

  onBack(): void {
    this.back.emit();
  }

  onSubmit(): void {
    if (this.selectedFee?.id && this.selectedGate?.id) {
      this.formResult.emit({
        feeId: this.selectedFee.id,
        gateId: this.individualGate?.id ?? this.selectedGate.id,
        merchantId: this.form.value.merchantId,
      } as Step3Form);
    }
  }

  onFeeSelect(fee: FeeResource): void {
    this.selectedFee = fee;
  }

  onGateSelect(gate: PaymentWallResource): void {
    this.selectedGate = gate;
  }
}
