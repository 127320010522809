import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TransactionResource } from 'api-client';
import { SortOrderType } from '../models/order-type.enum';

@Injectable()
@Pipe({
  name: 'transactionOrder',
  pure: true,
})
export class TransactionOrderPipe implements PipeTransform {
  transform(
    value: TransactionResource[],
    property: string,
    order: SortOrderType
  ): TransactionResource[] {
    if (order === SortOrderType.NONE || property === '') {
      return value;
    }
    const sortOrder = order === SortOrderType.ASC ? 1 : -1;

    switch (property) {
      case 'transactionDate':
        return value.sort((a, b) =>
          this.sortByDate(
            new Date(a.transaction_timestamp ?? ''),
            new Date(b.transaction_timestamp ?? ''),
            sortOrder
          )
        );
    }

    return value.sort((a, b) =>
      this.sortData((<any>a)[property], (<any>b)[property], sortOrder)
    );
  }

  private sortByDate(a: Date, b: Date, sortOrder: number) {
    if (a < b) {
      return -1 * sortOrder;
    } else if (a > b) {
      return 1 * sortOrder;
    } else {
      return 0 * sortOrder;
    }
  }

  private sortData(a: any, b: any, sortOrder: number) {
    if (a < b) {
      return -1 * sortOrder;
    } else if (a > b) {
      return 1 * sortOrder;
    } else {
      return 0 * sortOrder;
    }
  }
}
