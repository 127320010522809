import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private loggerService: LoggerService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const title =
          'Wystąpił problem podczas obsługi twojego żądania! ' +
          (error.error instanceof ErrorEvent
            ? '[po stronie klienta]'
            : '[po stronie serwera]');
        let message = 'Nieznany błąd!';
        if (error.status == 404) {
          return throwError(error);
        }
        if (error.status == 400) {
          message = 'Serwer odrzucił operację przed jej wykonaniem.';
        } else if (error.status == 500) {
          message =
            'Serwer odrzucił operację w trakcie jej wykonywania. Część zmian mogła zostać zapisana.';
        } else if (error.status == 401 || error.status == 403) {
          message =
            'Problem z potwierdzeniem tożsamości. Spróbuj zalogować się ponownie';
          this.router.navigate(['/auth']);
        }
        console.warn(message);
        console.error(error);
        return throwError(error);
      })
    );
  }
}
