import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentToIcon',
})
export class PaymentToIconPipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): string | null {
    switch (value) {
      case 'transfer':
        return 'assets/icons/bank.jpg';
      case 'przelew':
        return 'assets/icons/bank.jpg';
      case 'blik':
        return 'assets/icons/blik.png';
      case 'card':
        return 'assets/icons/card.png';
      case 'karta':
        return 'assets/icons/card.png';
    }
    return null;
  }
}
