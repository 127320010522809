import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bankToIcon',
})
export class BankToIconPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string | null {
    switch (value) {
      case 'mbank':
        return 'assets/icons/banks/mbank.png';
      case 'milenium':
        return 'assets/icons/banks/millenium.jpg';
      case 'ing':
        return 'assets/icons/banks/ing.png';
      case 'pko':
        return 'assets/icons/banks/pko.png';
      case 'bnp':
        return 'assets/icons/banks/bnp.jpg';
      case 'santander':
        return 'assets/icons/banks/santander.png';
      case 'getin':
        return 'assets/icons/banks/getin.webp';
      case 'credit-agricole':
        return 'assets/icons/banks/credit-agricole.jpg';
      case 'bank-pocztowy':
        return 'assets/icons/banks/bank-pocztowy.jpg';
      case 'bank-pekao':
        return 'assets/icons/banks/bank-pekao.png';
    }
    return null;
  }
}
