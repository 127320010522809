import { Component, Input } from '@angular/core';
import { PaginatedTableData, PaginationData } from '@eself/shared-services';
import { Observable, Subject } from 'rxjs';

@Component({
  templateUrl: './rodo-table.component.html',
  styleUrls: ['./rodo-table.component.scss'],
})
export class RodoTableComponent {
  @Input()
  displayShopId = false;

  isLoading = true;
  pageIndex = 0;
  totalCount = 0;

  pageChangeSubject = new Subject<{ pageIndex: number }>();

  pageData$: Observable<PaginatedTableData<any>> = new Observable();
}
