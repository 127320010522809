import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateInvoiceRequest, SettlementResource } from 'api-client';

@Component({
  templateUrl: './create-invoice-modal.component.html',
  styleUrls: ['./create-invoice-modal.component.scss'],
})
export class CreateInvoiceModalComponent {
  @Input() settlements: SettlementResource[] = [];
  form: FormGroup;

  public get totalAmount(): number {
    return this.settlements.reduce(
      (acc, settlement) => acc + (settlement.amount ?? 0),
      0
    );
  }

  public get totalEselfVat(): number {
    return this.settlements.reduce(
      (acc, settlement) => acc + (settlement.eself_fee_vat ?? 0),
      0
    );
  }

  public get totalMicroFeeVat(): number {
    return this.settlements.reduce(
      (acc, settlement) => acc + (settlement.micro_fee_vat ?? 0),
      0
    );
  }

  constructor(public activeModal: NgbActiveModal) {
    this.form = new FormGroup({
      invoice_number: new FormControl(''),
      issue_date: new FormControl(''),
      note: new FormControl(''),
    });
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit(): void {
    const selectedDate = new Date(this.form.value.issue_date);
    const formattedDate = selectedDate
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    const invoice: CreateInvoiceRequest = {
      invoice_number: this.form.value.invoice_number,
      issue_date: formattedDate,
      note: this.form.value.note,
      settlements: this.settlements
        .map((settlement) => settlement.id ?? 0)
        .filter((id) => id !== 0),
    };
    this.activeModal.close(invoice);
  }
}
