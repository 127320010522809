<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="invoiceModalLabel">
      Edycja Faktury #{{ invoice?.id }}
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="onClose()"
    ></button>
  </div>
  <div *ngIf="invoice" class="modal-body">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="invoice_number">Numer faktury</label>
        <input
          type="text"
          id="invoice_number"
          class="form-control"
          formControlName="invoice_number"
          required
        />
      </div>

      <div class="form-group">
        <label for="issue_date">Data wystawienia</label>
        <input
          type="datetime-local"
          id="issue_date"
          class="form-control"
          formControlName="issue_date"
          required
        />
      </div>

      <div class="form-group">
        <label for="note">Notatka</label>
        <textarea
          id="note"
          class="form-control"
          formControlName="note"
          required
        ></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex">
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          [disabled]="form.invalid"
          (click)="onSubmit()"
        >
          Zapisz
        </button>
      </div>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        (click)="onClose()"
      >
        Zamknij
      </button>
    </div>
  </div>
</div>
