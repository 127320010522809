<ng-container
  *ngIf="data$ | stateWrapper | async as result"
  [ngSwitch]="result.status"
>
  <ng-container *ngSwitchCase="'loaded'">

    <section *ngIf="actions">
      <ng-container
        *ngTemplateOutlet="actions; context: { $implicit: result.value }"
      ></ng-container>
    </section>


    <span *ngIf="displayPaginationDescription && result.value?.pagination">
      Widzisz {{ getStartingItems(result.value?.pagination) }}-{{
        getEndingItems(result.value)
      }}
      z {{ result.value?.pagination?.total }} wyników.
    </span>

    <section *ngIf="pagination">
      <ng-container
        *ngTemplateOutlet="
          pagination;
          context: { $implicit: result.value?.pagination }
        "
      ></ng-container>
    </section>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead *ngIf="headers" class="">
          <tr>
            <ng-container
              *ngTemplateOutlet="headers; context: { $implicit: result.value }"
            ></ng-container>
          </tr>
        </thead>
        <tbody *ngIf="rows" class="table-group-divider">
          <tr *ngFor="let row of result.value?.data">
            <ng-container
              *ngTemplateOutlet="rows; context: { $implicit: row }"
            ></ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <section *ngIf="pagination">
      <ng-container
        *ngTemplateOutlet="
          pagination;
          context: { $implicit: result.value?.pagination }
        "
      ></ng-container>
    </section>

    <span *ngIf="displayPaginationDescription && result.value?.pagination">
      Widzisz {{ getStartingItems(result.value?.pagination) }}-{{
        getEndingItems(result.value)
      }}
      z {{ result.value?.pagination?.total }} wyników.
    </span>
  </ng-container>

  <eself-common-loading *ngSwitchCase="'loading'"></eself-common-loading>
  <eself-common-api-error *ngSwitchCase="'error'"> </eself-common-api-error>
  <eself-common-empty-collection *ngSwitchCase="'empty'">
  </eself-common-empty-collection>
</ng-container>
