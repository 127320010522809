<img
  [src]="logoUrl"
  class="pointer"
  alt="Eself logo"
  routerLink="/global/shop-selection"
/>
<span class="section" [class]="colorClass">{{ mainSection | uppercase }}</span>
<span class="section-selected" [class]="colorClass">{{
  mainSectionSelected | uppercase
}}</span>
