import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rodoStatusColor',
})
export class RodoStatusColorPipe implements PipeTransform {
  transform(value?: number | undefined, ...args: unknown[]): string {
    switch (value) {
      case 1:
        return '#912da4'; // new
      case 2:
      case 3:
        return '#68bb18'; // ready
    }
    return '#006be0';
  }
}
