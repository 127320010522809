import { Component, Input } from '@angular/core';

@Component({
  selector: 'eself-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent {
  @Input()
  public logoUrl = 'assets/images/logo.svg';

  @Input()
  public mainSection: string;

  @Input()
  public mainSectionSelected: string;

  @Input()
  public colorClass = 'primary-text-color';

  constructor() {
    this.mainSection = '';
    this.mainSectionSelected = '';
  }
}
