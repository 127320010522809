import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentTypeText',
})
export class PaymentTypeTextPipe implements PipeTransform {
  transform(value: number | undefined, ...args: unknown[]): string {
    return 'Blik';
  }
}
