/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeeType } from './feeType';
import { FeeResource } from './feeResource';


/**
 * Fee associated with the shop
 */
/**
 * @type Fee
 * Fee associated with the shop
 * @export
 */
export type Fee = FeeResource;

