import { Pipe, PipeTransform } from '@angular/core';
import { PayoutStatus } from 'api-client';

@Pipe({
  name: 'payoutStatusColor',
})
export class PayoutStatusColorPipe implements PipeTransform {
  transform(value?: PayoutStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case PayoutStatus.NUMBER_1:
        return '#912da4'; // new
      case PayoutStatus.NUMBER_2:
        return '#006be0'; // in_progress
      case PayoutStatus.NUMBER_3:
        return '#68bb18'; // ready
    }
    return '#006be0';
  }
}
