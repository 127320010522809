export interface BasicFilter<T> {
  compare(value: T): boolean;
  setPosibleValues(value: T[]): void;
  setSelectedValues(value: T[]): void;
  hasSelectedValues(): boolean;
}

export class TextFilter implements BasicFilter<string> {
  public values: string[] = [];
  public selectedValues: string[] = [];

  public compare(value: string): boolean {
    if (this.selectedValues.length > 0) {
      return this.selectedValues.includes(value);
    }
    return true;
  }
  public setPosibleValues(value: string[]) {
    this.values = value;
  }
  public setSelectedValues(value: string[]) {
    this.selectedValues = value;
  }

  public hasSelectedValues(): boolean {
    return this.selectedValues.length > 0;
  }
}

export enum NumericFilterComparator {
  Equal,
  Greater,
  Less,
  GreaterOrEqual,
  LessOrEqual,
}

export class NumericFilter implements BasicFilter<number> {
  public values: number[] = [];
  public selectedValues: number[] = [];
  public selectedComparator: NumericFilterComparator =
    NumericFilterComparator.Equal;

  public compare(value: number): boolean {
    if (this.selectedValues.length == 0) return true;
    switch (this.selectedComparator) {
      case NumericFilterComparator.Equal:
        return this.selectedValues.includes(value);
      case NumericFilterComparator.Greater:
        return this.selectedValues.some((x) => x > value);
      case NumericFilterComparator.Less:
        return this.selectedValues.some((x) => x < value);
      case NumericFilterComparator.GreaterOrEqual:
        return this.selectedValues.some((x) => x >= value);
      case NumericFilterComparator.LessOrEqual:
        return this.selectedValues.some((x) => x <= value);
    }
  }

  public setPosibleValues(value: number[]) {
    this.values = value;
  }

  public SetSelectedComparator(value: NumericFilterComparator) {
    this.selectedComparator = value;
  }

  public setSelectedValues(value: number[]) {
    this.selectedValues = value;
  }

  public hasSelectedValues(): boolean {
    return this.selectedValues.length > 0;
  }
}
