import { RouterModule, Routes } from '@angular/router';

import { CommissionConfigurationPageComponent } from './pages/commission-configuration-page/commission-configuration-page.component';
import { GateConfigurationPageComponent } from './pages/gate-configuration-page/gate-configuration-page.component';
import { GlobalComponent } from './global.component';
import { NgModule } from '@angular/core';
import { ShopSelectionPageComponent } from './pages/shop-selection-page/shop-selection-page.component';
import { TransactionsComponent } from './pages/transactions-page/transactions.component';
import { AuthGuard, ModalGuard } from '@eself/shared-services';
import { NewShopFormPageComponent } from './pages/new-shop-form-page/new-shop-form-page.component';
import { RodoPageComponent } from './pages/rodo-page/rodo-page.component';

const routes: Routes = [
  {
    path: '',
    component: GlobalComponent,
    canActivate: [AuthGuard, ModalGuard],
    canActivateChild: [ModalGuard],
    children: [
      { path: 'shop-selection', component: ShopSelectionPageComponent },
      { path: 'gate-configuration', component: GateConfigurationPageComponent },
      { path: 'new-shop', component: NewShopFormPageComponent },
      { path: 'rodo', component: RodoPageComponent },
      {
        path: 'commission-configuration',
        component: CommissionConfigurationPageComponent,
      },
      { path: 'transactions', component: TransactionsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GlobalRoutingModule {}
