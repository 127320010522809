import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatus } from 'api-client';


@Pipe({
  name: 'invoiceStatusColor',
})
export class InvoiceStatusColorPipe implements PipeTransform {
  transform(value?: InvoiceStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case InvoiceStatus.NUMBER_0:
        return '#912da4'; // new
      case InvoiceStatus.NUMBER_1:
        return '#68bb18'; // ready
    }
    return '#006be0';
  }
}
