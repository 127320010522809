import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeeRequest, FeeResource, FeeType, ParametersInner } from 'api-client';

@Component({
  templateUrl: './commission-configuration-modal.component.html',
  styleUrls: ['./commission-configuration-modal.component.scss'],
})
export class CommissionConfigurationModalComponent implements OnInit {
  @Input() commissionToEdit: FeeResource | null = null;
  _commissionTypeLock: FeeType | null = null;

  get commissionTypeLock() {
    return this._commissionTypeLock;
  }

  @Input() set commissionTypeLock(value: FeeType | null) {
    this._commissionTypeLock = value;
    if (value) {
      this.commissionForm.get('type')?.setValue(value.toString());
    }
  }

  public get commissionType(): typeof FeeType {
    return FeeType;
  }

  @Input() transactionLimitEnabled = true;

  @Input() dateEndEnabled = true;

  commissionForm = new FormGroup({
    name: new FormControl(''),
    discount: new FormControl(''),
    price: new FormControl(''),
    commission: new FormControl(''),
    microfee: new FormControl(''),
    transaction_limit: new FormControl('', null),
    note: new FormControl(''),
    start: new FormControl(''),
    end: new FormControl('', null),
    type: new FormControl(''),
  });

  bulletPoints: string[] = [];

  bulletText = '';

  constructor(public activeModal: NgbActiveModal) {}

  public onCreateCommission(): void {
    const datePipe = new DatePipe('pl-PL');
    const type: string = this.commissionForm.get('type')?.value ?? '';
    const start = datePipe.transform(
      this.commissionForm.get('start')?.value ?? '',
      'yyyy-MM-dd HH:mm:ss'
    );
    let end = undefined;
    try {
      end = datePipe.transform(
        this.commissionForm.get('end')?.value ?? '',
        'yyyy-MM-dd HH:mm:ss'
      );
    } catch (err) {
      console.error('Invalid date format', err);
    }
    const commission: FeeRequest = {
      name: this.commissionForm.get('name')?.value ?? '',
      discount_text: this.commissionForm.get('discount')?.value ?? '',
      monthly_price:
        <number>(<unknown>this.commissionForm.get('price')?.value) ?? 0,
      fee_percent:
        <number>(<unknown>this.commissionForm.get('commission')?.value) ?? 0,
      micro_fee:
        <number>(<unknown>this.commissionForm.get('microfee')?.value) ?? 0,
      parameters: this.bulletPoints.map(
        (x) => ({ text: x } as ParametersInner)
      ),
      transaction_limit: parseInt(
        this.commissionForm.get('transaction_limit')?.value ?? ''
      ),
      valid_from: start ?? '',
      valid_to: end ?? undefined,
      type: parseInt(type) as FeeType,
      desc: this.commissionForm.get('note')?.value ?? '',
    };
    this.activeModal.close(commission);
  }

  public ngOnInit(): void {
    if (!this.commissionToEdit) {
      return;
    }
    const start = new Date(this.commissionToEdit.valid_from ?? '');
    let end: string | undefined = undefined;
    if (this.commissionToEdit.valid_to !== undefined) {
      end = this.formatDate(new Date(this.commissionToEdit.valid_to ?? ''));
      this.dateEndEnabled = true;
    } else {
      this.dateEndEnabled = false;
    }
    const limit: number | undefined =
      this.commissionToEdit.transaction_limit ?? 0;
    this.transactionLimitEnabled = limit !== undefined;

    this.commissionForm.setValue({
      name: this.commissionToEdit.name ?? '',
      discount: this.commissionToEdit.discount_text ?? '',
      price: (this.commissionToEdit.monthly_price ?? 0).toString(),
      commission: (this.commissionToEdit.fee_percent ?? 0).toString(),
      microfee: (this.commissionToEdit.micro_fee ?? 0).toString(),
      transaction_limit: limit !== undefined ? limit.toString() : '',
      note: this.commissionToEdit.desc ?? '',
      start: this.formatDate(start),
      end: end ?? null,
      type: (this.commissionToEdit.type ?? FeeType.NUMBER_1).toString(),
    });
    this.bulletPoints =
      this.commissionToEdit.parameters?.map((x) => x.text) ?? [];
    this.bulletText = '';
  }

  private formatDate(date: Date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  public onAddBulletPoint() {
    this.bulletPoints.push(this.bulletText);
    this.bulletText = '';
  }

  public onClose() {
    this.activeModal.dismiss();
  }

  public onDeleteBullet(index: number) {
    this.bulletPoints.splice(index, 1);
  }

  public offerNumberToName(i: number | FeeType): string {
    switch (i) {
      case FeeType.NUMBER_1:
      case 1:
        return 'Oferta publiczna';
      case FeeType.NUMBER_2:
      case 2:
        return 'Oferta promocyjna';
      case FeeType.NUMBER_3:
      case 3:
        return 'Oferta promocyjna niewidoczna na stronie';
      case FeeType.NUMBER_4:
      case 4:
        return 'Oferta indywidualna';
    }
    return 'b.d';
  }
}
