import { Component, Input } from '@angular/core';
import { ToastService } from '@eself/shared-ui';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceResource, InvoiceService, InvoiceStatus } from 'api-client';

@Component({
  templateUrl: './invoice-details-modal.component.html',
  styleUrls: ['./invoice-details-modal.component.scss'],
})
export class InvoiceDetailsModalComponent {
  @Input() invoice: InvoiceResource | null = null;

  constructor(
    private modal: NgbActiveModal,
    private invoiceService: InvoiceService,
    private toast: ToastService
  ) {}

  public onClose(val?: boolean | undefined): void {
    if (val) {
      this.modal.close(val);
    } else {
      this.modal.dismiss();
    }
  }

  public isPaid(): boolean {
    return this.invoice?.status === InvoiceStatus.NUMBER_1;
  }

  public isCanceled(): boolean {
    return this.invoice?.status === InvoiceStatus.NUMBER_2;
  }

  public onCancel(): void {
    this.invoiceService
      .markInvoiceAsCancelled(this.invoice?.id ?? 0)
      .subscribe(() => {
        this.toast.show(
          'Faktura została oznaczona jako anulowana. #' + this.invoice?.id
        );
        this.onClose(true);
      });
  }

  public onReady(): void {
    this.invoiceService
      .markInvoiceAsRealized(this.invoice?.id ?? 0)
      .subscribe(() => {
        this.toast.show(
          'Faktura została oznaczona jako opłacona. #' + this.invoice?.id
        );
        this.onClose(true);
      });
  }
}
