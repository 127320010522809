<form [formGroup]="form" (ngSubmit)="onSubmit()" class="row g-3">
  <h3>Bramka płatnicza</h3>
  <div>
    <button
      type="button"
      class="btn btn-primary d-flex"
      [disabled]="!selectedGate || individualGate"
      (click)="onCloneGatePerClient()"
    >
      Utwórz dedykowany profil bramki
    </button>
  </div>
  <div *ngIf="individualGate">
    <h4>Wybrano indywidualną bramkę!</h4>
  </div>
  <div class="list-group">
    <app-paymentwalll-list-item
      *ngIf="individualGate"
      [paymentwall]="individualGate"
      [active]="true"
    >
    </app-paymentwalll-list-item>
    <hr *ngIf="individualGate" />
    <app-paymentwalll-list-item
      *ngFor="let gate of availableGates | async"
      [paymentwall]="gate"
      (click)="onGateSelect(gate)"
      [active]="gate.id === selectedGate?.id"
    >
    </app-paymentwalll-list-item>
  </div>
  <h3>Profil prowizji</h3>

  <div>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onCreateCommission()"
    >
      Utwórz profil
    </button>
    <button
      type="button"
      class="btn btn-primary ms-2"
      [disabled]="!selectedFee"
      (click)="onCloneCommission(selectedFee)"
    >
      Klonuj wybrany profil
    </button>
  </div>
  <div class="list-group">
    <div
      class="list-group-item list-group-item-action"
      *ngFor="let fee of availableFees | async"
      [class.active]="fee.id === selectedFee?.id"
      (click)="onFeeSelect(fee)"
    >
      <div class="d-flex justify-content-between">
        <div>
          <span class="fw-bold">{{ fee.name }}</span> <br />
          <span>
            opłata miesięczna: {{ fee.monthly_price | currencyNumber : 'PLN' }}
          </span>
          <br />
          <span>
            prowizja od transakcji:
            {{ fee.fee_percent ?? 0 | currencyNumber : '%' }}
          </span>
          <br />
          <span>
            mikro opłata:
            {{ fee.micro_fee | currencyNumber : 'PLN' }}
          </span>
          <br />
        </div>
        <div>
          Oferta ważna do:
          <span class="fw-bold">{{ fee.valid_to | safeDate }}</span> <br />
          Slogan:
          <span class="fw-bold">{{ fee.discount_text }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <label for="merchantId" class="form-label"
      >Merchant ID (tymczasowo jako tekst)</label
    ><span class="required-text">*</span>
    <input
      type="text"
      id="merchantId"
      name="merchantId"
      class="form-control"
      formControlName="merchantId"
    />
  </div>
  <div class="col-md-12">
    <label for="note" class="form-label"
      >Dodatkowe notatki (check up-list)</label
    >
    <textarea
      type="text"
      id="note"
      name="note"
      class="form-control"
      formControlName="note"
    ></textarea>
  </div>

  <div class="col-12">
    <button *ngIf="showBack" class="btn btn-secondary" (click)="onBack()">Wstecz</button>
    <button
      type="submit"
      class="btn btn-primary ms-2"
      [disabled]="!selectedFee || !selectedGate || !showNext"
    >
      Zapisz
    </button>
  </div>
</form>
