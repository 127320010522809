import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import {
  SelectedShopService,
  ShopModel,
  ShortDomainPipe,
  retryPipe,
} from '@eself/shared-services';
import { Observable, map } from 'rxjs';
import { ShopResource, ShopService } from 'api-client';

@Component({
  templateUrl: './shop-selection-page.component.html',
  styleUrls: ['./shop-selection-page.component.scss'],
})
export class ShopSelectionPageComponent implements OnInit {
  public allShops: Observable<ShopResource[] | null>;
  public visibleShops: Observable<ShopResource[] | null>;
  public searchTerm: string | null = null;
  constructor(
    private apiShopService: ShopService,
    private selectedShopService: SelectedShopService,
    private router: Router,
    private shortDomain: ShortDomainPipe
  ) {
    this.allShops = this.apiShopService.getAllShops().pipe(retryPipe(3));
    this.visibleShops = this.allShops;
  }

  public ngOnInit() {
    this.visibleShops = this.allShops;
  }

  public onNavigateToShop(shop: ShopResource): void {
    const shopModel: ShopModel = {
      id: shop.id ?? 0,
      name: shop.name ?? 'b.d',
      nip: shop.nip ?? 'b.d',
      regon: shop.regon ?? 'b.d',
      url: shop.url ?? 'b.d',
      businessMail: shop.business_mail ?? 'b.d',
      businessTel: shop.business_phone ?? 'b.d',
      customerMail: shop.client_mail ?? 'b.d',
      customerCare: shop.client_person ?? 'b.d',
      customerTel: shop.client_phone ?? 'b.d',
      logo: shop.logo_base64,
      urlReadable: this.shortDomain.transform(shop.url ?? 'b.d'),
      logoBackground: '#fff',
      commissionId: 0,
      contactPerson: shop.client_person ?? 'b.d',
      transactions: [],
    };
    this.selectedShopService.setShop(shopModel);
    this.router.navigate([`shop/${shop.id}/dashboard`]);
  }

  public search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value?.toLowerCase();
    this.searchTerm = searchTerm;
    if (searchTerm.length > 1) {
      this.visibleShops = this.allShops.pipe(
        map(
          (list) =>
            list!.filter(
              (x) =>
                //nip, regon, adres, nazwa, mail, telefon
                x.nip?.toLowerCase().includes(searchTerm) ||
                x.business_mail?.toLowerCase().includes(searchTerm) ||
                x.business_phone?.toLowerCase().includes(searchTerm) ||
                x.url?.toLowerCase().includes(searchTerm) ||
                x.regon?.toLowerCase().includes(searchTerm) ||
                x.business_mail?.toLowerCase().includes(searchTerm) ||
                x.client_mail?.toLowerCase().includes(searchTerm) ||
                x.name?.toLowerCase().includes(searchTerm)
            ) ?? null
        )
      );
    } else {
      this.visibleShops = this.allShops;
    }
  }
}
