import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
/* przelewy24 - https://developers.przelewy24.pl/marketplace/index.php?pl
1	Osoba fizyczna
2	Jednoosobowa działalność gospodarcza
3	Spółka cywilna
4	Spółka jawna
5	Spółka komandytowa
6	Spółka komandytowo-akcyjna
7	Spółka akcyjna
8	Spółka z ograniczoną odpowiedzialnością
9	Stowarzyszenie, fundacja, organizacja pożytku publicznego
10	Spółdzielnia
*/

export interface Step1Form {
  companyName: string;
  pesel?: string;
  nip?: string;
  regon?: string;
  krs?: string;
  url: string;
  email: string;
  emailInvoices: string;
  emailRodo: string;
  password: string;
  confirmPassword: string;
  phone: string;
  companyType: number;
}

@Component({
  selector: 'app-shop-onboarding-step1',
  templateUrl: './shop-onboarding-step1.component.html',
  styleUrls: ['../onboarding.scss'],
})
export class ShopOnboardingStep1Component implements OnInit {
  @Output() formResult = new EventEmitter<Step1Form>();

  @Input() showBack = false;
  @Input() showStep = true;
  @Input() showNext = true;
  @Input() skipPassword = false;
  @Input() continue = 'Dalej';

  form: FormGroup;

  peselRequired = false;

  krsRequired = false;

  nipRequired = false;

  regonRequired = false;

  @Input() set formValue(value: Step1Form | null) {
    if (value) {
      if (this.skipPassword) {
        value.password = 'emptyValue';
        value.confirmPassword = 'emptyValue';
      }
      this.form.setValue(value);
    }
  }

  constructor() {
    this.form = new FormGroup({
      companyName: new FormControl(''),
      companyType: new FormControl('2'),
      nip: new FormControl(''),
      pesel: new FormControl(''),
      regon: new FormControl(''),
      krs: new FormControl(''),
      url: new FormControl('', [
        Validators.required,
        Validators.pattern('^(http|https)://[^ "]+$'),
      ]),
      email: new FormControl(''),
      emailInvoices: new FormControl(''),
      emailRodo: new FormControl(''),
      phone: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      // accountNumber: new FormControl(''),
      // country: new FormControl('Poland'),
      // city: new FormControl(''),
      // postalCode: new FormControl(''),
      // street: new FormControl(''),
      // building: new FormControl(''),
    });
    this.updateValidators('2');
  }

  ngOnInit(): void {
    if (this.skipPassword) {
      this.form.patchValue({
        password: 'emptyValue',
        confirmPassword: 'emptyValue',
      });
      this.form.get('password')?.disable();
      this.form.get('confirmPassword')?.disable();
    }
  }

  onCompanyTypeChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    if (value) {
      this.updateValidators(value);
    }
  }

  updateValidators(value: string): void {
    this.peselRequired = value === '1';
    this.krsRequired = parseInt(value) > 3;
    this.nipRequired = value !== '1';
    this.regonRequired = value !== '1';
  }

  onSubmit() {
    this.formResult.emit(this.form.value as Step1Form);
  }
}
