import { Injectable } from '@angular/core';
import { ShopModel } from '../models/shop.model';
import { faker } from '@faker-js/faker';
import { OrderModel } from '../models/order.model';
import { PaginatedModel } from '../models/paginated.model';
import { BehaviorSubject } from 'rxjs';
import { TransactionProductResource, TransactionProductType } from 'api-client';

@Injectable({
  providedIn: 'root',
})
export class ShopsService {
  public static shops: ShopModel[] = [
    {
      id: 3,
      logo: '/assets/images/logo-mockup.svg',
      logoBackground: '#2d2b42',
      name: 'Eself',
      url: 'https://e-self.pl',
      urlReadable: 'www.eself.pl',
      businessMail: 'data.innovations.spzoo@gmail.com',
      customerMail: 'data.innovations.spzoo+customer@gmail.com',
      businessTel: '+48 123 321 123',
      customerTel: '+48 123 321 111',
      contactPerson: 'Dawid Kowalski',
      customerCare: 'Barbara Nowak',
      nip: '716-28-31-692',
      regon: '388171057',
      commissionId: 1,
      transactions: faker.helpers.multiple(
        () => ShopsService.createRandomTransaction('Eself', 1),
        {
          count: 1250,
        }
      ),
    },
    {
      id: 6,
      logo: '/assets/images/logo-mockup-2.svg',
      logoBackground: '#2d2b42',
      name: 'E-self 2',
      url: 'https://e-self.pl',
      urlReadable: 'www.e-self.pl',
      businessMail: 'example-mail@gmail.com',
      customerMail: 'customer-service@gmail.com',
      businessTel: '+48 123 333 123',
      customerTel: '+48 123 333 111',
      contactPerson: 'Adam Nowak',
      customerCare: 'Michał Kowalski',
      nip: '123-12-12-123',
      regon: '123123123',
      commissionId: 2,
      transactions: faker.helpers.multiple(
        () => ShopsService.createRandomTransaction('E-self', 2),
        {
          count: 1250,
        }
      ),
    },
  ];

  public shops$: BehaviorSubject<ShopModel[] | null>;

  constructor() {
    this.shops$ = new BehaviorSubject<ShopModel[] | null>(null);
  }

  public getShops(): void {
    this.shops$.next(ShopsService.shops);
  }

  public async getShop(id: number): Promise<ShopModel | undefined> {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return ShopsService.shops.find((x) => x.id === id);
  }

  public async setShopCommission(id: number, commissionId: number) {
    const shop = await this.getShop(id);
    if (shop) {
      shop.commissionId = commissionId;
    }
  }

  public async getAllTransactions(
    page: number,
    pageSize: number
  ): Promise<PaginatedModel<OrderModel>> {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const pageValue = ShopsService.shops
      .flatMap((x) => x.transactions)
      ?.slice((page - 1) * pageSize, page * pageSize);
    let i = 1;
    for (const iterator of pageValue) {
      iterator.id = i++;
    }
    return {
      data: pageValue ?? [],
      total: ShopsService.shops.flatMap((x) => x.transactions).length,
      page: page,
      pageSize: pageSize,
    } as PaginatedModel<OrderModel>;
  }

  public async getTransactions(
    shopId: number,
    page: number,
    pageSize: number
  ): Promise<PaginatedModel<OrderModel>> {
    const shop = ShopsService.shops.find((x) => x.id === shopId);
    if (shop === undefined || shop === null) {
      Promise.reject();
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const pageValue = shop?.transactions.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    return {
      data: pageValue ?? [],
      total: shop?.transactions.length,
      page: page,
      pageSize: pageSize,
    } as PaginatedModel<OrderModel>;
  }

  public static createRandomProduct(): TransactionProductResource {
    return {
      id: faker.number.int(),
      name: faker.commerce.productName(),
      type: faker.helpers.arrayElement([
        TransactionProductType.NUMBER_1,
        TransactionProductType.NUMBER_2,
      ]),
      price: faker.number.float({ min: 5, max: 100 }),
      quantity: faker.number.int({ min: 1, max: 5 }),
    };
  }

  public static createRandomTransaction(
    shopName: string,
    shopId: number
  ): OrderModel {
    const products = faker.helpers.multiple(() => this.createRandomProduct());
    const amount = products
      .map((x) => x.price! * x.quantity!)
      .reduce((a, b) => a + b, 0);
    const commissionGate = amount * 0.019;
    const commissionEself = commissionGate * 2;
    const payable = amount - commissionEself;
    const status = faker.number.int({ max: 3 });
    const withdrawalDate = status > 1 ? faker.date.past() : null;
    let billingDate: Date | null = null;
    if (withdrawalDate && status > 2) {
      billingDate = new Date(withdrawalDate.getTime() + 48 * 60 * 60 * 1000);
    }
    const type = faker.number.int({ max: 2 });
    return {
      id: faker.number.int(),
      uid: faker.string.uuid(),
      userId: faker.string.uuid(),
      transactionStatus: faker.number.int({ max: 3 }),
      transactionType: type,
      transactionDate: faker.date.past(),
      transactionBank: faker.helpers.arrayElement([
        'mbank',
        'milenium',
        'ing',
        'pko',
        'bnp',
        'santander',
        'getin',
        'credit-agricole',
        'bank-pocztowy',
        'bank-pekao',
      ]),
      refundAmount: 0,
      refundDate: null,
      shopId: shopId,
      shopName: shopName,
      currency: 'PLN',
      amount: amount,
      paymentGatewayId: faker.number.int(1),
      paymentType: ['blik', 'przelew', 'karta'][type],
      gatewayCommission: commissionGate,
      eselfCommission: commissionEself,
      eselfMicrotransaction: 0,
      totalCharges: commissionEself,
      amountPayable: status > 1 ? 0 : payable,
      withdrawalStatus: status,
      withdrawalDate: withdrawalDate,
      withdrawalAmount: status > 1 ? payable : 0,
      updatedTransactionAmount: status > 1 ? payable : 0,
      billingDate: billingDate,
      items: products,
    };
  }
}
