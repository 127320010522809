import { Injectable } from '@angular/core';
import {
  CommissionHistoryModel,
  CommissionModel,
  CommissionType,
} from '../models/commission.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommissionsService {
  public static baseCommissions: CommissionModel[] = [
    {
      id: 1,
      name: 'STARTER',
      discount: 'Bez abonamentu',
      price: '0 zł',
      commission: '2.9% + 50gr',
      startDate: new Date(2000, 0, 1),
      endDate: new Date(2999, 0, 1),
      type: CommissionType.public,
      note: '',
      bulletPoints: [
        'brak abonamentu',
        'certyfikat RODO',
        'zabezpieczenie przed phishingiem',
        'obsługa prawa do zapomnienia',
        'błyskawiczne transakcje',
        'ujednolicony checkout',
      ],
    },
    {
      id: 2,
      name: 'ACTIVE 250',
      discount: 'Oszczędzasz 76 zł',
      price: '49 zł',
      commission: '2.9%',
      startDate: new Date(2000, 0, 1),
      endDate: new Date(2999, 0, 1),
      type: CommissionType.public,
      note: '',
      bulletPoints: [
        'do 250 transakcji',
        'ujednolicony checkout',
        'błyskawiczne transakcje',
        'obsługa prawa do zapomnienia',
        'zabezpieczenie przed phishingiem',
        'certyfikat RODO',
      ],
    },
  ];

  public commisions$ = this.getCommissions();
  constructor() {
    this.seed();
  }

  private seed() {
    if (localStorage.getItem('commissions') === null) {
      localStorage.setItem(
        'commissions',
        JSON.stringify(CommissionsService.baseCommissions)
      );
    }
  }

  public async getCommissionById(
    id: number | string
  ): Promise<CommissionModel | null> {
    await new Promise((resolve) => setTimeout(resolve, 250));
    const commissions: CommissionModel[] = JSON.parse(
      localStorage.getItem('commissions') ?? '[]'
    );
    return commissions.find((x) => x.id == id) ?? null;
  }

  public getComissionsHistory(
    shopId: number
  ): Observable<CommissionHistoryModel[]> {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 5);
    return of([
      {
        id: 1,
        startDate: new Date(2021, 0, 1),
        endDate: endDate,
        commission: {
          id: 50,
          name: '[LEGACY] ACTIVE-1150',
          discount: 'Oszczędzasz 900 zł',
          price: '500 zł',
          commission: '3.9%',
          startDate: new Date(2000, 0, 1),
          endDate: endDate,
          type: CommissionType.public,
          note: '',
          bulletPoints: [
            'do 5000 transakcji',
            'ujednolicony checkout',
            'błyskawiczne transakcje',
            'obsługa prawa do zapomnienia',
            'zabezpieczenie przed phishingiem',
            'certyfikat RODO',
          ],
        },
      },
    ]);
  }

  public async getCommissions(): Promise<CommissionModel[]> {
    await new Promise((resolve) => setTimeout(resolve, 250));
    return JSON.parse(localStorage.getItem('commissions') ?? '[]');
  }

  public async saveCommissions(commissions: CommissionModel[]): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 250));
    localStorage.setItem('commissions', JSON.stringify(commissions));
  }
}
