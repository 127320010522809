import { Pipe, PipeTransform } from '@angular/core';
import { PayoutStatus } from 'api-client';

@Pipe({
  name: 'payoutStatusIcon',
})
export class PayoutStatusIconPipe implements PipeTransform {
  transform(value?: PayoutStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case PayoutStatus.NUMBER_1:
        return 'downloading'; // new
      case PayoutStatus.NUMBER_2:
        return 'next_plan'; // ready
      case PayoutStatus.NUMBER_3:
        return 'offline_pin'; // closed
    }
    return 'error';
  }
}
