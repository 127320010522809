import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rodoStatusText',
})

/**
 * 0 - Pending, 1 - Realized, 2 - Cancelled
 */
export class RodoStatusTextPipe implements PipeTransform {
  transform(value: number | undefined, ...args: unknown[]): string {
    if (value === 1) {
      return 'Oczekująca';
    }
    if (value === 2) {
      return 'Zrealizowana częściowo';
    }
    if (value === 3) {
      return 'Zrealizowana';
    }
    return 'Anulowana';
  }
}
