import { Component } from '@angular/core';
import { SideBarElementModel } from '@eself/shared-ui';
@Component({
  templateUrl: './global.component.html',
})
export class GlobalComponent {
  public sideBarElements: SideBarElementModel[] = [
    { icon: 'store', link: '/global/shop-selection', name: 'Sklepy' },
    { icon: 'store', link: '/global/new-shop', name: 'Dodaj nowy sklep' },
    {
      icon: 'timeline',
      link: '/global/transactions',
      name: 'Transakcje globalne',
    },
    {
      icon: 'settings',
      link: '/global/commission-configuration',
      name: 'Konfiguracja prowizji',
    },
    {
      icon: 'integration_instructions',
      link: '/global/gate-configuration',
      name: 'Konfiguracja bramki',
    },
    {
      icon: 'timeline',
      link: '/global/rodo',
      name: 'RODO - prawo do zapomnienia',
    },
  ];
}
