<p class="d-flex flex-column">
  <span>
    Prowizja ESELF:
    <b>{{ transaction?.eself_fee | currencyNumber : 'PLN' }}</b>
  </span>
  <span>
    Mikroopłata ESELF:
    <b>{{ transaction?.micro_fee | currencyNumber : 'PLN' }}</b>
  </span>
  <span>
    Opłata łączna ESELF (Netto):
    <b>{{ transaction?.total_fee_net | currencyNumber : 'PLN' }}</b>
  </span>
  <span>
    Opłata łączna ESELF (VAT):
    <b>{{ transaction?.total_fee_vat | currencyNumber : 'PLN' }}</b>
  </span>
  <span>
    Opłata łączna ESELF (Brutto):
    <b>{{ transaction?.total_fee_gross | currencyNumber : 'PLN' }}</b>
  </span>
</p>
