import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  SelectedShopService,
  CurrencyNumberPipe,
  OrderChangedEvent,
  SortOrderType,
} from '@eself/shared-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionDetailsModalComponent } from '../../../../shared/components/modals/transaction-details-modal/transaction-details-modal.component';
import { TransactionResource, TransactionService } from 'api-client';
import { Subject, combineLatest, startWith, switchMap, tap, map } from 'rxjs';

@Component({
  templateUrl: './shop-transactions-page.component.html',
  styleUrls: ['./shop-transactions-page.component.scss'],
})
export class ShopTransactionsPageComponent {
  filtersHidden = true;
  order: OrderChangedEvent = {
    property: '',
    order: SortOrderType.NONE,
  };

  pageChangeSubject = new Subject<{ pageIndex: number }>();
  searchTermSubject = new Subject<string>();

  pageIndex = 1;
  searchTerm: string | null = null;

  pageData$ = combineLatest([
    this.pageChangeSubject.pipe(startWith({ pageIndex: this.pageIndex })),
    this.searchTermSubject.pipe(startWith('')),
  ]).pipe(
    switchMap(([page, searchTerm]) =>
      this.transactionService
        .getShopTransactions(this.shopId, page.pageIndex)
        .pipe(
          tap((transactionsPage) => {
            this.dateOptions = [];
            for (const iterator of transactionsPage.data ?? []) {
              if (iterator.transaction_timestamp == null) {
                continue;
              }
              const date = new Date(iterator.transaction_timestamp);
              if (
                this.dateOptions.find(
                  (x) =>
                    x.getFullYear() == date.getFullYear() &&
                    x.getMonth() == date.getMonth()
                ) == undefined
              ) {
                this.dateOptions.push(
                  new Date(date.getFullYear(), date.getMonth(), 1)
                );
              }
            }
            this.dateOptions = this.dateOptions.sort(
              (a, b) => b.getTime() - a.getTime()
            );
          }),
          tap(() => {
            this.pageIndex = page.pageIndex;
          }),
          map((transactions) => {
            transactions.data = transactions.data?.filter((transaction) =>
              this.searchFunc(transaction, searchTerm)
            );
            this.tableLoaded = true;
            this.isLoading = false;
            return transactions;
          })
        )
    )
  );

  shopId = 0;

  data: TransactionResource[] = [];
  filteredData: TransactionResource[] = [];
  dateOptions: Date[] | null = null;
  dateSelected: Date | null = null;
  isLoading = true;
  tableLoaded = false;
  constructor(
    private transactionService: TransactionService,
    private selectedShopService: SelectedShopService,
    private currencyNumber: CurrencyNumberPipe,
    private datePipe: DatePipe,
    private modalService: NgbModal
  ) {
    this.shopId = this.selectedShopService.getShop()?.id ?? 0;
    this.onPageChange(0);
  }

  public onPageChange(pageIndex: number): void {
    this.isLoading = true;
    this.pageChangeSubject.next({ pageIndex });
  }

  public onSerachChange(searchTerm: string): void {
    this.searchTermSubject.next(searchTerm);
  }
  public search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchTermSubject.next(searchTerm);
  }

  public onOrderUpdated(order: OrderChangedEvent): void {
    this.order = order;
    this.filteredData = [...this.filterData()];
  }

  public onFilterApplied(event: any) {
    this.filteredData = [...this.filterData()];
  }

  private filterData(): TransactionResource[] {
    const preFiltered = this.data;
    //      .filter((x) => this.bankTypeFilter.compare(x.transactionBank))
    //    .filter((x) => this.paymentTypeFilter.compare(x.paymentType));
    if (this.dateSelected) {
      const date = new Date(this.dateSelected);
      return preFiltered.filter((x) => {
        const elDate = new Date(x.transaction_timestamp ?? '');
        return (
          elDate.getMonth() == date?.getMonth() &&
          elDate.getFullYear() == date?.getFullYear()
        );
      });
    }
    return preFiltered;
  }

  public openTransactionDetails(transaction: TransactionResource): void {
    const modalRef = this.modalService.open(TransactionDetailsModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.transaction = transaction;
  }

  public transactionBalanceText(item: TransactionResource): string {
    const balance = this.currencyNumber.transform(item.balance ?? 0);
    let refundText = '';
    if (item.refund_amount && item.refund_timestamp) {
      refundText = `Zwrot: ${item.refund_amount} PLN, ${this.datePipe.transform(
        item.refund_timestamp,
        'dd.MM.YYYY-HH:mm:ss'
      )} | `;
    }
    let withdrawalText = '';
    if (item.payout_timestamp && item.payout_amount) {
      const withdrawalAmount = this.currencyNumber.transform(
        item.payout_amount
      );
      withdrawalText = ` Wypłacono: ${withdrawalAmount} PLN, ${this.datePipe.transform(
        item.payout_timestamp,
        'dd.MM.YYYY-HH:mm:ss'
      )} | `;
    }
    return `${refundText} ${withdrawalText}Saldo: ${balance} PLN`;
  }

  public searchFunc(item: TransactionResource, searchTerm: string): boolean {
    return (
      (('#' + item.id).includes(searchTerm.toLowerCase()) ||
        item.price?.toString().includes(searchTerm.toLowerCase()) ||
        item.balance?.toString().includes(searchTerm.toLowerCase()) ||
        item.eself_fee?.toString().includes(searchTerm) ||
        item.micro_fee?.toString().includes(searchTerm) ||
        this.datePipe
          .transform(item.transaction_timestamp, 'dd.MM.YYYY')
          ?.includes(searchTerm.toLowerCase())) ??
      (false ||
        this.datePipe
          .transform(item.payout_timestamp, 'dd.MM.YYYY')
          ?.includes(searchTerm.toLowerCase())) ??
      false
    );
  }
}
