/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeeType } from './feeType';
import { ParametersInner } from './parametersInner';


/**
 * Fee Request body data
 */
export interface FeeRequest { 
    /**
     * Name of the fee
     */
    name: string;
    type: FeeType;
    /**
     * Transaction limit for the fee
     */
    transaction_limit?: number | null;
    /**
     * Discount text for the fee
     */
    discount_text?: string;
    /**
     * Monthly price for the fee
     */
    monthly_price: number;
    /**
     * Percentage of the fee
     */
    fee_percent?: number;
    /**
     * Micro fee for the fee
     */
    micro_fee?: number;
    /**
     * Description of the fee
     */
    desc?: string;
    /**
     * Valid from date for the fee
     */
    valid_from: string;
    /**
     * Valid to date for the fee
     */
    valid_to?: string;
    /**
     * Parameters for the fee
     */
    parameters?: Array<ParametersInner>;
}
export namespace FeeRequest {
}


