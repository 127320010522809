import { NgModule } from '@angular/core';
import {
  CommonModule,
  NgFor,
  NgIf,
  NgTemplateOutlet,
  UpperCasePipe,
} from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsContainerComponent } from './components/toasts-container/toasts-container.component';
import { TableComponent } from './components/table/table.component';
import { TablePaginationComponent } from './components/common-table/table-pagination/table-pagination.component';
import { CopyDirective } from './directives/copy.directive';
import { TableSortComponent } from './components/common-table/table-sort/table-sort.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { InputModalComponent } from './components/modals/input-modal/input-modal.component';
import { FormsModule } from '@angular/forms';
import { ValuesBasedFilterInputComponent } from './components/filters/values-based-filter-input/values-based-filter-input.component';
import { CommonApiErrorComponent } from './components/common-api-error/common-api-error.component';
import { CommonEmptyCollectionComponent } from './components/common-empty-collection/common-empty-collection.component';
import { CommonLoadingComponent } from './components/common-loading/common-loading.component';
import { StatePaginatedTableComponent } from './components/state-paginated-table/state-table.component';
import { TableSortDirective } from './components/common-table/directives/table-sort.directive';
import { TableHeaderTemplateDirective } from './components/common-table/directives/table-header-template.directive';
import { TableRowTemplateDirective } from './components/common-table/directives/table-row-template.directive';
import { SharedServicesModule } from '@eself/shared-services';
import { RouterModule } from '@angular/router';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { LayoutComponent } from './components/layout/layout.component';
import { CommissionExplainedComponent } from './components/commission-explained/commission-explained.component';
import { FeeListItemComponent } from './components/fee-list-item/fee-list-item.component';
import { PaymentwalllListItemComponent } from './components/paymentwalll-list-item/paymentwalll-list-item.component';
import { CommissionExplainedPublicComponent } from './components/commission-explained-public/commission-explained-public.component';

@NgModule({
  declarations: [
    ToastsContainerComponent,
    TableComponent,
    TablePaginationComponent,
    TableHeaderTemplateDirective,
    TableRowTemplateDirective,
    CopyDirective,
    TableSortComponent,
    TableSortDirective,
    ConfirmModalComponent,
    InputModalComponent,
    ValuesBasedFilterInputComponent,
    CommonApiErrorComponent,
    CommonEmptyCollectionComponent,
    CommonLoadingComponent,
    StatePaginatedTableComponent,
    TopBarComponent,
    SideBarComponent,
    LayoutComponent,
    CommissionExplainedComponent,
    CommissionExplainedPublicComponent,
    FeeListItemComponent,
    PaymentwalllListItemComponent,
  ],
  imports: [
    CommonModule,
    NgbToastModule,
    NgIf,
    NgTemplateOutlet,
    NgFor,
    FormsModule,
    SharedServicesModule,
    RouterModule.forChild([]),
    UpperCasePipe,
  ],
  exports: [
    ToastsContainerComponent,
    TableComponent,
    TablePaginationComponent,
    TableHeaderTemplateDirective,
    TableRowTemplateDirective,
    CopyDirective,
    TableSortComponent,
    TableSortDirective,
    ConfirmModalComponent,
    InputModalComponent,
    ValuesBasedFilterInputComponent,
    CommonApiErrorComponent,
    CommonEmptyCollectionComponent,
    CommonLoadingComponent,
    StatePaginatedTableComponent,
    TopBarComponent,
    SideBarComponent,
    LayoutComponent,
    CommissionExplainedComponent,
    CommissionExplainedPublicComponent,
    FeeListItemComponent,
    PaymentwalllListItemComponent,
  ],
})
export class SharedUiModule {}
