import { Component, OnDestroy, OnInit } from '@angular/core';
import { Step1Form } from '../../../../shared/components/shop-onboarding-step1/shop-onboarding-step1.component';
import { Step2Form } from '../../../../shared/components/shop-onboarding-step2/shop-onboarding-step2.component';
import { Step3Form } from '../../../../shared/components/shop-onboarding-step3/shop-onboarding-step3.component';
import { Address, ShopResource, ShopService } from 'api-client';
import { SelectedShopService, retryPipe } from '@eself/shared-services';
import { Observable, Subscription } from 'rxjs';
import { Buffer } from 'buffer';
import { ToastService } from '@eself/shared-ui';

@Component({
  templateUrl: './shop-onboarding-page.component.html',
  styleUrls: ['./shop-onboarding-page.component.scss'],
})
export class ShopOnboardingPageComponent implements OnInit, OnDestroy {
  firstStep: Step1Form | null = null;
  secondStep: Step2Form | null = null;
  thirdStep: Step3Form | null = null;
  isLoading = false;

  shop$: Observable<ShopResource> = this.shopService
    .getShop(this.selectedShopService.getShop()?.id ?? 0)
    .pipe(retryPipe(3));

  shop: ShopResource | null = null;
  shopSubscription: Subscription = new Subscription();

  constructor(
    private shopService: ShopService,
    private toast: ToastService,
    private selectedShopService: SelectedShopService
  ) {}

  ngOnInit(): void {
    this.shopSubscription = this.shop$.subscribe((shop) => {
      this.shop = shop;
      this.firstStep = {
        companyName: shop.name ?? '',
        companyType: shop.type ?? '',
        url: shop.url ?? '',
        emailRodo: shop.rodo_email ?? '',
        phone: shop.phone_number ?? '',
        email: shop.email ?? '',
        password: '',
        confirmPassword: '',
        emailInvoices: shop.invoice_email ?? '',
        nip: shop.nip ?? '',
        regon: shop.regon ?? '',
        krs: shop.krs ?? '',
        pesel: shop.pesel ?? '',
      };
      this.secondStep = {
        buisnessMail: shop.business_mail ?? '',
        buisnessName: shop.business_person ?? '',
        buisnessPhone: shop.business_phone ?? '',
        customerSupportMail: shop.client_mail ?? '',
        customerSupportName: shop.client_person ?? '',
        customerSupportPhone: shop.client_phone ?? '',
        shopLogoBase64: shop.logo_base64 ?? '',
        shopIconBase64: shop.catalog_base64 ?? '',
        shopIcon: this.dataUrlToFile(shop.catalog_base64, 'catalog'),
        shopLogo: this.dataUrlToFile(shop.logo_base64, 'logo'),
        linkPrivacyPolicy: shop.privacy_policy_url ?? '',
        linkRegulations: shop.regulations_url ?? '',
      };
      this.thirdStep = {
        feeId: shop.fee?.id ?? 0,
        gateId: shop.paymentwall?.id ?? 0,
        merchantId: shop.merchant_id ?? '',
        note: shop.note ?? '',
      };
    });
  }
  /***
   * Converts a dataUrl base64 image string into a File byte array
   * dataUrl example:
   * data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIsAAACLCAYAAABRGWr/AAAAAXNSR0IA...etc
   */
  dataUrlToFile(dataUrl: string | undefined, filename: string): File | null {
    if (dataUrl != undefined && (dataUrl?.length ?? 0) > 0) {
      const arr = dataUrl.split(',');
      if (arr.length < 2) {
        return null;
      }
      const mimeArr = arr[0].match(/:(.*?);/);
      if (!mimeArr || mimeArr.length < 2) {
        return null;
      }
      const mime = mimeArr[1];
      const buff = Buffer.from(arr[1], 'base64');
      return new File([buff], filename, { type: mime });
    }
    return null;
  }

  /**
   * Creates a new shop
   * @param feeId Fee ID
   * @param paymentwallId Paymentwall ID
   * @param merchantId Merchant ID
   * @param type
   * @param name Name of the shop
   * @param url URL of the shop
   * @param rodoEmail Rodo email of the shop
   * @param phoneNumber Phone number of the shop
   * @param email Email of the shop
   * @param password Password of the shop
   * @param passwordConfirmation
   * @param invoiceEmail Invoice email of the shop
   * @param paymentwallShopTypeId Paymentwall Shop Type ID
   * @param logo Logo of the shop
   * @param catalog Catalog of the shop
   * @param nip NIP of the shop
   * @param regon Regon of the shop
   * @param krs KRS of the shop
   * @param pesel Pesel of the shop
   * @param bankAccount Bank account of the shop
   * @param customerAssistantId Customer assistant ID of the shop
   * @param address
   * @param mailingAddress
   * @param businessPerson Business person of the shop
   * @param businessPhone Business phone of the shop
   * @param businessMail Business mail of the shop
   * @param clientPerson Client person of the shop
   * @param clientPhone Client phone of the shop
   * @param clientMail Client mail of the shop
   * @param representativePerson Representative person of the shop
   * @param representativePesel Representative\\\&#39;s PESEL of the shop
   * @param paymentwallContactPerson PaymentWall\\\&#39;s contact person of the shop
   * @param paymentwallContactPhone PaymentWall\\\&#39;s contact phone of the shop
   * @param paymentwallContactMail PaymentWall\\\&#39;s contact email of the shop
   * @param paymentwallTechnicalContactPerson PaymentWall\\\&#39;s technical contact person of the shop
   * @param paymentwallTechnicalContactPhone PaymentWall\\\&#39;s technical contact phone of the shop
   * @param paymentwallTechnicalContactMail PaymentWall\\\&#39;s technical contact mail of the shop
   * @param note
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  /*
  this.thirdStep.feeId,
          this.thirdStep.gateId,
          this.thirdStep.merchantId,
          shopType,
          this.firstStep.companyName,
          this.firstStep.url,
          this.firstStep.emailRodo,
          this.firstStep.phone,
          this.firstStep.email,
          this.firstStep.password,
          this.firstStep.confirmPassword,
          this.firstStep.emailInvoices,
          undefined,
          this.secondStep?.shopLogo,
          this.secondStep?.shopIcon,
          this.firstStep.nip ?? '',
          this.firstStep.regon ?? '',
          this.firstStep.krs ?? '',
          this.firstStep.pesel ?? '',
          undefined,
          undefined,
          undefined,
          undefined,
          this.secondStep?.buisnessName,
          this.secondStep?.buisnessPhone,
          this.secondStep?.buisnessMail,
          this.secondStep?.customerSupportName,
          this.secondStep?.customerSupportPhone,
          this.secondStep?.customerSupportMail
  */

  ngOnDestroy(): void {
    this.shopSubscription.unsubscribe();
  }

  onFirstStepReady(form: Step1Form) {
    this.firstStep = form;
    if (
      !(
        this.shop?.id &&
        this.shop.fee?.id &&
        this.shop.paymentwall?.id &&
        this.shop.merchant_id
      )
    ) {
      this.toast.show(
        'Brakuje danych sklepu! Jedno z pól: id, fee, paymentwall, merchant_id jest puste'
      );
      return;
    }
    const logo = this.base64toBlob(this.shop.logo_base64);
    const catalog = this.base64toBlob(this.shop.catalog_base64);
    this.shopService
      .updateShop(
        this.shop.id,
        this.shop.fee?.id,
        this.shop.paymentwall?.id,
        this.shop.merchant_id,
        this.shop.type,
        this.firstStep.companyName,
        this.firstStep.url,
        this.firstStep.emailRodo,
        this.firstStep.phone,
        this.firstStep.email,
        this.firstStep.emailInvoices,
        this.shop.paymentwall_shop_type?.id,
        logo,
        catalog,
        this.shop.nip ?? 'b.d',
        this.shop.regon ?? 'b.d',
        this.shop.krs ?? 'b.d',
        this.shop.pesel ?? 'b.d',
        this.shop.bank_account,
        this.shop.customer_assistant?.id,
        <Address>this.shop.address,
        <Address>this.shop.mailing_address,
        this.shop.business_person,
        this.shop.business_phone,
        this.shop.business_mail,
        this.shop.client_person,
        this.shop.client_phone,
        this.shop.client_mail,
        this.shop.representative_person,
        this.shop.representative_pesel,
        this.shop.paymentwall_contact_person,
        this.shop.paymentwall_contact_phone,
        this.shop.paymentwall_contact_email ?? 'missing@mail.com',
        this.shop.paymentwall_technical_contact_person,
        this.shop.paymentwall_technical_contact_phone,
        this.shop.paymentwall_technical_contact_mail ?? 'missing@mail.com',
        this.shop.privacy_policy_url,
        this.shop.regulations_url,
        this.shop.note
      )
      .subscribe(
        (response) => {
          this.toast.show('Zapisano zmiany!');
        },
        (error) => {
          this.toast.show('Wystąpił problem: ' + error.error?.message);
          console.error(error);
        }
      );
  }
  base64toBlob(image: string | undefined): Blob | undefined {
    if (!image) {
      return undefined;
    }
    const parts = image.split(';base64,');

    // Hold the content type
    let contentType = parts[0].split(':')[1];

    // Decode Base64 string
    const byteCharacters = window.atob(parts[1]);
    contentType = contentType || '';
    const sliceSize = 1024;
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  onSecondStepReady(form: Step2Form) {
    this.secondStep = form;

    if (
      !(
        this.shop?.id &&
        this.shop.fee?.id &&
        this.shop.paymentwall?.id &&
        this.shop.merchant_id
      )
    ) {
      this.toast.show(
        'Brakuje danych sklepu! Jedno z pól: id, fee, paymentwall, merchant_id jest puste'
      );
      return;
    }
    const logo =
      this.secondStep.shopLogo ?? this.base64toBlob(this.shop.logo_base64);
    const catalog =
      this.secondStep.shopIcon ?? this.base64toBlob(this.shop.catalog_base64);
    this.shopService
      .updateShop(
        this.shop.id,
        this.shop.fee?.id,
        this.shop.paymentwall?.id,
        this.shop.merchant_id,
        this.shop.type,
        this.shop.name ?? 'b.d',
        this.shop.url ?? '',
        this.shop.rodo_email ?? '',
        this.shop.phone_number ?? '',
        this.shop.email ?? ' ',
        this.shop.invoice_email ?? '',
        this.shop.paymentwall_shop_type?.id,
        logo,
        catalog,
        this.shop.nip ?? 'b.d',
        this.shop.regon ?? 'b.d',
        this.shop.krs ?? 'b.d',
        this.shop.pesel ?? 'b.d',
        this.shop.bank_account,
        this.shop.customer_assistant?.id,
        <Address>this.shop.address,
        <Address>this.shop.mailing_address,
        this.secondStep.buisnessName,
        this.secondStep.buisnessPhone,
        this.secondStep.buisnessMail,
        this.secondStep.customerSupportName,
        this.secondStep.customerSupportPhone,
        this.secondStep.customerSupportMail,
        this.shop.representative_person,
        this.shop.representative_pesel,
        this.shop.paymentwall_contact_person,
        this.shop.paymentwall_contact_phone,
        this.shop.paymentwall_contact_email ?? 'missing@mail.com',
        this.shop.paymentwall_technical_contact_person,
        this.shop.paymentwall_technical_contact_phone,
        this.shop.paymentwall_technical_contact_mail ?? 'missing@mail.com',
        this.secondStep.linkPrivacyPolicy,
        this.secondStep.linkRegulations,
        this.shop.note
      )
      .subscribe(
        (response) => {
          this.toast.show('Zapisano zmiany!');
        },
        (error) => {
          this.toast.show('Wystąpił problem: ' + error.error?.message);
          console.error(error);
        }
      );
  }
  onThirdStepReady(form: Step3Form) {
    this.thirdStep = form;
    alert('Ta opcja nie jest jeszcze dostępna');
  }
}
