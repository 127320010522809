import { TransactionProductResource } from 'api-client';

export interface OrderModel {
  id: number;
  uid: string;
  transactionStatus: number;
  transactionType: number;
  transactionDate: Date;
  transactionBank: string;
  shopId: number;
  shopName: string;
  userId: string;
  currency: string;
  amount: number;
  paymentGatewayId: number;
  paymentType: string;
  gatewayCommission: number;
  eselfCommission: number;
  eselfMicrotransaction: number;
  totalCharges: number;
  amountPayable: number;
  withdrawalStatus: number;
  withdrawalDate: Date | null;
  withdrawalAmount: number;
  refundAmount: number;
  refundDate: Date | null;
  updatedTransactionAmount: number;
  billingDate: Date | null;
  items?: TransactionProductResource[];
}
