import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextFilter } from '../basic-filter';

@Component({
  selector: 'eself-values-based-filter-input',
  templateUrl: './values-based-filter-input.component.html',
  styleUrls: ['./values-based-filter-input.component.scss'],
})
export class ValuesBasedFilterInputComponent {
  @Input() name = '';
  @Output() filterChanged = new EventEmitter<string[]>();
  selectedValues: string[] = [];
  @Input() textFilter: TextFilter = new TextFilter();

  public onSelectionChanged(): void {
    if (this.selectedValues.length == 1 && this.selectedValues[0] == null) {
      this.selectedValues = [];
    }
    this.textFilter.setSelectedValues(this.selectedValues);
    this.filterChanged.emit(this.selectedValues);
  }

  public onRestart(): void {
    this.selectedValues = [];
    this.onSelectionChanged();
  }
}
