<div class="form-group">
  <label for="{{ name }}-filter-select">{{ name }}</label>
  <button
    *ngIf="selectedValues.length > 0"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onRestart()"
  ></button>
  <select
    id="{{ name }}-filter-select"
    class="form-control"
    [(ngModel)]="selectedValues"
    (ngModelChange)="onSelectionChanged()"
    multiple
  >
    <option [value]="null">-- Wybierz wartość --</option>
    <option *ngFor="let value of textFilter.values" [value]="value">
      {{ value }}
    </option>
  </select>
</div>
