import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BaseTable } from '../common-table/base-table.component';
import { TableHeaderTemplateDirective } from '../common-table/directives/table-header-template.directive';
import { TableRowTemplateDirective } from '../common-table/directives/table-row-template.directive';
import { Observable } from 'rxjs';
import { PaginatedTableData, PaginationData } from '@eself/shared-services';

@Component({
  selector: 'eself-state-table',
  templateUrl: './state-table.component.html',
  styleUrls: ['./state-table.component.scss'],
})
export class StatePaginatedTableComponent<
  ItemType extends object
> extends BaseTable {
  @Input() displayPaginationDescription = true;

  @Input() data$ = new Observable<PaginatedTableData<ItemType>>();

  @ContentChild(TableHeaderTemplateDirective, { read: TemplateRef })
  headers?: TemplateRef<any>;

  @ContentChild('actions', { read: TemplateRef })
  actions?: TemplateRef<any>;

  @ContentChild('row', { read: TemplateRef })
  rows?: TemplateRef<any>;

  @ContentChild('pagination', { read: TemplateRef })
  pagination?: TemplateRef<any>;

  public getStartingItems(
    pagination: PaginationData | undefined
  ): number | null {
    if (!pagination) {
      return null;
    }
    if (pagination.total == 0) {
      return 0;
    }
    return (
      ((pagination.current_page ?? 0) - 1) * (pagination.per_page ?? 0) + 1
    );
  }

  public getEndingItems(
    pagination: PaginatedTableData<ItemType> | undefined
  ): number | null {
    if (!pagination) {
      return null;
    }
    return (
      ((pagination.pagination?.current_page ?? 0) - 1) *
        (pagination.pagination?.per_page ?? 0) +
      (pagination.data?.length ?? 0)
    );
  }
}
