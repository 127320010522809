export enum SortOrderType {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export interface OrderChangedEvent {
  property: string;
  order: SortOrderType;
}
