import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss'],
})
export class InputModalComponent {
  @Input() title = 'Wymagane potwierdzenie';
  @Input() message = 'Czy jesteś pewien?';
  @Input() type = 'text';
  @Input() confirmText = 'Potwierdź';
  @Input() rejectText = 'Anuluj';

  public inputValue = '';

  constructor(public activeModal: NgbActiveModal) {}

  onConfirm(): void {
    this.activeModal.close(this.inputValue);
  }

  onReject(): void {
    this.activeModal.dismiss();
  }
}
