import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShopComponent } from './shop.component';
import { ShopTransactionsPageComponent } from './pages/shop-transactions-page/shop-transactions-page.component';
import { ShopCommissionPageComponent } from './pages/shop-commission-page/shop-commission-page.component';
import { ShopBillingStatusPageComponent } from './pages/shop-billing-status-page/shop-billing-status-page.component';
import { ShopBalancePageComponent } from './pages/shop-balance-page/shop-balance-page.component';
import { ShopGatewayBillingPageComponent } from './pages/shop-gateway-billing-page/shop-gateway-billing-page.component';
import { ShopEselfBillingPageComponent } from './pages/shop-eself-billing-page/shop-eself-billing-page.component';
import { ShopInvoicesPageComponent } from './pages/shop-invoices-page/shop-invoices-page.component';
import { ShopAccountsStatusPageComponent } from './pages/shop-accounts-status-page/shop-accounts-status-page.component';
import { ShopDashboardPageComponent } from './pages/shop-dashboard-page/shop-dashboard-page.component';
import { AuthGuard, ModalGuard } from '@eself/shared-services';
import { ShopOnboardingPageComponent } from './pages/shop-onboarding-page/shop-onboarding-page.component';
import { ShopRodoPageComponent } from './pages/shop-rodo-page/shop-rodo-page.component';

const routes: Routes = [
  {
    path: ':id?',
    component: ShopComponent,
    canActivate: [AuthGuard, ModalGuard],
    canActivateChild: [ModalGuard],
    children: [
      {
        path: 'transactions',
        component: ShopTransactionsPageComponent,
      },
      {
        path: 'commission',
        component: ShopCommissionPageComponent,
      },
      // {
      //   path: 'billing-status',
      //   component: ShopBillingStatusPageComponent,
      // },
      {
        path: 'balance',
        component: ShopBalancePageComponent,
      },
      // {
      //   path: 'gateway-billing',
      //   component: ShopGatewayBillingPageComponent,
      // },
      {
        path: 'onboarding',
        component: ShopOnboardingPageComponent,
      },
      {
        path: 'eself-billing',
        component: ShopEselfBillingPageComponent,
      },
      {
        path: 'invoices',
        component: ShopInvoicesPageComponent,
      },
      {
        path: 'accounts-status',
        component: ShopAccountsStatusPageComponent,
      },
      {
        path: 'dashboard',
        component: ShopDashboardPageComponent,
      },
      {
        path: 'rodo',
        component: ShopRodoPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopRoutingModule {}
