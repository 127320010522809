import { Component } from '@angular/core';
import { RodoTableComponent } from '../../../../shared/components/rodo-table/rodo-table.component';
import { of } from 'rxjs';
import { PaginatedTableData } from '@eself/shared-services';

@Component({
  templateUrl:
    '../../../../shared/components/rodo-table/rodo-table.component.html',
  styleUrls: [
    './shop-rodo-page.component.scss',
    '../../../../shared/components/rodo-table/rodo-table.component.scss',
  ],
})
export class ShopRodoPageComponent extends RodoTableComponent {
  constructor() {
    super();
    this.pageData$ = of({
      data: [],
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
      },
    });
  }
}
