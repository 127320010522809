import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ShopModel,
  SelectedShopService,
  ShopsService,
} from '@eself/shared-services';
import { SideBarElementModel } from '@eself/shared-ui';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent {
  public shop: ShopModel | null = null;

  public shopName = '';

  public sideBarElements: SideBarElementModel[] = [];

  constructor(
    private selectedShopService: SelectedShopService,
    private router: Router,
    shopsService: ShopsService,
    activatedRoute: ActivatedRoute
  ) {
    try {
      const shop = this.selectedShopService.getShop();
      if (shop == null) {
        this.router.navigate(['/global/shop-selection']);
        return;
      }
      const shopId = activatedRoute.snapshot.params['id?'];
      if (shop.id != shopId) {
        shopsService.getShop(shopId).then(
          (shop) => {
            if (shop) {
              this.shopLoaded(shop);
            }
          },
          (_) => this.router.navigate(['/global/shop-selection'])
        );
      } else {
        this.shopLoaded(shop);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private shopLoaded(shop: ShopModel): void {
    this.shop = shop;
    this.shopName = shop.urlReadable;
    this.sideBarElements = [
      {
        icon: 'arrow_back',
        link: '/global/shop-selection',
        name: 'Wybór sklepu',
      },
      {
        icon: 'store',
        link: `/shop/${shop.id}/dashboard`,
        name: this.shopName,
      },
      // {
      //   icon: 'store',
      //   link: `/shop/${shop.id}/onboarding`,
      //   name: 'Onboarding',
      // },
      {
        icon: 'timeline',
        link: `/shop/${shop.id}/transactions`,
        name: 'Lista transakcji',
      },
      {
        icon: 'paid',
        link: `/shop/${shop.id}/commission`,
        name: 'Prowizja sklepu',
      },
      // {
      //   icon: 'account_balance',
      //   link: `/shop/${shop.id}/billing-status`,
      //   name: 'Status rozliczeń',
      // },
      {
        icon: 'settings',
        link: `/shop/${shop.id}/balance`,
        name: 'Saldo sklepu',
      },
      // {
      //   icon: 'monetization_on',
      //   link: `/shop/${shop.id}/gateway-billing`,
      //   name: 'Rozliczenia z bramką',
      // },
      {
        icon: 'payments',
        link: `/shop/${shop.id}/eself-billing`,
        name: 'Rozliczenia eself',
      },
      {
        icon: 'receipt_long',
        link: `/shop/${shop.id}/invoices`,
        name: 'Faktury',
      },
      {
        icon: 'timeline',
        link: `/shop/${shop.id}/rodo`,
        name: 'RODO - Prawo do zapomnienia',
      },
    ];
  }
}
