<h3 class="m-4">Faktury</h3>
<div class="card m-4 p-4">
  <eself-state-table [data$]="pageData$">
    <ng-template eselfTableHeader>
      <th scope="col" class="text-center">numer faktury</th>
      <th scope="col" class="text-center">status</th>
      <th scope="col" class="text-center">ilość transakcji</th>
      <th scope="col" class="text-center">pierwsza transakcja</th>
      <th scope="col" class="text-center">ostatnia transakcja</th>
      <th scope="col" class="text-center">ilość rozliczeń</th>
      <th scope="col" class="text-center">opłata brutto</th>
      <th scope="col" class="text-center">opłata netto</th>
      <th scope="col" class="text-center">opłata vat</th>
      <th scope="col" class="text-center">akcja</th>
    </ng-template>
    <ng-template #pagination let-row>
      <eself-table-pagination
        [pagination]="row"
        (moveToPage)="onPageChange($event)"
      >
      </eself-table-pagination>
      <eself-common-loading *ngIf="isLoading"></eself-common-loading>
    </ng-template>
    <ng-template #row let-row>
      <td scope="row" class="text-center" data-title="numer faktury">
        {{ row.invoice_number }}
      </td>
      <td
        scope="row"
        class="text-center"
        data-title="status"
        placement="bottom"
        [ngbTooltip]="row.status | invoiceStatusText"
        [openDelay]="50"
        [closeDelay]="300"
        container="body"
      >
        <i
          class="material-icons"
          [style.color]="row.status | invoiceStatusColor"
        >
          {{ row.status | invoiceStatusIcon }}
        </i>
      </td>
      <td scope="row" class="text-center" data-title="ilość transakcji">
        {{ row.transaction_count }}
      </td>
      <td scope="row" data-title="pierwsza transakcja">
        {{ row.first_transaction_date | safeDate : 'long' }}
      </td>
      <td scope="row" data-title="ostatnia transakcja">
        {{ row.last_transaction_date | safeDate : 'long' }}
      </td>
      <td scope="row" class="text-center" data-title="ilość transakcji">
        {{ row.settlements?.length }}
      </td>
      <td scope="row" class="text-center" data-title="opłata brutto">
        {{ row.only_eself_fee_gross | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-center" data-title="opłata netto">
        {{ row.only_eself_fee_net | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-center" data-title="opłata vat">
        {{ row.only_eself_fee_vat | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" data-title="id">
        <button
          *ngIf="canEdit(row)"
          class="btn btn-outline-secondary me-2"
          (click)="editInvoice(row)"
        >
          Edytuj
        </button>
        <button class="btn btn-outline-primary" (click)="openInvoice(row)">
          Otwórz
        </button>
      </td>
    </ng-template>
  </eself-state-table>
</div>
