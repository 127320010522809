import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CreateIndividualPaymentwallRequest,
  PaymentMethodsInner,
  PaymentWallResource,
  PaymentwallType,
  UpdatePaymentwallRequest,
} from 'api-client';

@Component({
  templateUrl: './create-payment-wall-modal.component.html',
  styleUrls: ['./create-payment-wall-modal.component.scss'],
})
export class CreatePaymentWallModalComponent implements OnInit {
  @Input() set original(wall: PaymentWallResource) {
    this.name = wall.name ?? '';
    this.elements =
      wall.payment_methods?.map(
        (x) =>
          ({
            id: x.id ?? 0,
            name: x.name ?? '',
            external_id: x.external_id?.toString() ?? '',
            fee_percent: x.fee_percent ?? 0,
            charge: x.charge ?? 0,
          } as PaymentMethodsInner)
      ) ?? [];
  }

  name = '';

  formGroup = new FormGroup({
    paymentForm: new FormGroup({
      name: new FormControl(''),
      external_id: new FormControl(''),
      fee: new FormControl(''),
      charge: new FormControl(''),
    }),
  });

  inEditId: number | null = null;

  get paymentForm(): FormGroup {
    return this.formGroup.get('paymentForm') as FormGroup;
  }

  elements: PaymentMethodsInner[] = [];

  constructor(public activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.formGroup.setValue({
      paymentForm: {
        name: '',
        external_id: '',
        fee: '',
        charge: '',
      },
    });
  }

  public onCreate(): void {
    const gateConfiguration: CreateIndividualPaymentwallRequest = {
      name: this.name,
      payment_methods: this.elements,
      type: PaymentwallType.NUMBER_1, // TODO: make it configurable
    };
    this.activeModal.close(gateConfiguration);
  }

  public onAddElement(): void {
    if (this.inEditId) {
      const element = this.elements.find((x) => x.id === this.inEditId);
      if (element) {
        element.name = this.paymentForm.get('name')?.value ?? '';
        element.external_id = this.paymentForm.get('external_id')?.value ?? '';
        element.fee_percent = Number.parseFloat(
          this.paymentForm.get('fee')?.value ?? '0'
        );
        element.charge = Number.parseFloat(
          this.paymentForm.get('charge')?.value ?? '0'
        );
      }
    } else {
      const newElement: PaymentMethodsInner = {
        id: 0,
        name: this.paymentForm.get('name')?.value ?? '',
        external_id: this.paymentForm.get('external_id')?.value ?? '',
        fee_percent: Number.parseFloat(
          this.paymentForm.get('fee')?.value ?? '0'
        ),
        charge: Number.parseFloat(this.paymentForm.get('charge')?.value ?? '0'),
      };
      this.elements.push(newElement);
    }
    this.onClearEdit();
  }

  public onEditElement(id: number | undefined): void {
    const element = this.elements.find((x) => x.id == id);
    if (!element) {
      return;
    }
    this.paymentForm.setValue({
      name: element.name,
      external_id: element.external_id,
      fee: element.fee_percent ?? 0,
      charge: element.charge,
    });
    this.inEditId = element.id ?? null;
  }

  public onClearEdit(): void {
    this.paymentForm.setValue({
      name: '',
      external_id: '',
      fee: '',
      charge: '',
    });
    this.inEditId = null;
  }

  public onRemoveElement(id: number | undefined): void {
    const element = this.elements.find((x) => x.id == id);
    if (!element) {
      return;
    }
    this.elements.splice(this.elements.indexOf(element), 1);
  }

  public onClose() {
    this.activeModal.dismiss();
  }
}
