<h3 class="m-4">Rozliczenia z bramką</h3>

<ul
  ngbNav
  #nav="ngbNav"
  [(activeId)]="active"
  (activeIdChange)="activeIdChange($event)"
  class="nav-tabs ps-4 m-0"
>
  <li [ngbNavItem]="1">
    <button ngbNavLink>Dane</button>
    <ng-template ngbNavContent>
      <div class="d-flex flex-column w-100 p-4">
        <div class="d-flex mb-2">
          <div class="btn-group d-flex" role="group" aria-label="Basic example">
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="filterOnWithdrawReady()"
            >
              Do wypłaty
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="filterOnWithdraw()"
            >
              Wypłacone
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="showAll()"
            >
              Wszystkie
            </button>
          </div>
          <!-- <button
            *ngIf="canWithdraw(result.value ?? [])"
            class="btn btn-primary me-2"
            (click)="withdraw()"
          >
            Wypłać
          </button> -->
        </div>
        <eself-common-loading *ngIf="isLoading"></eself-common-loading>
        <eself-common-empty-collection *ngIf="!isLoading && totalCount === 0">
        </eself-common-empty-collection>
        <eself-table
          class="d-flex w-100"
          *ngIf="(pageData$ | async) ?? [] as pageData"
          [renderPaginated]="pageData"
          [pageIndex]="pageIndex"
          [totalCount]="totalCount"
          [pageSize]="10"
          (pageChange)="onPageChange($event)"
          [isLoading]="isLoading"
        >
          <ng-template eselfTableHeader>
            <th scope="col" class="text-center">wypłać</th>
            <th scope="col" class="text-start">status wypłaty</th>
            <th scope="col" class="text-end">kwota całkowita</th>
            <th scope="col" class="text-end">łączne opłaty</th>
            <th scope="col" class="text-end">kwota wypłaty*</th>
            <th scope="col" class="text-center">data wypłaty*</th>
            <th scope="col" class="text-center">data rozliczenia*</th>
          </ng-template>
          <ng-template [eselfTableRow]="pageData ?? []" let-row>
            <td scope="row" data-title="wypłać">
              <input
                *ngIf="!row.disabled; else ready"
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                [(ngModel)]="row.selected"
              />
              <ng-template #ready>
                <i class="material-icons text-success big-icon">check</i>
              </ng-template>
            </td>
            <td
              scope="row"
              class="text-start"
              data-title="status wypłaty"
              placement="bottom"
              [ngbTooltip]="row.data.withdrawal_status | withdrawalStatusText"
              [openDelay]="50"
              [closeDelay]="300"
              container="body"
            >
              <i
                class="material-icons"
                [style.color]="
                  row.data.withdrawal_status === 1 ? '#006be0' : '#912da4'
                "
              >
                {{
                  row.data.withdrawal_status === 1
                    ? 'account_balance_wallet'
                    : 'attach_money'
                }}
              </i>

              -
              {{ row.data.withdrawal_status | withdrawalStatusText }}
            </td>
            <td scope="row" class="text-end" data-title="kwota do zapłaty">
              {{
                row.data.available_balance | currencyNumber : row.data.currency
              }}
            </td>
            <td
              scope="row"
              class="text-end"
              class="text-end"
              data-title="łączne opłaty underline"
              placement="bottom"
              container="body"
            >
              <u>
                {{
                  (row.data.eself_fee_gross ?? 0) +
                    (row.data.eself_fee_gross ?? 0)
                    | currencyNumber : row.data.currency
                }}
              </u>
            </td>
            <td scope="row" class="text-end" data-title="kwota do zapłaty">
              {{ row.data.amount | currencyNumber : row.data.currency }}
            </td>
            <td scope="row" data-title="data wypłaty*">
              {{ row.data.withdrawal_date | safeDate }}
            </td>
            <td scope="row" data-title="data rozliczenia*">
              {{ row.data.settlement_date | safeDate }}
            </td>
          </ng-template>
        </eself-table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <button ngbNavLink>Analiza</button>
    <ng-template ngbNavContent>
      <div class="d-flex m-4">
        <div echarts [options]="options" class="demo-chart mt-4">
          Ładowanie..
        </div>
        <div echarts [options]="options2" class="demo-chart mt-4"></div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="bg-white" class="pt-2"></div>
