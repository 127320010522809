/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Address of the shop
 */
export interface Address { 
    country: string;
    country_code: string;
    city: string;
    post_code: string;
    street?: string;
    building_number: string;
    apartment_number?: string;
}

