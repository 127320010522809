import { CachedResponse } from '../interfaces/cached-response.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpCacheService {
  private cache: Map<string, CachedResponse> = new Map<
    string,
    CachedResponse
  >();

  get(url: string): any {
    const cachedResponse = this.cache.get(url);
    if (cachedResponse && !this.isExpired(cachedResponse)) {
      return cachedResponse.response;
    }
    this.invalidate(url);
    return null;
  }

  set(url: string, response: any, duration = 300000): void {
    const expirationTime = Date.now() + duration;
    const cachedResponse: CachedResponse = {
      response,
      expirationTime,
    };
    this.cache.set(url, cachedResponse);
  }

  has(url: string): boolean {
    const cachedResponse = this.cache.get(url);
    return !!cachedResponse && !this.isExpired(cachedResponse);
  }

  invalidate(url: string): void {
    this.cache.delete(url);
  }

  clear(): void {
    this.cache.clear();
  }

  private isExpired(cachedResponse: CachedResponse): boolean {
    return cachedResponse.expirationTime < Date.now();
  }
}
