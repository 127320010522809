<form
  [formGroup]="commissionForm"
  (ngSubmit)="onCreateCommission()"
  class="p-4"
>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between">
      <h3
        *ngIf="!commissionToEdit || commissionToEdit.id === 0; else editHeader"
      >
        Kreator prowizji
      </h3>
      <ng-template #editHeader>
        <h3>Edytor prowizji #{{ commissionToEdit?.id }}</h3>
      </ng-template>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="onClose()"
      ></button>
    </div>
    <br />
    <div class="form-group">
      <label class="form-label" for="name">Nazwa: </label>
      <input
        id="name"
        type="text"
        class="form-control"
        formControlName="name"
        required
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label" for="discount">Zniżka: </label>
      <input
        id="discount"
        type="text"
        class="form-control"
        formControlName="discount"
        required
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label" for="price">Cena miesięczna: </label>
      <input
        id="price"
        type="number"
        class="form-control"
        formControlName="price"
        required
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label" for="commission">% za transakcję: </label>
      <input
        id="commission"
        type="number"
        class="form-control"
        formControlName="commission"
        required
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label" for="microfee">Mikro opłata: </label>
      <input
        id="microfee"
        type="number"
        class="form-control"
        formControlName="microfee"
        required
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label" for="transaction_limit"
        >Limit transakcji
      </label>
      <input
        [(ngModel)]="transactionLimitEnabled"
        [ngModelOptions]="{ standalone: true }"
        class="form-check-input ms-2 me-1"
        type="checkbox"
      />:
      <input
        *ngIf="transactionLimitEnabled"
        id="transaction_limit"
        type="number"
        class="form-control"
        formControlName="transaction_limit"
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label">
        Rodzaj typu oferty:
        <span *ngIf="commissionTypeLock !== null" class="fw-bold">{{
          offerNumberToName(commissionTypeLock)
        }}</span>
      </label>
      <!--
/**
 * 1 - PublicOffer, 2 - PromotionalOffer, 3 - HiddenPromotionalOffer, 4 - IndividualOffer
 */ -->
      <input
        *ngIf="commissionTypeLock !== null"
        type="hidden"
        [value]="commissionTypeLock"
        [readonly]="commissionTypeLock !== null"
      />
      <select
        *ngIf="commissionTypeLock === null"
        class="form-control form-select"
        formControlName="type"
        title="Wybór prowizji"
        required
      >
        <option [value]="1">{{ offerNumberToName(1) }}</option>
        <option [value]="2">{{ offerNumberToName(2) }}</option>
        <option [value]="3">{{ offerNumberToName(3) }}</option>
        <option [value]="4">{{ offerNumberToName(4) }}</option>
      </select>
    </div>
    <div class="form-group mt-2">
      <label class="form-label"> Notatka dla oferty: </label>
      <textarea class="form-control" formControlName="note"></textarea>
    </div>
    <div class="form-group mt-2">
      <label class="form-label"> Oferta ważna od: </label>
      <input
        class="form-control"
        type="date"
        formControlName="start"
        required
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label"> Oferta ważna do </label>
      <input
        [(ngModel)]="dateEndEnabled"
        [ngModelOptions]="{ standalone: true }"
        class="form-check-input ms-2 me-1"
        type="checkbox"
      />:
      <input
        *ngIf="dateEndEnabled"
        class="form-control"
        type="date"
        formControlName="end"
      />
    </div>
    <div class="form-group mt-2">
      <label class="form-label" for="bulletPoint">Dodaj parametr: </label>
      <div class="input-group mt-0">
        <input
          id="bulletPoint"
          type="text"
          class="form-control"
          [(ngModel)]="bulletText"
          [ngModelOptions]="{ standalone: true }"
        />
        <button
          class="btn btn-outline-secondary"
          type="button"
          id="button-bulletPoint"
          (click)="onAddBulletPoint()"
          [disabled]="!bulletText"
        >
          +
        </button>
      </div>
    </div>
    <h4 class="mt-2">Parametry:</h4>
    <div class="d-flex flex-column">
      <div
        class="alert alert-light p-1 m-2 d-flex justify-content-between"
        *ngFor="let bullet of bulletPoints; let i = index"
        role="alert"
      >
        {{ i + 1 }}. {{ bullet }}
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="onDeleteBullet(i)"
        ></button>
      </div>
    </div>
    <button
      type="submit"
      class="btn btn-primary mt-2"
      [disabled]="!commissionForm.valid"
    >
      <span>{{
        !commissionToEdit || commissionToEdit.id === 0
          ? 'Utwórz nową prowizję'
          : 'Zapisz zmiany'
      }}</span>
    </button>
  </div>
</form>
