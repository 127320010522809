import { Component, Input } from '@angular/core';
import { TransactionResource } from 'api-client';

@Component({
  selector: 'app-commission-explained',
  templateUrl: './commission-explained.component.html',
  styleUrls: ['./commission-explained.component.scss'],
})
export class CommissionExplainedComponent {
  @Input() transaction: TransactionResource | null = null;
}
