/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvoiceResource } from './invoiceResource';
import { PaginationResource } from './paginationResource';


/**
 * Invoice paginated resource
 */
export interface InvoicePaginatedResource { 
    /**
     * Collection of Invoice resources
     */
    data?: Array<InvoiceResource>;
    pagination?: PaginationResource;
}

