import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionResource } from 'api-client';

@Component({
  templateUrl: './transaction-details-modal.component.html',
  styleUrls: ['./transaction-details-modal.component.scss'],
})
export class TransactionDetailsModalComponent {
  @Input() transaction: TransactionResource | null = null;

  isLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    platformLocation: PlatformLocation
  ) {
    platformLocation.onPopState(() =>
      setTimeout(() => this.activeModal.dismiss(), 500)
    );
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }
}
