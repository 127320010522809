import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';
if(environment.version != localStorage.getItem('version')){
  localStorage.clear();
  localStorage.setItem('version', environment.version);
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
