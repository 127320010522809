<h3 class="m-4 me-0">Prowizje sklepu</h3>
<section class="p-4 pt-0">
  <div class="row">
    <div *ngIf="isLoading" class="col-12">
      <div class="spinner-grow" role="status">
        <span class="visually-hidden">Ładowanie...</span>
      </div>
    </div>
    <div *ngIf="shop$ | async as shop" class="col-12 col-lg-6 d-flex">
      <div class="card w-100 m-2">
        <div class="card-header">
          <h3>Aktualny profil:{{ shop.fee?.name }}</h3>
        </div>
        <div class="card-body">
          <p>
            <strong>Cena miesięczna:</strong>
            {{ shop.fee?.monthly_price | currencyNumber : 'PLN' }}
          </p>
          <p>
            <strong>Opłata :</strong>
            {{ shop.fee?.fee_percent ?? 0 | currencyNumber : 'PLN' }}% / za
            transakcję
          </p>
          <p>
            <strong>Najważniejsze informacje:</strong>
          </p>
          <ul>
            <li *ngFor="let bullet of shop.fee?.parameters">
              {{ bullet.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="fees$ | async as fees" class="col col-12 col-md-6 d-flex">
      <div class="card card-body m-2">
        <div class="list-group">
          <app-fee-list-item
            *ngFor="let fee of fees"
            [fee]="fee"
            [active]="fee.id === selectedFee?.id"
            [actual]="fee.id === actualFeeId"
            (click)="onFeeSelect(fee)"
          >
          </app-fee-list-item>
        </div>
        <button
          class="btn btn-primary mt-3"
          (click)="onChangeCommission()"
          [disabled]="
            isLoading || !selectedFee || selectedFee.id === actualFeeId
          "
        >
          Zapisz zmianę profilu prowizji
        </button>
      </div>
    </div>

    <!-- <div class="col-12 d-flex">
      <div class="card w-100 m-2">
        <div class="card-header">
          <h3>Historia zmian prowizji</h3>
        </div>
        <div class="card-body" *ngIf="history$ | async as history">
          <eself-table class="w-100" [data]="history ?? []">
            <ng-template eselfTableHeader>
              <th scope="col">Nazwa abonamentu</th>
              <th scope="col">Data startu</th>
              <th scope="col">Data zakończenia</th>
            </ng-template>
            <ng-template [eselfTableRow]="history ?? []" let-row>
              <td scope="row">{{ row.commission.name }}</td>
              <td scope="row">
                {{ row.startDate | safeDate : 'long' }}
              </td>
              <td scope="row">
                {{ row.endDate | safeDate : 'long' }}
              </td>
            </ng-template>
          </eself-table>
        </div>
      </div>
    </div> -->
  </div>
</section>
