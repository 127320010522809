/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Payment Method resource
 */
export interface PaymentMethodResource { 
    /**
     * ID
     */
    id?: number;
    /**
     * Payment method name
     */
    name?: string;
    /**
     * Base64 encoded logo of the payment method
     */
    logo_base64?: string;
    /**
     * Fee percentage for using the payment method
     */
    fee_percent?: number;
    /**
     * Charge amount on using the payment method
     */
    charge?: number;
    /**
     * External ID referencing to the payment method\'s ID in external system
     */
    external_id?: string | null;
}

