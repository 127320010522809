import {
  Component,
  ContentChild,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { Subject } from 'rxjs';
import { TableHeaderTemplateDirective } from '../common-table/directives/table-header-template.directive';
import { TableRowTemplateDirective } from '../common-table/directives/table-row-template.directive';
import { BaseTable } from '../common-table/base-table.component';

@Component({
  selector: 'eself-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<TItem extends object> extends BaseTable {
  @Input() pageSize: number | null = null;
  private _pageIndex = 0;
  public get pageIndex() {
    return this._pageIndex;
  }
  @Input()
  public set pageIndex(value: number) {
    this._pageIndex = value;
    const pageFrame = this.generateClosestPages(
      this.pageSize,
      this.pageData?.length,
      this.totalCount
    );
    if (pageFrame) {
      this.closestPages = pageFrame.closestPages;
      this.canGoBackward = pageFrame.canGoBackward;
      this.canGoForward = pageFrame.canGoForward;
    }
  }
  private _totalCount = 0;
  public get totalCount() {
    return this._totalCount;
  }
  @Input()
  public set totalCount(value: number) {
    this._totalCount = value;
    const pageFrame = this.generateClosestPages(
      this.pageSize,
      this.pageData?.length,
      this.totalCount
    );
    if (pageFrame) {
      this.closestPages = pageFrame.closestPages;
      this.canGoBackward = pageFrame.canGoBackward;
      this.canGoForward = pageFrame.canGoForward;
    }
  }
  @Input() pageData!: TItem[];
  @Input() filteredData!: TItem[];
  @Input() allData: TItem[] | null = null;
  @Input() isLoading = false;
  @Input() searchPlaceholder: string | null = 'Szukaj...';
  @Input() searchFunc: ((item: TItem, searchTerm: string) => boolean) | null =
    null;
  @Input() searchFuncContext: any = this;

  @Input() set data(data: any[] | null) {
    if (data == null) {
      data = [];
    }
    this.allData = data;
    this.filteredData = this.allData;
    this.totalCount = this.filteredData.length;
    if (this.pageSize) {
      this.onMoveToFirstPage();
    } else {
      this.pageData = this.filteredData;
    }
  }

  @Input() set renderPaginated(data: TItem[]) {
    this.pageData = data;
    const pageFrame = this.generateClosestPages(
      this.pageSize,
      data.length,
      this.totalCount
    );
    if (pageFrame) {
      this.closestPages = pageFrame.closestPages;
      this.canGoBackward = pageFrame.canGoBackward;
      this.canGoForward = pageFrame.canGoForward;
    }
  }

  @Input() displayPaginationDescription = true;
  @Input() displaySearch = false;

  @Output() pageChange = new Subject<number>();

  @Output() searchChange = new Subject<string>();

  searchText = '';
  closestPages: number[] = [];
  canGoBackward = false;
  canGoForward = false;
  @ContentChild(TableHeaderTemplateDirective, { read: TemplateRef })
  headers?: TemplateRef<any>;
  @ContentChild(TableRowTemplateDirective, { read: TemplateRef })
  rows?: TemplateRef<any>;

  public get startIndex(): number {
    return this.pageIndex * this.pageSize!;
  }

  public get endIndex(): number {
    if (this.filteredData) {
      return Math.min(
        this.startIndex + this.pageSize!,
        this.filteredData?.length
      );
    }
    return Math.min(
      this.startIndex + this.pageSize!,
      this.startIndex + this.pageData?.length
    );
  }

  public isPaginationEnabled(): boolean {
    return this.pageSize != null && this.pageData != undefined;
  }

  public onMoveToFirstPage(): void {
    if (!this.pageSize) {
      return;
    }
    this.onMoveToPage(0);
  }

  public onMoveToLastPage(): void {
    if (!this.pageSize) {
      return;
    }
    this.onMoveToPage(Math.ceil(this.totalCount / this.pageSize) - 1);
  }

  public onMoveToNextPage(): void {
    if (!this.pageSize) {
      return;
    }
    this.onMoveToPage(this.pageIndex + 1);
  }

  public onMoveToPreviousPage(): void {
    if (!this.pageSize) {
      return;
    }
    this.onMoveToPage(this.pageIndex - 1);
  }

  public onMoveToPage(page: number): void {
    if (!this.pageSize) {
      return;
    }
    this.pageChange.next(page);
    if (
      this.allData &&
      page >= 0 &&
      page < Math.ceil(this.totalCount / this.pageSize)
    ) {
      this.pageIndex = page;
      this.setPageData();
      const pageFrame = this.generateClosestPages(
        this.pageSize,
        page,
        this.totalCount
      );
      if (pageFrame) {
        this.closestPages = pageFrame.closestPages;
        this.canGoBackward = pageFrame.canGoBackward;
        this.canGoForward = pageFrame.canGoForward;
      }
    }
  }

  public search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchChange.next(searchTerm);
    if (this.allData) {
      if (searchTerm.length > 1 && this.searchFunc != null) {
        this.filteredData = this.allData.filter((x) =>
          this.searchFunc?.call(this.searchFuncContext, x, searchTerm)
        );
      } else {
        this.filteredData = this.allData;
      }
      this.onMoveToFirstPage();
    }
  }

  private setPageData(): void {
    if (!this.pageSize) {
      return;
    }
    this.totalCount = this.filteredData.length;
    this.pageData = this.filteredData.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize
    );
  }
}
