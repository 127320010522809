<div class="modal-content">
  <div class="modal-header">
    <h1>Kreator faktury</h1>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="p-4">
        <div class="form-group">
          <label for="invoice_number">Numer faktury</label>
          <span class="required-text">*</span>
          <input
            type="text"
            class="form-control"
            id="invoice_number"
            formControlName="invoice_number"
            required
          />
        </div>
        <div class="form-group">
          <label for="issue_date">Data wystawienia</label>
          <span class="required-text">*</span>
          <input
            type="datetime-local"
            class="form-control"
            id="issue_date"
            formControlName="issue_date"
            required
          />
        </div>
        <div class="form-group">
          <label for="note">Notatka</label>
          <textarea
            class="form-control"
            id="note"
            formControlName="note"
          ></textarea>
        </div>
        <div class="form-group">
          <label for="settlements">Wybrane rozliczenia</label>
          <ul>
            <li *ngFor="let settlement of settlements">
              #{{ settlement.id }} - Kwota całkowita:
              <b>
                {{
                  settlement.amount | currencyNumber : settlement.currency
                }}</b
              >, Łączne opłaty (brutto):
              <b>
                {{
                  settlement.total_fee_gross
                    | currencyNumber : settlement.currency
                }}</b
              >, prowizja eSelf (vat):
              <b>{{
                settlement.eself_fee_vat | currencyNumber : settlement.currency
              }}</b
              >, mikroopłata (vat):
              <b>{{
                settlement.micro_fee_vat | currencyNumber : settlement.currency
              }}</b>
            </li>
          </ul>
        </div>
        <div>
          <h4>Podsumowanie (wstępne)</h4>
          Kwota całkowita: <b>{{ totalAmount | currencyNumber : 'PLN' }}</b
          ><br />
          Prowizja eSelf (vat):
          <b>{{ totalEselfVat | currencyNumber : 'PLN' }}</b
          ><br />
          Prowizja eSelf mikroopłata (vat):
          <b>{{ totalMicroFeeVat | currencyNumber : 'PLN' }}</b
          ><br />
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="onClose()"
    >
      Zamknij
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="form.invalid"
      (click)="onSubmit()"
    >
      Zapisz
    </button>
  </div>
</div>
