import { OrderModel } from './order.model';

export interface ShopModel {
  id: number;
  logo?: string | undefined;
  logoBackground: string;
  commissionId: number;
  name: string;
  url: string;
  urlReadable: string;
  businessMail: string;
  businessTel: string;
  customerTel: string;
  customerMail: string;
  contactPerson: string;
  customerCare: string;
  nip: string;
  regon: string;
  transactions: OrderModel[];
}
