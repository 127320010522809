import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { OrderChangedEvent, SortOrderType } from '../../../../../../shared-services/src/lib/models/order-type.enum';

@Directive({
  selector: '[eselfTableSort]',
})
export class TableSortDirective {
  @Input() set eselfTableSort(value: string) {
    this.property = value;
  }

  @Input() property = '';

  @Input() set currentProperty(value: string) {
    if (this.property != value) {
      this.order = SortOrderType.NONE;
      const elem = this.targetElem.nativeElement;
      elem.classList.remove('desc');
      elem.classList.remove('asc');
    }
  }

  @Input() order: SortOrderType = SortOrderType.NONE;

  @Output() sort = new EventEmitter<OrderChangedEvent>();

  constructor(private renderer: Renderer2, private targetElem: ElementRef) {}

  @HostListener('click')
  sortData() {
    const elem = this.targetElem.nativeElement;
    if (this.order === SortOrderType.DESC) {
      this.order = SortOrderType.ASC;
      elem.classList.add('asc');
      elem.classList.remove('desc');
    } else if (this.order === SortOrderType.ASC) {
      this.order = SortOrderType.NONE;
      elem.classList.remove('asc');
      elem.classList.remove('desc');
    } else {
      this.order = SortOrderType.DESC;
      elem.classList.remove('asc');
      elem.classList.add('desc');
    }
    this.sort.emit({ property: this.property, order: this.order });
  }
}
