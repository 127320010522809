import { Injectable } from '@angular/core';
import { TokenPayload } from '../models/auth';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _jwt: TokenPayload | null = null;
  get isAuthenticated() {
    return this._jwt !== null && this._jwt.exp > Date.now() / 1000;
  }

  get token(): TokenPayload | null {
    return this._jwt;
  }

  constructor() {
    const token = localStorage.getItem('token');
    if (token) {
      this.setToken(token);
    }
  }

  setToken(token: string): void {
    try {
      localStorage.setItem('token', token);
      const jwt = JSON.parse(atob(token.split('.')[1])) as TokenPayload;
      if (jwt.exp < Date.now() / 1000) {
        throw new Error('Token expired');
      }
      this._jwt = jwt;
    } catch (e) {
      console.error(e);
    }
  }

  getAccessToken(): string {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No access token');
    }
    return `Bearer ${token}`;
  }

  logout(): void {
    localStorage.removeItem('token');
    this._jwt = null;
  }
}
