import { Pipe, PipeTransform } from '@angular/core';
import { RefundStatus } from 'api-client';


@Pipe({
  name: 'refundStatusText',
})
/**
 * 1 - Pending, 2 - Realized
 */
export class RefundStatusTextPipe implements PipeTransform {
  transform(value: RefundStatus | undefined, ...args: unknown[]): string {
    if (value === RefundStatus.NUMBER_1) {
      return 'Oczekująca';
    }
    return 'Zrealizowana';
  }
}
