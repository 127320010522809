import { Component, Input } from '@angular/core';
import { FeeResource } from 'api-client';

@Component({
  selector: 'app-fee-list-item',
  templateUrl: './fee-list-item.component.html',
  styleUrls: ['./fee-list-item.component.scss'],
})
export class FeeListItemComponent {
  @Input() fee: FeeResource | null = null;

  @Input() active = false;

  @Input() actual = false;
}
