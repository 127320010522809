import { Pipe, PipeTransform } from '@angular/core';
import { ToBePaidStatus } from 'api-client';


@Pipe({
  name: 'toBePaidStatusText',
})

/**
 * 1 - Pending, 2 - Realized
 */
export class ToBePaidStatusTextPipe implements PipeTransform {
  transform(value: ToBePaidStatus | undefined, ...args: unknown[]): string {
    if (value === ToBePaidStatus.NUMBER_1) {
      return 'Nowa';
    }
    return 'Zrealizowana';
  }
}
