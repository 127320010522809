<h3 class="m-4">
  Onboarding <span *ngIf="step < 4"> - krok{{ step }} z {{ allSteps }}</span>
</h3>
<div class="d-flex">
  <div class="card mt-0 m-4 p-4">
    <div class="card-body">
      {{ error }}
      <app-shop-onboarding-step1 *ngIf="step === 1" (formResult)="onFirstStepReady($event)"
        [formValue]="this.firstStep"></app-shop-onboarding-step1>
      <app-shop-onboarding-step2 *ngIf="step === 2" (formResult)="onSecondStepReady($event)" (back)="onSecondStepBack()"
        [formValue]="this.secondStep"></app-shop-onboarding-step2>
      <app-shop-onboarding-step3 *ngIf="step === 3" (back)="onThirdStepBack()"
        (formResult)="onThirdStepReady($event)"></app-shop-onboarding-step3>
      <div *ngIf="step > 3 && !token" class="loading-bar">
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated mt-2" role="progressbar"
            [style.width.%]="25"></div>
        </div>
      </div>

      <div *ngIf="token" class="alert alert-info mt-2" role="alert">
        <h1>Gratulacje!</h1>
        <p>Sklep został zarejestrwaony.</p>
        <h2>Twój token:</h2>
        <span class="pointer shadow-on-hover" [copy]="token">
          {{ token }}
        </span>
        <span class="mt-3">
          <br />
          <b>Token do odczytu dostępny jest tylko raz, pamiętaj aby zapisać w
            bezpieczynm miejscu.</b>
        </span>
      </div>
    </div>
  </div>
</div>
