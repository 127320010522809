/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * User resource
 */
export interface UserResource { 
    /**
     * ID
     */
    id?: string;
    /**
     * User\'s email
     */
    email?: string;
    /**
     * Created at
     */
    created_at?: string;
    /**
     * Updated at
     */
    updated_at?: string;
}

