import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CurrencyNumberPipe,
  SharedServicesModule,
} from '@eself/shared-services';
import { CommissionTypeReadablePipe } from './pipes/commission-type-readable.pipe';
import { SharedUiModule } from '@eself/shared-ui';
import { TransactionDetailsModalComponent } from './components/modals/transaction-details-modal/transaction-details-modal.component';
import { CommissionConfigurationModalComponent } from './components/modals/commission-configuration-modal/commission-configuration-modal.component';
import { CreatePaymentWallModalComponent } from './components/modals/create-payment-wall-modal/create-payment-wall-modal.component';
import { ShopOnboardingStep1Component } from './components/shop-onboarding-step1/shop-onboarding-step1.component';
import { ShopOnboardingStep2Component } from './components/shop-onboarding-step2/shop-onboarding-step2.component';
import { ShopOnboardingStep3Component } from './components/shop-onboarding-step3/shop-onboarding-step3.component';
import { CreateInvoiceModalComponent } from './components/modals/create-invoice-modal/create-invoice-modal.component';
import { RodoTableComponent } from './components/rodo-table/rodo-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    CommissionTypeReadablePipe,
    TransactionDetailsModalComponent,
    CommissionConfigurationModalComponent,
    CreatePaymentWallModalComponent,
    ShopOnboardingStep1Component,
    ShopOnboardingStep2Component,
    ShopOnboardingStep3Component,
    CreateInvoiceModalComponent,
    RodoTableComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedUiModule,
    SharedServicesModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [CurrencyNumberPipe],
  exports: [
    CommissionTypeReadablePipe,
    TransactionDetailsModalComponent,
    CommissionConfigurationModalComponent,
    ShopOnboardingStep1Component,
    ShopOnboardingStep2Component,
    ShopOnboardingStep3Component,
    CreateInvoiceModalComponent,
  ],
})
export class SharedModule {}
