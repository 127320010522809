<div
  *ngIf="fee"
  class="list-group-item list-group-item-action"
  [class.active]="active"
>
  <div class="d-flex justify-content-between">
    <div>
      <span class="fw-bold">{{ fee.name }}</span>
      <span *ngIf="actual" class="fw-bold"> (obecny profil)</span>
      <br />
      <span>
        opłata miesięczna:
        {{ fee.monthly_price | currencyNumber : 'PLN' }}
      </span>
      <br />
      <span>
        prowizja od transakcji:
        {{ fee.fee_percent ?? 0 | currencyNumber : '%' }}
      </span>
      <br />
      <span>
        mikro opłata:
        {{ fee.micro_fee | currencyNumber : 'PLN' }}
      </span>
      <br />
    </div>
    <div>
      Oferta ważna do:
      <span class="fw-bold">{{ fee.valid_to | safeDate }}</span>
      <br />
      Slogan:
      <span class="fw-bold">{{ fee.discount_text }}</span>
    </div>
  </div>
</div>
