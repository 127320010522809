import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationData } from '@eself/shared-services';
import { ClosestPages } from '../base-table.component';

@Component({
  selector: 'eself-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent {
  @Output() moveToFirstPage = new EventEmitter<void>();
  @Output() moveToLastPage = new EventEmitter<void>();
  @Output() moveToNextPage = new EventEmitter<void>();
  @Output() moveToPreviousPage = new EventEmitter<void>();
  @Output() moveToPage = new EventEmitter<number>();

  @Input() isBusy = false;

  @Input() set pagination(pagination: PaginationData | null | undefined) {
    if (
      pagination &&
      pagination.current_page &&
      pagination.per_page &&
      pagination.total
    ) {
      this.currentPage = pagination.current_page ?? 0;
      const closestPages: number[] = [];
      this.totalPages = Math.ceil(pagination.total / pagination.per_page);
      const currentPage = pagination.current_page;
      if (currentPage > 1) {
        if (currentPage > 2) {
          closestPages.push(currentPage - 2);
        }
        closestPages.push(currentPage - 1);
      }
      closestPages.push(currentPage);

      if (currentPage < this.totalPages) {
        closestPages.push(currentPage + 1);
      }
      if (
        (currentPage == 1 && this.totalPages > 2) ||
        this.totalPages - currentPage > 2
      ) {
        closestPages.push(currentPage + 2);
        if (closestPages.length < 3 && this.totalPages - currentPage > 3) {
          closestPages.push(currentPage + 3);
        }
      }
      this.pages = closestPages;
      this.canGoBackward = currentPage > 1;
      this.canGoForward = currentPage < this.totalPages;
    }
  }

  @Input() pages: number[] = [];
  @Input() currentPage = 0;
  @Input() canGoBackward = false;
  @Input() canGoForward = false;
  private totalPages = 0;

  public onMoveToFirstPage(): void {
    if (this.isBusy) {
      return;
    }
    this.moveToFirstPage.emit();
    this.moveToPage.emit(1);
  }

  public onMoveToLastPage(): void {
    if (this.isBusy) {
      return;
    }
    this.moveToLastPage.emit();
    this.moveToPage.emit(this.totalPages);
  }

  public onMoveToNextPage(): void {
    if (this.isBusy) {
      return;
    }
    this.moveToNextPage.emit();
    this.moveToPage.emit(this.currentPage + 1);
  }

  public onMoveToPreviousPage(): void {
    if (this.isBusy) {
      return;
    }
    this.moveToPreviousPage.emit();
    this.moveToPage.emit(this.currentPage - 1);
  }

  public onMoveToPage(pageIndex: number): void {
    if (this.isBusy) {
      return;
    }
    this.moveToPage.emit(pageIndex);
  }
}
