import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyNumber',
})
export class CurrencyNumberPipe implements PipeTransform {
  constructor(private numberPipe: DecimalPipe) {}
  transform(
    value: number | string | null | undefined,
    currencyText?: string | null
  ): string | null {
    if (currencyText === undefined || currencyText === null)
      currencyText = '';
    return `${this.numberPipe.transform(
      value,
      '1.2-2',
      'pl-PL'
    )} ${currencyText}`;
  }
}
