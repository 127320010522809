export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './billingStatus.service';
import { BillingStatusService } from './billingStatus.service';
export * from './fee.service';
import { FeeService } from './fee.service';
export * from './general.service';
import { GeneralService } from './general.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './paymentWall.service';
import { PaymentWallService } from './paymentWall.service';
export * from './settlement.service';
import { SettlementService } from './settlement.service';
export * from './shop.service';
import { ShopService } from './shop.service';
export * from './transaction.service';
import { TransactionService } from './transaction.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AuthenticationService, BillingStatusService, FeeService, GeneralService, InvoiceService, PaymentWallService, SettlementService, ShopService, TransactionService, UserService];
