/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Address } from '../model/address';
// @ts-ignore
import { MailingAddress } from '../model/mailingAddress';
// @ts-ignore
import { RegisterShop200Response } from '../model/registerShop200Response';
// @ts-ignore
import { RegisterShop400Response } from '../model/registerShop400Response';
// @ts-ignore
import { ShopChangeFeeNoteRequest } from '../model/shopChangeFeeNoteRequest';
// @ts-ignore
import { ShopResource } from '../model/shopResource';
// @ts-ignore
import { ShopSaldoResource } from '../model/shopSaldoResource';
// @ts-ignore
import { ShopType } from '../model/shopType';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ShopService {

    protected basePath = 'https://internal.api.dev.1clickstore.online/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Change shop fee
     * @param shopId 
     * @param feeId 
     * @param shopChangeFeeNoteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeShopFeeNote(shopId: number, feeId: number, shopChangeFeeNoteRequest: ShopChangeFeeNoteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public changeShopFeeNote(shopId: number, feeId: number, shopChangeFeeNoteRequest: ShopChangeFeeNoteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public changeShopFeeNote(shopId: number, feeId: number, shopChangeFeeNoteRequest: ShopChangeFeeNoteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public changeShopFeeNote(shopId: number, feeId: number, shopChangeFeeNoteRequest: ShopChangeFeeNoteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (shopId === null || shopId === undefined) {
            throw new Error('Required parameter shopId was null or undefined when calling changeShopFeeNote.');
        }
        if (feeId === null || feeId === undefined) {
            throw new Error('Required parameter feeId was null or undefined when calling changeShopFeeNote.');
        }
        if (shopChangeFeeNoteRequest === null || shopChangeFeeNoteRequest === undefined) {
            throw new Error('Required parameter shopChangeFeeNoteRequest was null or undefined when calling changeShopFeeNote.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (api-key) required
        localVarCredential = this.configuration.lookupCredential('api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Api-Key', localVarCredential);
        }

        // authentication (api) required
        localVarCredential = this.configuration.lookupCredential('api');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Shop/${this.configuration.encodeParam({name: "shopId", value: shopId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/Fee/${this.configuration.encodeParam({name: "feeId", value: feeId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: shopChangeFeeNoteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate new shop token
     * @param shopId Shop ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateNewShopToken(shopId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<RegisterShop200Response>;
    public generateNewShopToken(shopId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<RegisterShop200Response>>;
    public generateNewShopToken(shopId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<RegisterShop200Response>>;
    public generateNewShopToken(shopId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (shopId === null || shopId === undefined) {
            throw new Error('Required parameter shopId was null or undefined when calling generateNewShopToken.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (api-key) required
        localVarCredential = this.configuration.lookupCredential('api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Api-Key', localVarCredential);
        }

        // authentication (api) required
        localVarCredential = this.configuration.lookupCredential('api');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Shop/${this.configuration.encodeParam({name: "shopId", value: shopId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/NewToken`;
        return this.httpClient.request<RegisterShop200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all shops
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllShops(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ShopResource>>;
    public getAllShops(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ShopResource>>>;
    public getAllShops(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ShopResource>>>;
    public getAllShops(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (api-key) required
        localVarCredential = this.configuration.lookupCredential('api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Api-Key', localVarCredential);
        }

        // authentication (api) required
        localVarCredential = this.configuration.lookupCredential('api');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Shops`;
        return this.httpClient.request<Array<ShopResource>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get shop by id
     * @param shopId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShop(shopId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ShopResource>;
    public getShop(shopId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ShopResource>>;
    public getShop(shopId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ShopResource>>;
    public getShop(shopId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (shopId === null || shopId === undefined) {
            throw new Error('Required parameter shopId was null or undefined when calling getShop.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (api-key) required
        localVarCredential = this.configuration.lookupCredential('api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Api-Key', localVarCredential);
        }

        // authentication (api) required
        localVarCredential = this.configuration.lookupCredential('api');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Shops/${this.configuration.encodeParam({name: "shopId", value: shopId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<ShopResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get shop saldo
     * @param shopId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShopSaldo(shopId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ShopSaldoResource>;
    public getShopSaldo(shopId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ShopSaldoResource>>;
    public getShopSaldo(shopId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ShopSaldoResource>>;
    public getShopSaldo(shopId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (shopId === null || shopId === undefined) {
            throw new Error('Required parameter shopId was null or undefined when calling getShopSaldo.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (api-key) required
        localVarCredential = this.configuration.lookupCredential('api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Api-Key', localVarCredential);
        }

        // authentication (api) required
        localVarCredential = this.configuration.lookupCredential('api');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Shops/Saldo/${this.configuration.encodeParam({name: "shopId", value: shopId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<ShopSaldoResource>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new shop
     * @param feeId Fee ID
     * @param paymentwallId Paymentwall ID
     * @param merchantId Merchant ID
     * @param type 
     * @param name Name of the shop
     * @param url URL of the shop
     * @param rodoEmail Rodo email of the shop
     * @param phoneNumber Phone number of the shop
     * @param email Email of the shop
     * @param password Password of the shop
     * @param passwordConfirmation 
     * @param invoiceEmail Invoice email of the shop
     * @param paymentwallShopTypeId Paymentwall Shop Type ID
     * @param logo Logo of the shop
     * @param catalog Catalog of the shop
     * @param nip NIP of the shop
     * @param regon Regon of the shop
     * @param krs KRS of the shop
     * @param pesel Pesel of the shop
     * @param bankAccount Bank account of the shop
     * @param customerAssistantId Customer assistant ID of the shop
     * @param address 
     * @param mailingAddress 
     * @param businessPerson Business person of the shop
     * @param businessPhone Business phone of the shop
     * @param businessMail Business mail of the shop
     * @param clientPerson Client person of the shop
     * @param clientPhone Client phone of the shop
     * @param clientMail Client mail of the shop
     * @param representativePerson Representative person of the shop
     * @param representativePesel Representative\\\&#39;s PESEL of the shop
     * @param paymentwallContactPerson PaymentWall\\\&#39;s contact person of the shop
     * @param paymentwallContactPhone PaymentWall\\\&#39;s contact phone of the shop
     * @param paymentwallContactMail PaymentWall\\\&#39;s contact email of the shop
     * @param paymentwallTechnicalContactPerson PaymentWall\\\&#39;s technical contact person of the shop
     * @param paymentwallTechnicalContactPhone PaymentWall\\\&#39;s technical contact phone of the shop
     * @param paymentwallTechnicalContactMail PaymentWall\\\&#39;s technical contact mail of the shop
     * @param privacyPolicyUrl 
     * @param regulationsUrl 
     * @param note 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerShop(feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, password: string, passwordConfirmation: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<RegisterShop200Response>;
    public registerShop(feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, password: string, passwordConfirmation: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<RegisterShop200Response>>;
    public registerShop(feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, password: string, passwordConfirmation: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<RegisterShop200Response>>;
    public registerShop(feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, password: string, passwordConfirmation: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (feeId === null || feeId === undefined) {
            throw new Error('Required parameter feeId was null or undefined when calling registerShop.');
        }
        if (paymentwallId === null || paymentwallId === undefined) {
            throw new Error('Required parameter paymentwallId was null or undefined when calling registerShop.');
        }
        if (merchantId === null || merchantId === undefined) {
            throw new Error('Required parameter merchantId was null or undefined when calling registerShop.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling registerShop.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling registerShop.');
        }
        if (url === null || url === undefined) {
            throw new Error('Required parameter url was null or undefined when calling registerShop.');
        }
        if (rodoEmail === null || rodoEmail === undefined) {
            throw new Error('Required parameter rodoEmail was null or undefined when calling registerShop.');
        }
        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling registerShop.');
        }
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling registerShop.');
        }
        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling registerShop.');
        }
        if (passwordConfirmation === null || passwordConfirmation === undefined) {
            throw new Error('Required parameter passwordConfirmation was null or undefined when calling registerShop.');
        }
        if (invoiceEmail === null || invoiceEmail === undefined) {
            throw new Error('Required parameter invoiceEmail was null or undefined when calling registerShop.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (api-key) required
        localVarCredential = this.configuration.lookupCredential('api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Api-Key', localVarCredential);
        }

        // authentication (api) required
        localVarCredential = this.configuration.lookupCredential('api');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (feeId !== undefined) {
            localVarFormParams = localVarFormParams.append('fee_id', <any>feeId) as any || localVarFormParams;
        }
        if (paymentwallId !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_id', <any>paymentwallId) as any || localVarFormParams;
        }
        if (paymentwallShopTypeId !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_shop_type_id', <any>paymentwallShopTypeId) as any || localVarFormParams;
        }
        if (merchantId !== undefined) {
            localVarFormParams = localVarFormParams.append('merchant_id', <any>merchantId) as any || localVarFormParams;
        }
        if (type !== undefined) {
            localVarFormParams = localVarFormParams.append('type', <any>type) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (url !== undefined) {
            localVarFormParams = localVarFormParams.append('url', <any>url) as any || localVarFormParams;
        }
        if (logo !== undefined) {
            localVarFormParams = localVarFormParams.append('logo', <any>logo) as any || localVarFormParams;
        }
        if (catalog !== undefined) {
            localVarFormParams = localVarFormParams.append('catalog', <any>catalog) as any || localVarFormParams;
        }
        if (rodoEmail !== undefined) {
            localVarFormParams = localVarFormParams.append('rodo_email', <any>rodoEmail) as any || localVarFormParams;
        }
        if (nip !== undefined) {
            localVarFormParams = localVarFormParams.append('nip', <any>nip) as any || localVarFormParams;
        }
        if (regon !== undefined) {
            localVarFormParams = localVarFormParams.append('regon', <any>regon) as any || localVarFormParams;
        }
        if (krs !== undefined) {
            localVarFormParams = localVarFormParams.append('krs', <any>krs) as any || localVarFormParams;
        }
        if (pesel !== undefined) {
            localVarFormParams = localVarFormParams.append('pesel', <any>pesel) as any || localVarFormParams;
        }
        if (phoneNumber !== undefined) {
            localVarFormParams = localVarFormParams.append('phone_number', <any>phoneNumber) as any || localVarFormParams;
        }
        if (email !== undefined) {
            localVarFormParams = localVarFormParams.append('email', <any>email) as any || localVarFormParams;
        }
        if (password !== undefined) {
            localVarFormParams = localVarFormParams.append('password', <any>password) as any || localVarFormParams;
        }
        if (passwordConfirmation !== undefined) {
            localVarFormParams = localVarFormParams.append('password_confirmation', <any>passwordConfirmation) as any || localVarFormParams;
        }
        if (bankAccount !== undefined) {
            localVarFormParams = localVarFormParams.append('bank_account', <any>bankAccount) as any || localVarFormParams;
        }
        if (invoiceEmail !== undefined) {
            localVarFormParams = localVarFormParams.append('invoice_email', <any>invoiceEmail) as any || localVarFormParams;
        }
        if (customerAssistantId !== undefined) {
            localVarFormParams = localVarFormParams.append('customer_assistant_id', <any>customerAssistantId) as any || localVarFormParams;
        }
        if (address !== undefined) {
            localVarFormParams = localVarFormParams.append('address', localVarUseForm ? new Blob([JSON.stringify(address)], {type: 'application/json'}) : <any>address) as any || localVarFormParams;
        }
        if (mailingAddress !== undefined) {
            localVarFormParams = localVarFormParams.append('mailing_address', localVarUseForm ? new Blob([JSON.stringify(mailingAddress)], {type: 'application/json'}) : <any>mailingAddress) as any || localVarFormParams;
        }
        if (businessPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('business_person', <any>businessPerson) as any || localVarFormParams;
        }
        if (businessPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('business_phone', <any>businessPhone) as any || localVarFormParams;
        }
        if (businessMail !== undefined) {
            localVarFormParams = localVarFormParams.append('business_mail', <any>businessMail) as any || localVarFormParams;
        }
        if (clientPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('client_person', <any>clientPerson) as any || localVarFormParams;
        }
        if (clientPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('client_phone', <any>clientPhone) as any || localVarFormParams;
        }
        if (clientMail !== undefined) {
            localVarFormParams = localVarFormParams.append('client_mail', <any>clientMail) as any || localVarFormParams;
        }
        if (representativePerson !== undefined) {
            localVarFormParams = localVarFormParams.append('representative_person', <any>representativePerson) as any || localVarFormParams;
        }
        if (representativePesel !== undefined) {
            localVarFormParams = localVarFormParams.append('representative_pesel', <any>representativePesel) as any || localVarFormParams;
        }
        if (paymentwallContactPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_contact_person', <any>paymentwallContactPerson) as any || localVarFormParams;
        }
        if (paymentwallContactPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_contact_phone', <any>paymentwallContactPhone) as any || localVarFormParams;
        }
        if (paymentwallContactMail !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_contact_mail', <any>paymentwallContactMail) as any || localVarFormParams;
        }
        if (paymentwallTechnicalContactPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_technical_contact_person', <any>paymentwallTechnicalContactPerson) as any || localVarFormParams;
        }
        if (paymentwallTechnicalContactPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_technical_contact_phone', <any>paymentwallTechnicalContactPhone) as any || localVarFormParams;
        }
        if (paymentwallTechnicalContactMail !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_technical_contact_mail', <any>paymentwallTechnicalContactMail) as any || localVarFormParams;
        }
        if (privacyPolicyUrl !== undefined) {
            localVarFormParams = localVarFormParams.append('privacy_policy_url', <any>privacyPolicyUrl) as any || localVarFormParams;
        }
        if (regulationsUrl !== undefined) {
            localVarFormParams = localVarFormParams.append('regulations_url', <any>regulationsUrl) as any || localVarFormParams;
        }
        if (note !== undefined) {
            localVarFormParams = localVarFormParams.append('note', <any>note) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Shop`;
        return this.httpClient.request<RegisterShop200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the shop
     * @param shopId Shop ID
     * @param feeId Fee ID
     * @param paymentwallId Paymentwall ID
     * @param merchantId Merchant ID
     * @param type 
     * @param name Name of the shop
     * @param url URL of the shop
     * @param rodoEmail Rodo email of the shop
     * @param phoneNumber Phone number of the shop
     * @param email Email of the shop
     * @param invoiceEmail Invoice email of the shop
     * @param paymentwallShopTypeId Paymentwall Shop Type ID
     * @param logo Logo of the shop
     * @param catalog Catalog of the shop
     * @param nip NIP of the shop
     * @param regon Regon of the shop
     * @param krs KRS of the shop
     * @param pesel Pesel of the shop
     * @param bankAccount Bank account of the shop
     * @param customerAssistantId Customer assistant ID of the shop
     * @param address 
     * @param mailingAddress 
     * @param businessPerson Business person of the shop
     * @param businessPhone Business phone of the shop
     * @param businessMail Business mail of the shop
     * @param clientPerson Client person of the shop
     * @param clientPhone Client phone of the shop
     * @param clientMail Client mail of the shop
     * @param representativePerson Representative person of the shop
     * @param representativePesel Representative\\\&#39;s PESEL of the shop
     * @param paymentwallContactPerson PaymentWall\\\&#39;s contact person of the shop
     * @param paymentwallContactPhone PaymentWall\\\&#39;s contact phone of the shop
     * @param paymentwallContactMail PaymentWall\\\&#39;s contact email of the shop
     * @param paymentwallTechnicalContactPerson PaymentWall\\\&#39;s technical contact person of the shop
     * @param paymentwallTechnicalContactPhone PaymentWall\\\&#39;s technical contact phone of the shop
     * @param paymentwallTechnicalContactMail PaymentWall\\\&#39;s technical contact mail of the shop
     * @param privacyPolicyUrl 
     * @param regulationsUrl 
     * @param note 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShop(shopId: number, feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public updateShop(shopId: number, feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateShop(shopId: number, feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateShop(shopId: number, feeId: number, paymentwallId: number, merchantId: string, type: ShopType, name: string, url: string, rodoEmail: string, phoneNumber: string, email: string, invoiceEmail: string, paymentwallShopTypeId?: number, logo?: Blob, catalog?: Blob, nip?: string, regon?: string, krs?: string, pesel?: string, bankAccount?: string, customerAssistantId?: string, address?: Address, mailingAddress?: MailingAddress, businessPerson?: string, businessPhone?: string, businessMail?: string, clientPerson?: string, clientPhone?: string, clientMail?: string, representativePerson?: string, representativePesel?: string, paymentwallContactPerson?: string, paymentwallContactPhone?: string, paymentwallContactMail?: string, paymentwallTechnicalContactPerson?: string, paymentwallTechnicalContactPhone?: string, paymentwallTechnicalContactMail?: string, privacyPolicyUrl?: string, regulationsUrl?: string, note?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (shopId === null || shopId === undefined) {
            throw new Error('Required parameter shopId was null or undefined when calling updateShop.');
        }
        if (feeId === null || feeId === undefined) {
            throw new Error('Required parameter feeId was null or undefined when calling updateShop.');
        }
        if (paymentwallId === null || paymentwallId === undefined) {
            throw new Error('Required parameter paymentwallId was null or undefined when calling updateShop.');
        }
        if (merchantId === null || merchantId === undefined) {
            throw new Error('Required parameter merchantId was null or undefined when calling updateShop.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling updateShop.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling updateShop.');
        }
        if (url === null || url === undefined) {
            throw new Error('Required parameter url was null or undefined when calling updateShop.');
        }
        if (rodoEmail === null || rodoEmail === undefined) {
            throw new Error('Required parameter rodoEmail was null or undefined when calling updateShop.');
        }
        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling updateShop.');
        }
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling updateShop.');
        }
        if (invoiceEmail === null || invoiceEmail === undefined) {
            throw new Error('Required parameter invoiceEmail was null or undefined when calling updateShop.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (api-key) required
        localVarCredential = this.configuration.lookupCredential('api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Api-Key', localVarCredential);
        }

        // authentication (api) required
        localVarCredential = this.configuration.lookupCredential('api');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (feeId !== undefined) {
            localVarFormParams = localVarFormParams.append('fee_id', <any>feeId) as any || localVarFormParams;
        }
        if (paymentwallId !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_id', <any>paymentwallId) as any || localVarFormParams;
        }
        if (paymentwallShopTypeId !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_shop_type_id', <any>paymentwallShopTypeId) as any || localVarFormParams;
        }
        if (merchantId !== undefined) {
            localVarFormParams = localVarFormParams.append('merchant_id', <any>merchantId) as any || localVarFormParams;
        }
        if (type !== undefined) {
            localVarFormParams = localVarFormParams.append('type', <any>type) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (url !== undefined) {
            localVarFormParams = localVarFormParams.append('url', <any>url) as any || localVarFormParams;
        }
        if (logo !== undefined) {
            localVarFormParams = localVarFormParams.append('logo', <any>logo) as any || localVarFormParams;
        }
        if (catalog !== undefined) {
            localVarFormParams = localVarFormParams.append('catalog', <any>catalog) as any || localVarFormParams;
        }
        if (rodoEmail !== undefined) {
            localVarFormParams = localVarFormParams.append('rodo_email', <any>rodoEmail) as any || localVarFormParams;
        }
        if (nip !== undefined) {
            localVarFormParams = localVarFormParams.append('nip', <any>nip) as any || localVarFormParams;
        }
        if (regon !== undefined) {
            localVarFormParams = localVarFormParams.append('regon', <any>regon) as any || localVarFormParams;
        }
        if (krs !== undefined) {
            localVarFormParams = localVarFormParams.append('krs', <any>krs) as any || localVarFormParams;
        }
        if (pesel !== undefined) {
            localVarFormParams = localVarFormParams.append('pesel', <any>pesel) as any || localVarFormParams;
        }
        if (phoneNumber !== undefined) {
            localVarFormParams = localVarFormParams.append('phone_number', <any>phoneNumber) as any || localVarFormParams;
        }
        if (email !== undefined) {
            localVarFormParams = localVarFormParams.append('email', <any>email) as any || localVarFormParams;
        }
        if (bankAccount !== undefined) {
            localVarFormParams = localVarFormParams.append('bank_account', <any>bankAccount) as any || localVarFormParams;
        }
        if (invoiceEmail !== undefined) {
            localVarFormParams = localVarFormParams.append('invoice_email', <any>invoiceEmail) as any || localVarFormParams;
        }
        if (customerAssistantId !== undefined) {
            localVarFormParams = localVarFormParams.append('customer_assistant_id', <any>customerAssistantId) as any || localVarFormParams;
        }
        if (address !== undefined) {
            localVarFormParams = localVarFormParams.append('address', localVarUseForm ? new Blob([JSON.stringify(address)], {type: 'application/json'}) : <any>address) as any || localVarFormParams;
        }
        if (mailingAddress !== undefined) {
            localVarFormParams = localVarFormParams.append('mailing_address', localVarUseForm ? new Blob([JSON.stringify(mailingAddress)], {type: 'application/json'}) : <any>mailingAddress) as any || localVarFormParams;
        }
        if (businessPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('business_person', <any>businessPerson) as any || localVarFormParams;
        }
        if (businessPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('business_phone', <any>businessPhone) as any || localVarFormParams;
        }
        if (businessMail !== undefined) {
            localVarFormParams = localVarFormParams.append('business_mail', <any>businessMail) as any || localVarFormParams;
        }
        if (clientPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('client_person', <any>clientPerson) as any || localVarFormParams;
        }
        if (clientPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('client_phone', <any>clientPhone) as any || localVarFormParams;
        }
        if (clientMail !== undefined) {
            localVarFormParams = localVarFormParams.append('client_mail', <any>clientMail) as any || localVarFormParams;
        }
        if (representativePerson !== undefined) {
            localVarFormParams = localVarFormParams.append('representative_person', <any>representativePerson) as any || localVarFormParams;
        }
        if (representativePesel !== undefined) {
            localVarFormParams = localVarFormParams.append('representative_pesel', <any>representativePesel) as any || localVarFormParams;
        }
        if (paymentwallContactPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_contact_person', <any>paymentwallContactPerson) as any || localVarFormParams;
        }
        if (paymentwallContactPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_contact_phone', <any>paymentwallContactPhone) as any || localVarFormParams;
        }
        if (paymentwallContactMail !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_contact_mail', <any>paymentwallContactMail) as any || localVarFormParams;
        }
        if (paymentwallTechnicalContactPerson !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_technical_contact_person', <any>paymentwallTechnicalContactPerson) as any || localVarFormParams;
        }
        if (paymentwallTechnicalContactPhone !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_technical_contact_phone', <any>paymentwallTechnicalContactPhone) as any || localVarFormParams;
        }
        if (paymentwallTechnicalContactMail !== undefined) {
            localVarFormParams = localVarFormParams.append('paymentwall_technical_contact_mail', <any>paymentwallTechnicalContactMail) as any || localVarFormParams;
        }
        if (privacyPolicyUrl !== undefined) {
            localVarFormParams = localVarFormParams.append('privacy_policy_url', <any>privacyPolicyUrl) as any || localVarFormParams;
        }
        if (regulationsUrl !== undefined) {
            localVarFormParams = localVarFormParams.append('regulations_url', <any>regulationsUrl) as any || localVarFormParams;
        }
        if (note !== undefined) {
            localVarFormParams = localVarFormParams.append('note', <any>note) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Shop/${this.configuration.encodeParam({name: "shopId", value: shopId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
