import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { AuthService } from '@eself/shared-services';
import { Router } from '@angular/router';
import { SideBarElementModel } from '../../services/side-bar-element.model';

@Component({
  selector: 'eself-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  @Input()
  public menuElements: SideBarElementModel[] = [];

  @ContentChild('headerSide', { read: TemplateRef })
  headerSide?: TemplateRef<any>;

  get userEmail(): string {
    return this.auth.token?.email ?? '';
  }

  constructor(private auth: AuthService, private router: Router) {}

  logout(): void {
    this.auth.logout();
    this.router.navigate(['/auth']);
  }
}
