import { Pipe, PipeTransform } from '@angular/core';
import {
  WithdrawalStatus,
} from 'api-client';

@Pipe({
  name: 'withdrawalStatusText',
})
/**
 * 0 - New, 1 - Pending, 2 - Realized
 */
export class WithdrawalStatusTextPipe implements PipeTransform {
  transform(value: number | undefined, ...args: unknown[]): string {
    if (value === 0) {
      return 'Nowa';
    }
    if (value === 1) {
      return 'Oczekująca';
    }
    return 'Zrealizowana';
  }
}


