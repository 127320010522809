import { Pipe, PipeTransform } from '@angular/core';
import { WithdrawalStatus } from 'api-client';

@Pipe({
  name: 'withdrawalStatusColor',
})
export class WithdrawalStatusColorPipe implements PipeTransform {
  transform(value?: WithdrawalStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case WithdrawalStatus.NUMBER_0:
        return '#912da4'; // new
      case WithdrawalStatus.NUMBER_1:
        return '#006be0'; // in_progress
      case WithdrawalStatus.NUMBER_2:
        return '#68bb18'; // ready
    }
    return '#006be0';
  }
}
