import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { SafeDatePipe } from './pipes/safe-date.pipe';
import { CurrencyNumberPipe } from './pipes/currency-number.pipe';
import { StateWrapperPipe } from './pipes/state-wrapper.pipe';
import '@angular/common/locales/global/pl';
import { BankToIconPipe } from './pipes/bank-to-icon.pipe';
import { PaymentToIconPipe } from './pipes/status-icon/payment-to-icon.pipe';
import { TransactionOrderPipe } from './pipes/transaction-order.pipe';
import {
  InvoiceStatusTextPipe,
  PayoutStatusColorPipe,
  PayoutStatusIconPipe,
  PayoutStatusTextPipe,
  RefundStatusTextPipe,
  ShortDomainPipe,
  ToBePaidStatusTextPipe,
  TransactionStatusTextPipe,
  WithdrawalStatusTextPipe,
  PaymentTypeTextPipe,
  TransactionProductTypePipe,
  PaymentTypeIconPipe,
  TransactionStatusIconPipe,
  TransactionStatusColorPipe,
  InvoiceStatusIconPipe,
  InvoiceStatusColorPipe,
  WithdrawalStatusColorPipe,
  WithdrawalStatusIconPipe,
  PaymentMethodsReadablePipe,
  RodoStatusTextPipe,
  RodoStatusIconPipe,
  RodoStatusColorPipe,
} from './pipes';

@NgModule({
  declarations: [
    SafeDatePipe,
    CurrencyNumberPipe,
    StateWrapperPipe,
    BankToIconPipe,
    TransactionOrderPipe,
    ShortDomainPipe,
    PayoutStatusColorPipe,
    PaymentToIconPipe,
    PayoutStatusIconPipe,
    InvoiceStatusTextPipe,
    PayoutStatusTextPipe,
    RefundStatusTextPipe,
    ToBePaidStatusTextPipe,
    TransactionStatusTextPipe,
    WithdrawalStatusTextPipe,
    PaymentTypeTextPipe,
    TransactionProductTypePipe,
    PaymentTypeIconPipe,
    TransactionStatusColorPipe,
    TransactionStatusIconPipe,
    InvoiceStatusIconPipe,
    InvoiceStatusColorPipe,
    WithdrawalStatusColorPipe,
    WithdrawalStatusIconPipe,
    PaymentMethodsReadablePipe,
    RodoStatusTextPipe,
    RodoStatusColorPipe,
    RodoStatusIconPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafeDatePipe,
    CurrencyNumberPipe,
    StateWrapperPipe,
    BankToIconPipe,
    TransactionOrderPipe,
    ShortDomainPipe,
    PayoutStatusColorPipe,
    PaymentToIconPipe,
    PayoutStatusIconPipe,
    InvoiceStatusTextPipe,
    PayoutStatusTextPipe,
    RefundStatusTextPipe,
    ToBePaidStatusTextPipe,
    TransactionStatusTextPipe,
    WithdrawalStatusTextPipe,
    PaymentTypeTextPipe,
    TransactionProductTypePipe,
    PaymentTypeIconPipe,
    TransactionStatusColorPipe,
    TransactionStatusIconPipe,
    InvoiceStatusIconPipe,
    InvoiceStatusColorPipe,
    WithdrawalStatusColorPipe,
    WithdrawalStatusIconPipe,
    PaymentMethodsReadablePipe,
    RodoStatusTextPipe,
    RodoStatusColorPipe,
    RodoStatusIconPipe,
  ],
  providers: [DecimalPipe, DatePipe],
})
export class SharedServicesModule {}
