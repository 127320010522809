import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommissionConfigurationPageComponent } from './pages/commission-configuration-page/commission-configuration-page.component';
import { GateConfigurationPageComponent } from './pages/gate-configuration-page/gate-configuration-page.component';
import { GateConfigurationModalComponent } from './pages/gate-configuration-page/gate-configuration-modal/gate-configuration-modal.component';
import { GlobalComponent } from './global.component';
import { GlobalRoutingModule } from './global-routing.module';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import {
  SharedServicesModule,
  ShortDomainPipe,
  TransactionOrderPipe,
} from '@eself/shared-services';
import { SharedUiModule } from '@eself/shared-ui';
import { ShopSelectionPageComponent } from './pages/shop-selection-page/shop-selection-page.component';
import { TransactionsComponent } from './pages/transactions-page/transactions.component';
import { NewShopFormPageComponent } from './pages/new-shop-form-page/new-shop-form-page.component';
import { ShopModule } from '../shop/shop.module';
import { RodoPageComponent } from './pages/rodo-page/rodo-page.component';

@NgModule({
  declarations: [
    ShopSelectionPageComponent,
    TransactionsComponent,
    GateConfigurationPageComponent,
    CommissionConfigurationPageComponent,
    GlobalComponent,
    GateConfigurationModalComponent,
    NewShopFormPageComponent,
    RodoPageComponent,
  ],
  imports: [
    CommonModule,
    GlobalRoutingModule,
    SharedModule,
    ClipboardModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUiModule,
    SharedServicesModule,
    DatePipe,
    ShopModule,
  ],
  providers: [DatePipe, TransactionOrderPipe, ShortDomainPipe],
})
export class GlobalModule {}
