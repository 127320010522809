<h3 class="m-4">Konfiguracja bramek</h3>
<div class="ms-4 me-4">
  <input
    class="eself-input w-100"
    type="text"
    placeholder="Szukaj bramki:   id, nazwa"
    (keyup)="search($event)"
  />
</div>

<ng-container
  *ngIf="visible | stateWrapper | async as result"
  [ngSwitch]="result.status"
>
  <ng-container *ngSwitchCase="'loading'">
    <div class="m-4 mt-0 d-flex">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
      <h4 class="ms-4">Ładowanie ...</h4>
    </div></ng-container
  >
  <ng-container *ngSwitchCase="'loaded'">
    <div class="card m-4 p-2" *ngFor="let item of result.value">
      <div class="d-flex justify-content-between">
        <section class="d-flex flex-row">
          <h3>{{ item.name }}</h3>
          <img class="ms-2" [src]="item.logo_base64" alt="logo" />
        </section>
        <button class="btn btn-primary" (click)="onEdit(item)">Edytuj</button>
      </div>
      <eself-table class="mt-3 ms-1 me-1" [data]="item.payment_methods ?? []">
        <ng-template eselfTableHeader>
          <th class="text-center">Nazwa</th>
          <th class="text-center">Logo</th>
          <th class="text-center">Prowizja</th>
          <th class="text-center">Opłata</th>
          <th class="text-center">Zewnętrzne Id</th>
        </ng-template>
        <ng-template [eselfTableRow]="item.payment_methods ?? []" let-row>
          <td data-title="Nazwa">{{ row.name }}</td>
          <td data-title="Nazwa">
            <img [src]="row.logo_base64" alt="logo" />
          </td>
          <td data-title="Prowizja">
            {{ row.fee_percent ?? 0 | currencyNumber : '%' }}
          </td>
          <td data-title="Opłata">{{ row.charge | currencyNumber : 'PLN' }}</td>
          <td data-title="Zewnętrzne Id">{{ row.external_id }}</td>
        </ng-template>
      </eself-table>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'error'">
    <p class="text-center" *ngIf="searchTerm">Brak wyników..</p></ng-container
  >
  <ng-container *ngSwitchCase="'empty'">
    <p class="text-center" *ngIf="searchTerm">Brak wyników..</p></ng-container
  >
</ng-container>
