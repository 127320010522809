import { Component, OnInit } from '@angular/core';
import {
  GateConfiguration,
  GateConfigurationService,
  retryPipe,
} from '@eself/shared-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, map } from 'rxjs';
import { GateConfigurationModalComponent } from './gate-configuration-modal/gate-configuration-modal.component';
import {
  PaymentWallResource,
  PaymentWallService,
  UpdatePaymentwallRequest,
} from 'api-client';

@Component({
  templateUrl: './gate-configuration-page.component.html',
  styleUrls: ['./gate-configuration-page.component.scss'],
})
export class GateConfigurationPageComponent {
  public all: Observable<PaymentWallResource[] | null>;
  public visible: Observable<PaymentWallResource[] | null>;
  public searchTerm: string | null = null;

  constructor(
    private paymentWallService: PaymentWallService,
    private modalService: NgbModal
  ) {
    this.all = this.paymentWallService.getPaymentWalls().pipe(retryPipe(3));
    this.visible = this.all;
  }

  public onEdit(gateConfiguration: PaymentWallResource): void {
    const modalRef = this.modalService.open(GateConfigurationModalComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.toEdit = gateConfiguration;
    modalRef.result.then(
      (result: { request: UpdatePaymentwallRequest; id: number }) => {
        if (result && result.id) {
          this.paymentWallService
            .updatePaymentwall(result.id, result.request)
            .subscribe(
              (x) =>
                (this.visible = this.paymentWallService
                  .getPaymentWalls()
                  .pipe(retryPipe(3)))
            );
        }
      }
    );
  }

  public onAdd(): void {
    const modalRef = this.modalService.open(GateConfigurationModalComponent);
    modalRef.result.then((result: GateConfiguration) => {
      if (result && result.id) {
        // this.gateConfigurationService.add(result);
      }
    });
  }

  public search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value?.toLowerCase();
    this.searchTerm = searchTerm;
    if (searchTerm.length > 1) {
      this.visible = this.all.pipe(
        map(
          (list) =>
            list!.filter((x) => x.name?.toLowerCase().includes(searchTerm)) ??
            null
        )
      );
    } else {
      this.visible = this.all;
    }
  }
}
