import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { SideBarElementModel } from '@eself/shared-ui';

@Component({
  selector: 'eself-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input()
  public mainSection: string;

  @Input()
  public mainSectionSelected: string;

  @Input()
  public colorClass: string;

  @ContentChild('sideBar', { read: TemplateRef })
  sideBar: TemplateRef<any> | null = null;

  @Input()
  public sideBarElements: SideBarElementModel[] = [];

  constructor() {
    this.mainSection = '';
    this.mainSectionSelected = '';
    this.colorClass = 'primary-text-color';
  }
}
