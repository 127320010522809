import { Pipe, PipeTransform } from '@angular/core';
import { CommissionType } from '@eself/shared-services';
import { FeeType } from 'api-client';

@Pipe({
  name: 'commissionTypeReadable',
})
export class CommissionTypeReadablePipe implements PipeTransform {
  transform(value: FeeType | undefined, ...args: unknown[]): string {
    if (value === null) return 'b.d';
    switch (value) {
      case FeeType.NUMBER_4:
        return 'Indywidualna ';
      case FeeType.NUMBER_3:
        return 'Promocja ukryta';
      case FeeType.NUMBER_2:
        return 'Promocja';
      case FeeType.NUMBER_1:
        return 'Publiczna';
    }
    return 'błąd';
  }
}
