import { Pipe, PipeTransform } from '@angular/core';
import { PayoutStatus, TransactionStatus } from 'api-client';

@Pipe({
  name: 'transactionStatusColor',
})
export class TransactionStatusColorPipe implements PipeTransform {
  transform(value?: TransactionStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case TransactionStatus.NUMBER_1:
        return '#912da4'; // new
      case TransactionStatus.NUMBER_2:
        return '#006be0'; // in_progress
      case TransactionStatus.NUMBER_3:
        return '#68bb18'; // ready
    }
    return '#006be0';
  }
}


