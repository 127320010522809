<h3 class="m-4">Lista zgłoszeń</h3>
<div class="card m-4 p-4">
  <eself-state-table [data$]="pageData$">
    <ng-template eselfTableHeader>
      <th scope="col" class="text-center">numer zgłoszenia</th>
      <th scope="col" class="text-center">status</th>
      <th scope="col" class="text-center">ocena czasu</th>
      <th scope="col" class="text-center">inicjały</th>
      <th scope="col" class="text-center" *ngIf="displayShopId">sklep</th>
      <th scope="col" class="text-center">data wpłynięcia</th>
    </ng-template>
    <ng-template #pagination let-row>
      <eself-table-pagination [pagination]="row"> </eself-table-pagination>
    </ng-template>
    <ng-template #row let-row>
      <td scope="row" class="text-center" data-title="numer zgłoszenia">
        {{ row.id }}
      </td>
      <td
        scope="row"
        class="text-center"
        data-title="status"
        placement="bottom"
        [ngbTooltip]="row.status | rodoStatusText"
        [openDelay]="50"
        [closeDelay]="300"
        container="body"
      >
        <i class="material-icons" [style.color]="row.status | rodoStatusColor">
          {{ row.status | rodoStatusIcon }}
        </i>
      </td>
      <td scope="row" class="text-center" data-title="ocena czasu">
        <i class="material-icons" [style.color]="row.status | rodoStatusColor">
          {{ row.status | rodoStatusIcon }}
        </i>
      </td>

      <td scope="row" class="text-center" data-title="inicjały">
        {{ row.initials }}
      </td>
      <td
        scope="row"
        class="text-center"
        data-title="sklep"
        *ngIf="displayShopId"
      >
        {{ row.shop }}
      </td>
      <td scope="row" class="text-center" data-title="data wpłynięcia">
        {{ row.date | safeDate : 'long' }}
      </td>
    </ng-template>
  </eself-state-table>
</div>
