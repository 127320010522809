export interface CommissionModel {
  id?: number;
  name: string;
  discount: string;
  price: string;
  commission: string;
  startDate: Date;
  endDate: Date;
  type: CommissionType;
  note: string;
  bulletPoints: string[];
}

export enum CommissionType {
  individual = 'individual',
  promotion_hidden = 'promotion_hidden',
  promotion = 'promotion',
  public = 'public',
}

export interface CommissionHistoryModel {
  id?: number;
  startDate: Date;
  endDate?: Date | null;
  commission: CommissionModel;
}
