<h3 class="m-4">Saldo sklepu</h3>
<div *ngIf="shopSaldo$ | async as shopSaldo" class="d-flex">
  <div class="card mt-0 m-4 p-4">
    <div class="card-body">
      <h3>
        Aktualny stan konta:
        <b>{{
          shopSaldo.available_balance | currencyNumber : shopSaldo.currency
          }}</b>
        PLN
      </h3>
      <p>
        Status na dzień: {{ shopSaldo.date_of_update | date : 'dd.MM.yyyy' }}
      </p>
      <hr />
      <h3>Prowizje i opłaty</h3>
      <table class="table table-striped table-bordered">
        <tbody>
          <tr>
            <td></td>
            <td>Rozliczone</td>
            <td>Nierozliczone</td>
          </tr>
          <tr>
            <td scope="col"><b>Wpłaty</b></td>
            <td>b.d PLN</td>
            <td>
              {{
              (shopSaldo.amount ?? 0) - (shopSaldo.available_balance ?? 0)
              | currencyNumber : 'PLN'
              }}
            </td>
          </tr>
          <tr>
            <td scope="col">Prowizja bramki</td>
            <td>{{ shopSaldo.gate_fees | currencyNumber : 'PLN' }}</td>
            <td>-</td>
          </tr>
          <tr>
            <td scope="col">Prowizja eself</td>
            <td>
              {{ shopSaldo.eself_fees | currencyNumber : 'PLN' }}
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td scope="col">Mikroopłata eself</td>
            <td>
              {{ shopSaldo.micro_fees | currencyNumber : 'PLN' }}
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td scope="col">Abonament eself</td>
            <td>tbd</td>
            <td>-</td>
          </tr>
          <tr style="background-color: black; height: 4px">
            <td colspan="3" class="p-0 bg-dark" style="height: 4px"></td>
          </tr>
          <tr class="mt-2">
            <td>Łącznie opłaty</td>
            <td>
              <u>{{ shopSaldo.total_fees | currencyNumber }} PLN</u>
            </td>
            <td>
              <u>-</u>
            </td>
          </tr>
          <tr>
            <td>Wpłaty wypłacone</td>
            <td>
              <b>{{ shopSaldo.withdrawal | currencyNumber }}</b> PLN
            </td>
          </tr>
          <tr>
            <td>Do wypłaty</td>
            <td>
              <b>{{ shopSaldo.available_balance | currencyNumber }}</b> PLN
            </td>
            <td><b>-</b></td>
          </tr>
        </tbody>
      </table>
      <button type="button" class="btn btn-outline-primary" (click)="synchronize()">
        Rozlicz
      </button>
    </div>
  </div>
</div>
<div class="d-flex card m-4 p-4">
  <h3>Historia salda</h3>
  <eself-state-table [data$]="pageData$">
    <ng-template eselfTableHeader>
      <th scope="col" class="text-center">kwota sprzedaży</th>
      <th scope="col" class="text-center">prowizja bramki</th>
      <th scope="col" class="text-center">prowizja eself</th>
      <th scope="col" class="text-center">mikroopłata eself</th>
      <th scope="col" class="text-center">abonament eself</th>
      <th scope="col" class="text-center">kwota wypłat*</th>
      <th scope="col" class="text-center">data wypłaty*</th>
      <th scope="col" class="text-center">status wypłaty</th>
      <th scope="col" class="text-center">data rozliczenia*</th>
      <th scope="col" class="text-center">saldo dostępne</th>
      <th scope="col" class="text-center">data transakcji</th>
    </ng-template>
    <ng-template #pagination let-row>
      <eself-table-pagination [pagination]="row" (moveToPage)="onPageChange($event)"> </eself-table-pagination>
      <eself-common-loading *ngIf="isLoading "></eself-common-loading>
    </ng-template>
    <ng-template #row let-row>
      <td scope="row" class="text-end me-2" data-title="kwota sprzedaży">
        {{ row.price | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-end me-2">
        {{ row.fee | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-end me-2">
        {{ row.eself_fee | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-end me-2">
        {{ row.micro_fee | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-end me-2">
        <u> tbd</u>
      </td>
      <td scope="row" class="text-end me-2" data-title="kwota wypłat">
        {{ row.payout_amount | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-center" data-title="data wypłaty*">
        {{
        (row.payout_timestamp | safeDate : 'long') ?? '-'
        }}
      </td>
      <td scope="row" data-title="status wypłaty" placement="bottom" [ngbTooltip]="row.payout_status | payoutStatusText"
        [openDelay]="50" [closeDelay]="300" container="body" class="text-center">
        {{ row.payout_status | payoutStatusText }}
      </td>
      <td scope="row" class="text-center" data-title="data rozliczenia*">
        {{
        (row.accounted_timestamp | safeDate : 'long') ??
        '-'
        }}
      </td>
      <td scope="row" class="text-end me-2" data-title="saldo dostępne*">
        {{ row.balance | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" data-title="data transakcji">
        {{ row.transaction_timestamp | safeDate : 'long' }}
      </td>
    </ng-template>
  </eself-state-table>
</div>
