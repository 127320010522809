/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodResource } from './paymentMethodResource';


/**
 * PaymentWall resource
 */
export interface PaymentWallResource { 
    /**
     * ID
     */
    id?: number;
    /**
     * Name of the payment wall
     */
    name?: string;
    /**
     * Logo of the payment wall in Base64
     */
    logo_base64?: string;
    /**
     * List of active payment methods related to the payment wall
     */
    payment_methods?: Array<PaymentMethodResource>;
}

