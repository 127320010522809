import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { GlobalModule } from './modules/global/global.module';
import { CurrencyPipe, UpperCasePipe } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { SharedUiModule } from '@eself/shared-ui';
import { AuthService, ErrorInterceptor, SharedServicesModule } from '@eself/shared-services';
import { ApiModule, Configuration } from 'api-client';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    SharedUiModule,
    SharedServicesModule,
    GlobalModule,
    HttpClientModule,
    ApiModule,
    ReactiveFormsModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    UpperCasePipe,
    CurrencyPipe,
    {
      provide: Configuration,
      useFactory: (authService: AuthService) =>
        new Configuration({
          basePath: environment.apiUrl,
          credentials: {
            api: () => authService.getAccessToken(),
          },
        }),
      deps: [AuthService],
      multi: false,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
