import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShopRoutingModule } from './shop-routing.module';
import { ShopComponent } from './shop.component';
import { SharedModule } from '../../shared/shared.module';
import { ShopTransactionsPageComponent } from './pages/shop-transactions-page/shop-transactions-page.component';
import { ShopCommissionPageComponent } from './pages/shop-commission-page/shop-commission-page.component';
import { ShopBillingStatusPageComponent } from './pages/shop-billing-status-page/shop-billing-status-page.component';
import { ShopBalancePageComponent } from './pages/shop-balance-page/shop-balance-page.component';
import { ShopGatewayBillingPageComponent } from './pages/shop-gateway-billing-page/shop-gateway-billing-page.component';
import { ShopEselfBillingPageComponent } from './pages/shop-eself-billing-page/shop-eself-billing-page.component';
import { ShopInvoicesPageComponent } from './pages/shop-invoices-page/shop-invoices-page.component';
import { ShopAccountsStatusPageComponent } from './pages/shop-accounts-status-page/shop-accounts-status-page.component';
import { ShopDashboardPageComponent } from './pages/shop-dashboard-page/shop-dashboard-page.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUiModule } from '@eself/shared-ui';
import { SharedServicesModule } from '@eself/shared-services';
import { ShopOnboardingPageComponent } from './pages/shop-onboarding-page/shop-onboarding-page.component';
import { InvoiceDetailsModalComponent } from './pages/shop-invoices-page/invoice-details-modal/invoice-details-modal.component';
import { EditInvoiceModalComponent } from './pages/shop-invoices-page/edit-invoice-modal/edit-invoice-modal.component';
import { ShopRodoPageComponent } from './pages/shop-rodo-page/shop-rodo-page.component';

@NgModule({
  declarations: [
    ShopComponent,
    ShopTransactionsPageComponent,
    ShopCommissionPageComponent,
    ShopBillingStatusPageComponent,
    ShopBalancePageComponent,
    ShopGatewayBillingPageComponent,
    ShopEselfBillingPageComponent,
    ShopInvoicesPageComponent,
    ShopAccountsStatusPageComponent,
    ShopDashboardPageComponent,
    ShopOnboardingPageComponent,
    InvoiceDetailsModalComponent,
    EditInvoiceModalComponent,
    ShopRodoPageComponent,
  ],
  imports: [
    CommonModule,
    ShopRoutingModule,
    SharedModule,
    NgxEchartsModule,
    ClipboardModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUiModule,
    SharedServicesModule,
  ],
  exports: [],
})
export class ShopModule {}
