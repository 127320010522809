import { Directive, ElementRef, Input, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, switchMap, takeWhile } from 'rxjs';
import { ToastService } from '../services/toast/toast.service';

@UntilDestroy()
@Directive({
  selector: '[copy]',
})
export class CopyDirective {
  @Input() copy: string | null | undefined = '';

  constructor(
    private host: ElementRef<HTMLElement>,
    private toast: ToastService
  ) {}

  ngOnInit() {
    fromEvent(this.host.nativeElement, 'click')
      .pipe(
        takeWhile(() => this.copy !== undefined && this.copy !== null),
        switchMap(() => navigator.clipboard.writeText(this.copy ?? '')),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.toast.show('Skopiowano do schowka!', {
          classname: 'bg-success text-light',
          delay: 1000,
        });
      });
  }
}
