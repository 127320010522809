/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 1 - New, 2 - Paid, 3 - Accounted
 */
export type PayoutStatus = 1 | 2 | 3;

export const PayoutStatus = {
    NUMBER_1: 1 as PayoutStatus,
    NUMBER_2: 2 as PayoutStatus,
    NUMBER_3: 3 as PayoutStatus
};

