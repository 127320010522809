import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() title = 'Wymagane potwierdzenie';
  @Input() message = 'Czy jesteś pewien?';
  @Input() confirmText = 'Tak';
  @Input() rejectText = 'Nie';

  constructor(public activeModal: NgbActiveModal) {}

  onConfirm(): void {
    this.activeModal.close(true);
  }

  onReject(): void {
    this.activeModal.dismiss();
  }
}
