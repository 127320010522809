import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatus } from 'api-client';


@Pipe({
  name: 'invoiceStatusText',
})

/**
 * 0 - Pending, 1 - Realized, 2 - Cancelled
 */
export class InvoiceStatusTextPipe implements PipeTransform {
  transform(value: InvoiceStatus | undefined, ...args: unknown[]): string {
    if (value === InvoiceStatus.NUMBER_0) {
      return 'Oczekująca';
    }
    if (value === InvoiceStatus.NUMBER_1) {
      return 'Zrealizowana';
    }
    return 'Anulowana';
  }
}
