import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, map, startWith, catchError, of, Observable } from 'rxjs';

enum Status {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
  empty = 'empty',
}

export type AsyncStatusInput = {
  type?: 'loading' | 'error';
  value?: any;
  error?: string;
};

export interface AsyncStatusResult<T> {
  status?: Status;
  value?: T;
  error?: string;
}

const defaultError = 'Something went wrong';

@Pipe({
  name: 'stateWrapper',
})
export class StateWrapperPipe implements PipeTransform {
  transform<T = any>(val: Observable<T>): Observable<AsyncStatusResult<T>> {
    return val.pipe(
      map((value: any) => {
        let setStatus: Status;
        let setError = '';

        if (value === null) {
          setStatus = Status.empty;
        } else if (value.type === 'loading') {
          setStatus = Status.loading;
        } else if (value.type === 'error') {
          setStatus = Status.error;
          setError = value.error ?? defaultError;
        } else {
          setStatus =
            value &&
            (!Array.isArray(value) || (Array.isArray(value) && value.length))
              ? Status.loaded
              : Status.empty;
        }
        return {
          status: setStatus,
          value: value?.type && value?.value ? value.value : value,
          error: setError,
        };
      }),
      startWith({ status: Status.loading }),
      catchError((error) => {
        let errorText: string;
        if (typeof error === 'string') {
          errorText = error;
        } else if (error instanceof Error) {
          errorText = error.message;
        } else {
          errorText = defaultError;
        }
        console.log(
          'wrapperAsyncStatus Error - ' + error.name + ': ' + errorText
        );
        return of({
          status: Status.error,
          error: errorText,
        });
      })
    );
  }
}
