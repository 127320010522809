import { Injectable } from '@angular/core';
import { ShopModel } from '../models/shop.model';

@Injectable({
  providedIn: 'root',
})
export class SelectedShopService {
  public setShop(shop: ShopModel) {
    localStorage.setItem('current_shop', JSON.stringify(shop));
  }

  public getShop(): ShopModel | null {
    const shopJson = localStorage.getItem('current_shop');
    if(shopJson == null){
      return null;
    }
    return JSON.parse(shopJson);
  }
}
