import { Component } from '@angular/core';
import { OrderModel, SelectedShopService } from '@eself/shared-services';
import { SelectableModel } from '@eself/shared-ui';
import {
  SettlementResource,
  SettlementService,
  WithdrawalStatus,
} from 'api-client';
import { EChartsOption } from 'echarts';
import { Subject, map, startWith, switchMap, tap } from 'rxjs';

@Component({
  templateUrl: './shop-gateway-billing-page.component.html',
  styleUrls: ['./shop-gateway-billing-page.component.scss'],
})
export class ShopGatewayBillingPageComponent {
  active = 1;
  isLoading = true;
  options: EChartsOption | null = null;
  options2: EChartsOption | null = null;

  pageIndex = 0;
  pageSize = 10;
  totalCount = 0;
  searchTerm: string | null = null;

  withdrawalStatusFilter: WithdrawalStatus | null = null;

  pageChangeSubject = new Subject<{ pageIndex: number; pageSize: number }>();

  pageData$ = this.pageChangeSubject.pipe(
    tap(() => (this.isLoading = true)),
    startWith({ pageIndex: this.pageIndex, pageSize: this.pageSize }),
    switchMap((pageDetails) =>
      this.settlementService
        .getGateSettlements(this.selectedShopService.getShop()?.id ?? 0) //use here pageDetails
        .pipe(
          tap((frame) => {
            this.pageIndex = frame.pagination?.current_page ?? 0;
            this.pageSize = frame.pagination?.per_page ?? 0;
          }),
          map((frame) => {
            this.totalCount = frame.pagination?.total ?? 0;
            if (this.totalCount < this.pageIndex * this.pageSize) {
              this.pageIndex = 0;
            }
            return (
              frame.data?.map(
                (x) =>
                  ({
                    data: x,
                    selected: false,
                    disabled: x.available_balance ?? 0 == 0,
                  } as SelectableModel<SettlementResource>)
              ) ?? []
            );
          })
        )
    ),
    tap(() => (this.isLoading = false))
  );

  constructor(
    private settlementService: SettlementService,
    private selectedShopService: SelectedShopService
  ) {}

  public onPageChange(pageIndex: number): void {
    this.pageChangeSubject.next({ pageIndex, pageSize: this.pageSize });
  }

  public withdraw() {
    alert(`Ta metoda nie została jeszcze zaimplementowana!`);
  }

  public canWithdraw(data: SelectableModel<SettlementResource>[]) {
    return data.filter((x) => x.selected).length > 0;
  }

  public showAll() {
    this.withdrawalStatusFilter = null;
  }

  public filterOnWithdrawReady() {
    this.withdrawalStatusFilter = WithdrawalStatus.NUMBER_1;
  }

  public filterOnWithdraw() {
    this.withdrawalStatusFilter === WithdrawalStatus.NUMBER_2;
  }

  public async activeIdChange(id: number): Promise<void> {
    if (id == 2) {
      this.setOptions();
    } else {
      this.options = null;
      this.options2 = null;
    }
  }

  public setOptions(): void {
    this.options = {
      title: {
        left: '50%',
        text: 'Aktualne saldo bramki płatności',
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        align: 'auto',
        bottom: 10,
        data: ['Saldo dostępne', 'Kwota prowizji'],
      },
      calculable: true,
      series: [
        {
          name: '',
          type: 'pie',
          radius: '55%',
          center: ['50%', '50%'],
          emphasis: {
            focus: 'self',
          },
          data: [
            { value: 1200, name: 'Saldo dostępne' },
            { value: 22.8, name: 'Kwota prowizji' },
          ],
        },
      ],
    };
    this.options = {
      title: {
        left: '50%',
        text: 'Aktualne saldo bramki płatności',
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        align: 'auto',
        bottom: 10,
        data: ['Saldo dostępne', 'Kwota prowizji'],
      },
      calculable: true,
      series: [
        {
          name: '',
          type: 'pie',
          radius: '55%',
          center: ['50%', '50%'],
          emphasis: {
            focus: 'self',
          },
          data: [
            { value: 1200, name: 'Saldo dostępne' },
            { value: 22.8, name: 'Kwota prowizji' },
          ],
        },
      ],
    };

    this.options2 = {
      legend: {},
      tooltip: {},
      dataset: {
        // Provide a set of data.
        source: [
          ['product', '01.2023', '02.2023', '03.2023'],
          ['Wypłacone', ...this.getRandomValues()],
          ['Nie wypłacone', ...this.getRandomValues()],
          ['Odrzucone', ...this.getRandomValues()],
        ],
      },
      // Declare an x-axis (category axis).
      // The category map the first column in the dataset by default.
      xAxis: { type: 'category' },
      // Declare a y-axis (value axis).
      yAxis: {},
      // Declare several 'bar' series,
      // every series will auto-map to each column by default.
      series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
    };
  }

  private getRandomValues() {
    const res: number[] = [];
    for (let i = 0; i < 3; i++) {
      res.push(Math.random() * 100);
    }
    return res;
  }

  public searchFunc(item: OrderModel, searchTerm: string): boolean {
    return (
      ('#' + item.id).includes(searchTerm) ||
      item.currency.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.eselfCommission
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item.amount.toString().includes(searchTerm)
    );
  }
}
