import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeeRequest, FeeResource, FeeService } from 'api-client';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from '@eself/shared-ui';
import { CommissionConfigurationModalComponent } from '../../../../shared/components/modals/commission-configuration-modal/commission-configuration-modal.component';
@Component({
  templateUrl: './commission-configuration-page.component.html',
  styleUrls: ['./commission-configuration-page.component.scss'],
})
export class CommissionConfigurationPageComponent {
  commissions$: Observable<FeeResource[]> = new Observable();

  constructor(private feeService: FeeService, private modalService: NgbModal) {
    this.commissions$ = this.feeService.getFees();
  }

  public refresh() {
    this.commissions$ = this.feeService.getFees();
  }

  public onCreateCommission(): void {
    this.modalService
      .open(CommissionConfigurationModalComponent)
      .result.then((result: FeeRequest) => {
        this.feeService.createFee(result).subscribe(() => this.refresh());
      });
  }

  public onEditCommission(commission: FeeResource): void {
    const modalRef = this.modalService.open(
      CommissionConfigurationModalComponent
    );
    modalRef.componentInstance.commissionToEdit = commission;
    modalRef.result.then((result: FeeRequest) => {
      if (result && commission.id) {
        this.feeService.updateFee(commission.id, result).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

  public async onDeleteCommission(commission: FeeResource): Promise<void> {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent);
    confirmModalRef.componentInstance.message = `Czy na pewno chcesz usunąć abonament: ${commission.name}?`;
    const result = await confirmModalRef.result;
    if (result === true) {
      this.feeService.deleteFee(commission.id ?? 0).subscribe(() => {
        this.refresh();
      });
    }
  }
}
