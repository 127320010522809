<h3 class="m-4">Aktualizacja ustawień</h3>
<div class="d-flex m-4">
  <div class="w-100" ngbAccordion>
    <div ngbAccordionItem="first" [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>Dane z kroku 1</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-shop-onboarding-step1
              [showBack]="false"
              [showStep]="false"
              [skipPassword]="true"
              continue="Zapisz"
              (formResult)="onFirstStepReady($event)"
              [formValue]="this.firstStep"
            ></app-shop-onboarding-step1>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem="second">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>Dane z kroku 2</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-shop-onboarding-step2
              [showBack]="false"
              [showStep]="false"
              [showNext]="false"
              [formValue]="this.secondStep"
              continue="Zapisz"
              (formResult)="onSecondStepReady($event)"
            ></app-shop-onboarding-step2>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem="third">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>Dane z kroku 3</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="alert alert-danger m-4" role="alert">
              W tej chwili nie ma możliwości zapisania danych z formularza!
            </div>
            <app-shop-onboarding-step3
              [showBack]="false"
              [showStep]="false"
              [showNext]="false"
              [formValue]="this.thirdStep"
              (formResult)="onThirdStepReady($event)"
            ></app-shop-onboarding-step3>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-bar">
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        [style.width.%]="25"
      ></div>
    </div>
  </div>
</div>
