<h3 class="header m-4">Lista transakcji</h3>
<div class="d-flex card m-4 p-4">
  <div class="form-group mb-2 d-flex" *ngIf="dateOptions">
    <label class="form-label">Transakcje w miesiącu: </label>
    <select class="form-control d-flex" [(ngModel)]="dateSelected" title="date">
      <option *ngFor="let date of dateOptions" [value]="date">
        {{ date | date : 'MM.yyyy' }}
      </option>
    </select>
    <button class="btn btn-outline-secondary" *ngIf="dateSelected" (click)="dateSelected = null">
      X
    </button>
  </div>
  <div *ngIf="dateSelected" class="m-4 alert alert-warning">
    Ta opcja jest tymczasowo niedostępna przy stronicowaniu serwerowym.
    Pracujemy nad jej udostępnieniem.
  </div>

  <div class="d-flex align-items-start justify-content-between">
    <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()"
      [attr.aria-expanded]="!filtersHidden">
      <i class="material-icons small-icon">filter_alt</i>
      {{ filtersHidden ? 'Otwórz kreator filtrów' : 'Zamknij kreator filtrów' }}
      <!-- <span
        *ngIf="
          bankTypeFilter.hasSelectedValues() ||
          paymentTypeFilter.hasSelectedValues()
        "
        class="badge bg-danger"
        >Wybrano filtry</span
      > -->
    </button>
    <input class="eself-input ms-2 mb-4" type="text" title="Szukaj" placeholder="Szukaj..." (keyup)="search($event)" />
  </div>

  <div #collapse="ngbCollapse" class="me-2" [(ngbCollapse)]="filtersHidden">
    <div class="row">
      <div class="col">
        <div class="m-4 alert alert-warning">
          Ta opcja jest tymczasowo niedostępna przy stronicowaniu serwerowym.
          Pracujemy nad jej udostępnieniem.
        </div>
        <!-- <eself-values-based-filter-input
          [textFilter]="companyFilter"
          (filterChanged)="onFilterApplied($event)"
          name="Sklep"
        >
        </eself-values-based-filter-input> -->
      </div>
    </div>
  </div>
  <eself-state-table [data$]="pageData$">
    <ng-template eselfTableHeader>
      <th scope="col" class="text-center pointer" eselfTableSort="transaction_status ?? ''"
        (sort)="onOrderUpdated($event)" [currentProperty]="order.property">
        status
      </th>
      <th scope="col" class="text-center pointer" eselfTableSort="transaction_type ?? ''"
        (sort)="onOrderUpdated($event)" [currentProperty]="order.property">
        typ
      </th>
      <th scope="col" class="text-center pointer" eselfTableSort="transactionDate" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property" ngbTooltip="Data dokonania transakcji" container="body" placement="bottom">
        <span class="w-100"> data </span>
      </th>
      <th scope="col" class="text-end pointer" eselfTableSort="amount" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        kwota
      </th>
      <th scope="col" class="text-end pointer" eselfTableSort="totalCharges" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        łączne opłaty
      </th>
      <th scope="col" class="text-end pointer" eselfTableSort="amountPayable" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        kwota do zapłaty
      </th>
      <th scope="col" class="text-center pointer" eselfTableSort="withdrawalStatus" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        status bramki
      </th>
      <th scope="col" class="text-center pointer" eselfTableSort="withdrawalDate" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        data wypłaty*
      </th>
      <th scope="col" class="text-end pointer" eselfTableSort="withdrawalAmount" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        kwota wypłaty*
      </th>
      <th scope="col" class="text-end pointer" eselfTableSort="updatedTransactionAmount" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        zaktualizowane saldo*
      </th>
      <th scope="col" class="text-center pointer" eselfTableSort="billingDate" (sort)="onOrderUpdated($event)"
        [currentProperty]="order.property">
        data rozliczenia*
      </th>
    </ng-template>
    <ng-template #pagination let-row>
      <eself-table-pagination [pagination]="row" (moveToPage)="onPageChange($event)"> </eself-table-pagination>
      <eself-common-loading *ngIf="isLoading "></eself-common-loading>

    </ng-template>
    <ng-template #row let-row>
      <td scope="row" data-title="status transakcji" placement="bottom"
        [ngbTooltip]="row.transaction_status | transactionStatusText" [openDelay]="50" [closeDelay]="300"
        container="body">
        <i class="material-icons" [style.color]="row.transaction_status | transactionStatusColor">
          {{ row.transaction_status | transactionStatusIcon }}
        </i>
      </td>
      <td scope="row" data-title="typ transakcji" placement="bottom"
        [ngbTooltip]="row.transaction_type | paymentTypeText" [openDelay]="50" [closeDelay]="300" container="body">
        <img [src]="row.transaction_type | paymentTypeIcon" alt="payment type icon" />
      </td>
      <td scope="row" data-title="data transakcji">
        {{ row.transaction_timestamp | safeDate : 'long' }}
      </td>
      <td scope="row" class="text-end" data-title="kwota">
        {{ row.price | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-end" data-title="łączne opłaty underline" placement="bottom" [ngbTooltip]="tipContent"
        [openDelay]="50" [closeDelay]="300" container="body">
        <ng-template #tipContent><app-commission-explained [transaction]="row"></app-commission-explained></ng-template>
        <u> {{ row.total_fee_gross | currencyNumber : 'PLN' }}</u>
      </td>
      <td scope="row" class="text-end" data-title="kwota do zapłaty">
        {{ row.to_be_paid | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" data-title="status wypłaty" placement="bottom" [ngbTooltip]="row.payout_status | payoutStatusText"
        [openDelay]="50" [closeDelay]="300" container="body">
        <i class="material-icons text-center w-100" [style.color]="row.payout_status | payoutStatusColor">
          {{ row.payout_status | payoutStatusIcon }}
        </i>
      </td>
      <td scope="row" data-title="data wypłaty*">
        {{ row.payout_timestamp | safeDate : 'long' }}
      </td>
      <td scope="row" class="text-end" data-title="kwota wypłaty*">
        {{ row.payout_amount | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" class="text-end" data-title="zaktualizowane saldo transakcji*">
        {{ row.balance | currencyNumber : 'PLN' }}
      </td>
      <td scope="row" data-title="data rozliczenia*">
        {{ row.accounted_timestamp | safeDate }}
      </td>
      <td>
        <button class="btn btn-outline-primary" (click)="openTransactionDetails(row)">
          Otwórz
        </button>
      </td>
    </ng-template>
  </eself-state-table>
</div>
