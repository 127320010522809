export interface ClosestPages {
  closestPages: number[];
  canGoBackward: boolean;
  canGoForward: boolean;
}

export class BaseTable {
  protected generateClosestPages(
    pageSize: number | null,
    pageIndex: number | null,
    totalCount: number | null
  ): ClosestPages | null {
    if (pageSize == null || pageIndex == null || totalCount == null) {
      return null;
    }
    const closestPages: number[] = [];
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = pageIndex + 1;
    if (currentPage > 1) {
      if (currentPage > 2) {
        closestPages.push(currentPage - 2);
      }
      closestPages.push(currentPage - 1);
    }
    closestPages.push(currentPage);

    if (currentPage < totalPages) {
      closestPages.push(currentPage + 1);
    }
    if ((currentPage == 1 && totalPages > 2) || totalPages - currentPage > 2) {
      closestPages.push(currentPage + 2);
      if (closestPages.length < 3 && totalPages - currentPage > 3) {
        closestPages.push(currentPage + 3);
      }
    }
    return {
      closestPages,
      canGoBackward: currentPage > 1,
      canGoForward: currentPage < totalPages,
    };
  }
}
