import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatus } from 'api-client';


@Pipe({
  name: 'invoiceStatusIcon',
})

/**
 * 0 - Pending, 1 - Realized, 2 - Cancelled
 */
export class InvoiceStatusIconPipe implements PipeTransform {
  transform(value: InvoiceStatus | undefined, ...args: unknown[]): string {
    if (value === InvoiceStatus.NUMBER_0) {
      return 'downloading';
    }
    if (value === InvoiceStatus.NUMBER_1) {
      return 'next_plan';
    }
    return 'error';
  }
}
