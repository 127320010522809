<ng-container
  *ngIf="shop$ | stateWrapper | async as shopResult"
  [ngSwitch]="shopResult.status"
>
  <ng-container *ngSwitchCase="'loaded'">
    <div *ngIf="shopResult.value" class="row m-4">
      <div class="col-12 col-lg-6 d-flex">
        <div class="card w-100 m-2">
          <div class="card-header">
            <h3>{{ shopResult.value.name }} #{{ shopResult.value.id }}</h3>
          </div>
          <div class="card-body">
            <div *ngIf="shopResult.value.logo_base64" class="d-flex">
              <img [src]="shopResult.value.logo_base64" alt="shop-logo" />
            </div>
            <p class="outfit-font mt-2">
              Adres:
              <a [href]="shopResult.value.url" target="_blank" rel="child">{{
                shopResult.value.url | shortDomain
              }}</a>
            </p>
            <p class="pointer shadow-on-hover" [copy]="shopResult.value.nip">
              NIP: {{ shopResult.value.nip
              }}<span class="material-icons"> content_copy </span>
            </p>
            <p class="pointer shadow-on-hover" [copy]="shopResult.value.regon">
              REGON: {{ shopResult.value.regon
              }}<span class="material-icons"> content_copy </span>
            </p>
            <p>
              Opiekun sklepu: <b>{{ shopResult.value.customer_assistant }}</b>
            </p>
          </div>
          <div class="card-footer">
            <div class="d-flex">
              <a
                class="eself-secondary-button link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                [href]="shopResult.value.url"
                target="_blank"
                rel="child"
              >
                Odwiedź stronę
              </a>
              <button
                class="ms-2 eself-secondary-button link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                rel="child"
                [routerLink]="['/shop', shopResult.value.id, 'onboarding']"
              >
                Konfiguracja
              </button>
              <button
                class="ms-2 eself-secondary-button link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                rel="child"
                [copy]="shopResult.value.token"
              >
                Skopiuj token
              </button>
              <button
                class="ms-2 eself-secondary-button bg-warning text-white link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                rel="child"
                (click)="onGenerateToken(shopResult.value)"
              >
                Wygeneruj token
              </button>
              <button
                class="ms-2 eself-secondary-button bg-warning text-white link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                rel="child"
                (click)="activateShop(shopResult.value)"
              >
                Akytwuj sklep
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 d-flex">
        <div class="card m-2 w-100">
          <div class="card-header">
            <h3>Dane kontaktowe</h3>
          </div>
          <div class="card-body">
            <p>
              Mail dla klienta:
              <a [href]="'mailto: ' + shopResult.value.client_mail">{{
                shopResult.value.client_mail
              }}</a>
            </p>
            <p>
              Telefon dla klienta:
              <a [href]="'tel: ' + shopResult.value.client_phone">{{
                shopResult.value.client_phone
              }}</a>
            </p>
            <p>
              Mail biznesowy:
              <a [href]="'mailto: ' + shopResult.value.business_mail">{{
                shopResult.value.business_mail
              }}</a>
            </p>
            <p>
              Telefon biznesowy:
              <a [href]="'tel: ' + shopResult.value.business_phone">{{
                shopResult.value.business_phone
              }}</a>
            </p>
            <p>
              Osoba do kontaktu: <b>{{ shopResult.value.business_person }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 d-flex">
        <div class="card m-2 w-100">
          <div class="card-header">
            <h3>Notatki</h3>
          </div>
          <div class="card-body">
            <textarea
              class="form-control w-100 d-flex h-100"
              id="note"
              [(ngModel)]="note"
            >
            </textarea>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-primary"
              (click)="onSaveNote(shopResult.value)"
              [disabled]="note === shopResult.value.note"
            >
              Zapisz
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 d-flex">
        <div class="card m-2 w-100">
          <div class="card-header">
            <h3>Płatności</h3>
          </div>
          <div class="card-body">
            <p
              class="pointer shadow-on-hover"
              [copy]="shopResult.value.merchant_id"
            >
              Merchant Id: {{ shopResult.value.merchant_id
              }}<span class="material-icons"> content_copy </span>
            </p>
            <div class="list-group">
              <app-paymentwalll-list-item
                *ngIf="shopResult.value.paymentwall"
                [paymentwall]="shopResult.value.paymentwall"
              >
              </app-paymentwalll-list-item>
              <app-fee-list-item
                *ngIf="shopResult.value.fee"
                [fee]="shopResult.value.fee"
                [active]="false"
                [actual]="true"
              >
              </app-fee-list-item>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-12 col-lg-6 d-flex">
        <div class="card m-2 w-100">
          <div class="card-body">
            <div
              echarts
              [options]="options"
              [autoResize]="true"
              class="demo-chart"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <eself-common-loading *ngSwitchCase="'loading'"></eself-common-loading>
  <eself-common-api-error *ngSwitchCase="'error'"> </eself-common-api-error>
  <eself-common-empty-collection *ngSwitchCase="'empty'">
  </eself-common-empty-collection>
</ng-container>
