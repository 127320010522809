import { Pipe, PipeTransform } from '@angular/core';
import { TransactionStatus } from 'api-client';


@Pipe({
  name: 'transactionStatusText',
})

/**
 * 1 - Pending, 2 - AwaitPayment, 3 - Realized, 4 - Canceled
 */
export class TransactionStatusTextPipe implements PipeTransform {
  transform(value: TransactionStatus | undefined, ...args: unknown[]): string {
    if (value === TransactionStatus.NUMBER_1) {
      return 'Oczekująca';
    }
    if (value === TransactionStatus.NUMBER_2) {
      return 'Oczekuje płatności';
    }
    if (value === TransactionStatus.NUMBER_3) {
      return 'Zrealizowana';
    }
    return 'Anulowana';
  }
}
