import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'eself-toasts-container',
  templateUrl: './toast-container.component.html',
})
export class ToastsContainerComponent {
  @HostBinding('class')
  class = 'toast-container position-fixed top-0 end-0 p-3';

  @HostBinding('style')
  style = 'z-index: 1200';

  constructor(public toastService: ToastService) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
