import { Pipe, PipeTransform } from '@angular/core';
import { PayoutStatus } from 'api-client';


@Pipe({
  name: 'payoutStatusText',
})

/**
 * 1 - New, 2 - Paid, 3 - Accounted
 */
export class PayoutStatusTextPipe implements PipeTransform {
  transform(value: PayoutStatus | undefined, ...args: unknown[]): string {
    if (value === PayoutStatus.NUMBER_1) {
      return 'Nowa';
    }
    if (value === PayoutStatus.NUMBER_2) {
      return 'Opłacona';
    }
    return 'Rozliczona';
  }
}
