import { Pipe, PipeTransform } from '@angular/core';
import { PayoutStatus, TransactionStatus } from 'api-client';

@Pipe({
  name: 'transactionStatusIcon',
})

/**
 * 1 - Pending, 2 - AwaitPayment, 3 - Realized, 4 - Canceled
 */
export class TransactionStatusIconPipe implements PipeTransform {
  transform(value?: TransactionStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case TransactionStatus.NUMBER_1:
        return 'downloading'; // new
      case TransactionStatus.NUMBER_2:
        return 'next_plan'; // ready
      case TransactionStatus.NUMBER_3:
        return 'offline_pin'; // closed
    }
    return 'error';
  }
}
