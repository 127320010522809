import { Pipe, PipeTransform } from '@angular/core';
import { PayoutStatus, WithdrawalStatus } from 'api-client';

@Pipe({
  name: 'withdrawalStatusIcon',
})
export class WithdrawalStatusIconPipe implements PipeTransform {
  transform(value?: WithdrawalStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case WithdrawalStatus.NUMBER_0:
        return 'downloading'; // new
      case WithdrawalStatus.NUMBER_1:
        return 'next_plan'; // ready
      case WithdrawalStatus.NUMBER_2:
        return 'offline_pin'; // closed
    }
    return 'error';
  }
}
