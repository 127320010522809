import { Component } from '@angular/core';
import { Step1Form } from '../../../../shared/components/shop-onboarding-step1/shop-onboarding-step1.component';
import { Step2Form } from '../../../../shared/components/shop-onboarding-step2/shop-onboarding-step2.component';
import { Step3Form } from '../../../../shared/components/shop-onboarding-step3/shop-onboarding-step3.component';
import { ShopService, ShopType } from 'api-client';
import { Router } from '@angular/router';

@Component({
  templateUrl: './new-shop-form-page.component.html',
  styleUrls: ['./new-shop-form-page.component.scss'],
})
export class NewShopFormPageComponent {
  step = 1;
  allSteps = 3;
  firstStep: Step1Form | null = null;
  secondStep: Step2Form | null = null;
  thirdStep: Step3Form | null = null;

  token: string | undefined;

  error: string | undefined;

  constructor(private shopService: ShopService, private router: Router) {}
  onFirstStepReady(form: Step1Form) {
    this.firstStep = form;
    this.step = 2;
  }
  onSecondStepBack() {
    this.step = 1;
  }
  onSecondStepReady(form: Step2Form) {
    this.secondStep = form;
    this.step = 3;
  }
  onThirdStepBack() {
    this.step = 2;
  }
  onThirdStepReady(form: Step3Form) {
    this.thirdStep = form;
    if (this.thirdStep && this.secondStep && this.firstStep) {
      this.step = 4;
      this.thirdStep = form;
      const shopType: ShopType = <any>this.firstStep.companyType;
      this.shopService
        .registerShop(
          this.thirdStep.feeId,
          this.thirdStep.gateId,
          this.thirdStep.merchantId,
          shopType,
          this.firstStep.companyName,
          this.firstStep.url,
          this.firstStep.emailRodo,
          this.firstStep.phone,
          this.firstStep.email,
          this.firstStep.password,
          this.firstStep.confirmPassword,
          this.firstStep.emailInvoices,
          undefined,
          this.secondStep?.shopLogo ?? undefined,
          this.secondStep?.shopIcon ?? undefined,
          this.firstStep.nip ?? '',
          this.firstStep.regon ?? '',
          this.firstStep.krs ?? '',
          this.firstStep.pesel ?? '',
          undefined,
          undefined,
          undefined,
          undefined,
          this.secondStep?.buisnessName,
          this.secondStep?.buisnessPhone,
          this.secondStep?.buisnessMail,
          this.secondStep?.customerSupportName,
          this.secondStep?.customerSupportPhone,
          this.secondStep?.customerSupportMail
        )
        .subscribe(
          (shop) => {
            this.token = shop.shop_token;
          },
          (err) => {
            this.error = err.error.message;
            alert(this.error);
          }
        );
    }
  }
}
